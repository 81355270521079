import React, { Component, useEffect, useState, useRef } from "react";
import Linechart from "./linechart";
import Nextcharts from "./nextcharts";
import Morecharts from "./morecharts";
import Sidebar from "../main/sidebar";
import Searchbar from "../main/navigation/searchbar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";
import JoditEditor from "jodit-react";

const AddProducts = () => {
  const editor = useRef(null);

  const currentPath = window.location.pathname;
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOption3, setSelectedOption3] = useState("");
  const [selectedOption4, setSelectedOption4] = useState("");
  const [selectedOption5, setSelectedOption5] = useState("");
  const [NamePro, setNamePro] = useState("");
  const [PricePro, setPricePro] = useState("");
  const [getAllProductRes, setgetAllProductRes] = useState([]);
  const [getAllSCatagoriesRes, setgetAllSCatagoriesRes] = useState([]);
  const [getAllSSCatagoriesRes, setgetAllSSCatagoriesRes] = useState([]);

  const [content, setContent] = useState("");
  const [usefectTrue, setUsefectTrue] = useState(false);
  const [image, setImages] = useState("");
  const [responseImages, setResponseImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  useEffect(() => {
    getAllProduct();
  }, []);
  useEffect(() => {
    getAllSScatagories();
    getAllScatagories();
  }, []);
  useEffect(() => {
    getAllProduct();
  }, [usefectTrue]);

  const getAllScatagories = () => {
    axios
      .get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
      .then((res) => {
        console.log("abc1=====", res.data.subcatagories);
        setgetAllSCatagoriesRes(res.data.subcatagories);
      })
      .catch((err) => {
        console.log("err=====", err);
      });
  };

  const getAllSScatagories = () => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagoryDD`)
      .then((res) => {
        console.log("abc2=====", res.data.subsubcatagories);
        setgetAllSSCatagoriesRes(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err=====", err);
      });
  };

  const handleDeleteFun = () => {
    // console.log("c====>>>", deleteID)
    axios
      .delete(`${Env.server}/api/product/DeleteSinglProduct/${deleteID}`)
      .then((res) => {
        // console.log("abc===>>>", res.data.message);
        getAllProduct();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const handleSelectChange1 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption1(selectedValue);
    console.log(`Selected option: ${selectedValue}`);
  };
  const handleSelectChange2 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption2(selectedValue);
    // console.log(`Selected option: ${selectedValue}`);
  };
  const handleSelectChange3 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption3(selectedValue);
    // console.log(`Selected option: ${selectedValue}`);
  };
  const handleSelectChange4 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption4(selectedValue);
    // console.log(`Selected option: ${selectedValue}`);
  };
  const handleSelectChange5 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption5(selectedValue);
    // console.log(`Selected option: ${selectedValue}`);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([
    "ModelName",
    "Edition",
    "Storage",
    // 'Color',
    "Condition",
    // 'Grade',
    // 'Networks',
    // 'Generation',
    // 'Manufacturer',
    // 'Capacity'
  ]);
  const [enteredValues, setEnteredValues] = useState({});
  const [enteredValuesArray, setEnteredValuesArray] = useState({});

  const handleOptionSelect = (event) => {
    const selectedOption = event.target.value;
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setOptions(options.filter((option) => option !== selectedOption));
      setEnteredValuesArray({ ...enteredValuesArray, [selectedOption]: [] });
    }
  };

  const handleInputChange = (event, option) => {
    setEnteredValues({ ...enteredValues, [option]: event.target.value });
  };

  const handleInputKeyPress = (event, option) => {
    if (event.key === "Enter" && enteredValues[option].trim() !== "") {
      setEnteredValuesArray({
        ...enteredValuesArray,
        [option]: [...enteredValuesArray[option], enteredValues[option]],
      });
      setEnteredValues({ ...enteredValues, [option]: "" });
    }
  };

  const handleRemoveValue = (option, index) => {
    const updatedValuesArray = [...enteredValuesArray[option]];
    updatedValuesArray.splice(index, 1);
    setEnteredValuesArray({
      ...enteredValuesArray,
      [option]: updatedValuesArray,
    });
  };

  const handlePublish = () => {
    console.log("Entered values:", enteredValuesArray);
  };

  const submit = (imageFile) => {
    // console.log("abc====", selectedOption1);
    // console.log("abc====", selectedOption2);
    // console.log("abc====", selectedOption3);
    // console.log("abc====", selectedOption4);
    // console.log("abc====", selectedOption5);

    const data = {
      name: NamePro,
      price: PricePro,
      subcatagoryid: selectedOption1,
      subsubcatagoryid: selectedOption2,
      addedby: "admin",
      image: imageFile,
      options: enteredValuesArray,
    };
    if (!data) {
      alert("Fill All Fields");
    }
    console.log("abc=====", data);
    axios
      .post(`${Env.server}/api/product/postNew`, data)
      .then((res) => {
        // console.log("abc===>>", res.data.message)
        // console.log("abc===>>", res.data.products)
        getAllProduct();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const publishImage = () => {
    const formData = new FormData();
    formData.append("imagefront", imagesFront);
    // console.log("muzz1-====", imagesFront)
    // console.log("muzz2-====", image)
    image.forEach((img, index) => {
      formData.append(`images`, img);
    });
    let objectNames = [];
    axios
      .post(`${Env.server}/api/uploadik/multiple`, formData)
      .then((res) => {
        // let resp = res.data.file;
        // for (let i = 0; i < resp.length; i++) {
        //   objectNames.push("/images/" + resp[i].filename);
        // }
        objectNames = res.data.urls;
        setResponseImages(objectNames);
        // if (res.data.message === "imagesSaved") {
          submit(objectNames);
        // } else {
        //   alert("error not in catch");
        // }
      })
      .catch((err) => {
        // console.log("err------ooo", err)
        alert("catch error");
      });
  };

  const getAllProduct = () => {
    setImages([]);
    setImagesFront([]);
    setSelectedOption1("");
    setSelectedOption2("");
    setSelectedOption3("");
    setSelectedOption4("");
    setSelectedOption5("");

    axios
      .get(`${Env.server}/api/product/getAllProductOfAdmin`)
      .then((res) => {
        // console.log("abc===>>", res.data.message)
        // console.log("abc===>>", res.data.products)
        setgetAllProductRes(res.data.products);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };
  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="d-flex">
                    <div className="card-header justify-content-center">
                      <h2 className="text-center">Add Your Products</h2>
                    </div>
                    <button className="card-header justify-content-right">
                      <h2
                        className="text-center"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add
                      </h2>
                    </button>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      height: "auto",
                    }}
                  >
                    <div class="container">
                      <div class="row">
                        {getAllProductRes.map((data, index) => (
                          <>
                            <div class="col-md-3" key={index}>
                              <div class="card">
                                <span
                                  className="delete-icon"
                                  data-toggle="modal"
                                  data-target="#exampleModalLong1"
                                  onClick={() => setDeleteID(data._id)}
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    color: "red",
                                    fontSize: "20px",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 2,
                                  }}
                                >
                                  &#x2716; {/* Unicode for an "X" character */}
                                </span>
                                <img
                                  src={getAsset(data.image[0])}
                                  class="card-img-top"
                                  alt="..."
                                  style={{ height: "250px", width: "auto" }}
                                />
                                <div class="card-body">
                                  <h5 class="card-title">
                                    Item: &nbsp;&nbsp;&nbsp;{data.item}
                                  </h5>
                                  <h5 class="card-title">
                                    Model: &nbsp;&nbsp;&nbsp;{data.model}
                                  </h5>
                                  <h5 class="card-title">
                                    Storage: &nbsp;&nbsp;&nbsp;{data.storage}
                                  </h5>
                                  <h5 class="card-title">
                                    Edition: &nbsp;&nbsp;&nbsp;{data.edition}
                                  </h5>
                                  <h5 class="card-title">
                                    Condition: &nbsp;&nbsp;&nbsp;
                                    {data.condition}
                                  </h5>
                                  <a href="">View</a>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="exampleModalLong1"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Add Product
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <h4>Are you sure to delete?</h4>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                              onClick={handleDeleteFun}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Modal --> */}
                    <div
                      class="modal fade"
                      id="exampleModalLong"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Add Product
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div>
                              <label>Product Name:</label>
                              <input
                                type="text"
                                onChange={(e) => setNamePro(e.target.value)}
                                placeholder="Type the product Name"
                                className="form-control"
                              />
                            </div>
                            <div>
                              <label>Product Price:</label>
                              <input
                                type="number"
                                onChange={(e) => setPricePro(e.target.value)}
                                placeholder="Product Price"
                                className="form-control"
                              />
                            </div>

                            <div>
                              <label>SubCatagory:</label>
                              <select
                                id="selectField"
                                className="form-control"
                                value={selectedOption2}
                                onChange={handleSelectChange1}
                              >
                                <option value={selectedOption2}>
                                  Select One
                                </option>
                                {getAllSCatagoriesRes.map((dat, index) => (
                                  <option key={index} value={dat._id}>
                                    {dat.subcatagory}
                                  </option>
                                ))}
                                {/* Add more options as needed */}
                              </select>
                            </div>
                            <div>
                              <label>SubSubCatagory:</label>
                              <select
                                id="selectField"
                                className="form-control"
                                value={selectedOption2}
                                onChange={handleSelectChange2}
                              >
                                <option value="">Select One</option>
                                {getAllSSCatagoriesRes.map((dat, index) => {
                                  if (selectedOption1 === dat.subcatagoryid) {
                                    return (
                                      <option key={index} value={dat._id}>
                                        {dat.subsubcatagory}
                                      </option>
                                    );
                                  } else {
                                    return null; // Skip rendering if condition doesn't match
                                  }
                                })}
                              </select>
                            </div>

                            <br />
                            <div className="container">
                              <h5 style={{ marginLeft: "-320px" }}>
                                Choose Options:
                              </h5>
                              <div className="select-container">
                                <div className="select-options">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={selectedOptions.join(", ")}
                                    readOnly
                                  />
                                  <select
                                    className="hidden-select"
                                    onChange={handleOptionSelect}
                                  >
                                    <option value="">Select an option</option>
                                    {options.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="selected-options">
                                <strong>Selected Options:</strong>
                                <br />
                                {selectedOptions.map((option) => (
                                  <div key={option}>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="text"
                                        className="form-control m-3 with-border" // Add border styling
                                        placeholder={option}
                                        value={enteredValues[option] || ""}
                                        onChange={(event) =>
                                          handleInputChange(event, option)
                                        }
                                        onKeyPress={(event) =>
                                          handleInputKeyPress(event, option)
                                        }
                                      />
                                    </div>
                                    <div>
                                      {enteredValuesArray[option] &&
                                        enteredValuesArray[option].map(
                                          (value, index) => (
                                            <div
                                              key={index}
                                              className="entered-value"
                                            >
                                              {value}
                                              <span
                                                className="remove-option"
                                                onClick={() =>
                                                  handleRemoveValue(
                                                    option,
                                                    index
                                                  )
                                                }
                                              >
                                                &times;
                                              </span>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div
                              onClick={handleInsideClick}
                              style={{
                                marginTop: "20px",
                                marginLeft: "20px",
                                marginBottom: "0px",
                                width: "100px",
                                height: "100px",
                                border: "1px dashed #aaa",
                                borderRadius: "50%",
                                cursor: "pointer",
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "black",
                              }}
                            >
                              {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                              <h5 style={{ color: "white", marginTop: "30px" }}>
                                Choose Image
                              </h5>
                              <input
                                {...getInputProps()}
                                id="fileInput" // Ensure this ID is present
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  opacity: 0,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                            <center>
                              {" "}
                              <div className="container" spacing={1}>
                                {imagesFront.map((file, index) => (
                                  <div item key={file.name}>
                                    <div>
                                      <img
                                        src={file.preview}
                                        alt={file.name}
                                        width="300"
                                      />
                                      <span
                                        onClick={() => removeImage(index)}
                                        size="small"
                                      >
                                        <i
                                          class="fa fa-times-circle-o"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </center>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                              onClick={publishImage}
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProducts;
