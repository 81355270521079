import React from 'react'
import OptionAdmin from './options/OptionAdmin'
function AdminSettingInner({ options }) {
    return (
        <div>
            {
                !options && (
                    <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        height:'80vh'
                    }}>
                        <p 
                        style={{
                            fontSize:'50px'
                        }}
                        >Welcome to Admin's Setting</p>
                    </div>
                )
            }
            {
                options === 'Admins' && (
                    <>
                     <OptionAdmin />
                    </>
                   
                )
            }
        </div>
    )
}

export default AdminSettingInner
