import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Modal, Form, Card } from 'react-bootstrap';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import Env from '../../Environment/Env';

const FooterCard = ({ footer, onEdit, onSetActive, onDelete }) => {
    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>
                    <img src={footer.logo} alt="Footer Logo" style={{ width: '100px', height: 'auto' }} />
                </Card.Title>
                <Card.Text>
                    Address: {footer.address}
                    <br />
                    Phone: {footer.phoneNum}
                    <br />
                    Facebook: {footer.facebook}
                    <br />
                    Instagram: {footer.instagram}
                    <br />
                    Twitter: {footer.twitter}
                    <br />
                    LinkedIn: {footer.linkedIn}
                </Card.Text>
                <Button variant="danger" onClick={() => onDelete(footer._id)}> Delete </Button>
                <Button variant="primary" onClick={() => onEdit(footer)}> Edit </Button>
                <Button variant="success" onClick={() => onSetActive(footer)} className="ml-2">
                    {footer.isActive ? 'Active' : 'Set Active'}
                </Button>
            </Card.Body>
        </Card>
    );
};

const DynamicFooter = () => {
    const [footers, setFooters] = useState([]);
    const [selectedFooter, setSelectedFooter] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [newFooterData, setNewFooterData] = useState({
        logo: '',
        columns: [{ heading: '', headingLink: '', subMenu: [{ text: '', link: '' }] }],
        address: '',
        phoneNum: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedIn: '',
        isActive: false
    });

    useEffect(() => {
        fetchFooters();
    }, []);

    const fetchFooters = async () => {
        try {
            const response = await axios.get(`${Env.server}/api/footer`);
            setFooters(response.data);
        } catch (error) {
            console.error('Error fetching footers', error);
        }
    };

    const handleEdit = (footer) => {
        setSelectedFooter(footer);
        setNewFooterData(footer);
        setShowModal(true);
    };

    const handleSetActive = async (footer) => {
        try {
            await axios.put(`${Env.server}/api/footer/${footer._id}`, { ...footer, isActive: true });
            fetchFooters();
        } catch (error) {
            console.error('Error setting active footer', error);
        }
    };

    const handleSave = async () => {
        try {
            if (selectedFooter) {
                await axios.put(`${Env.server}/api/footer/${selectedFooter._id}`, newFooterData);
            } else {
                await axios.post(`${Env.server}/api/footer`, newFooterData);
            }
            setShowModal(false);
            fetchFooters();
        } catch (error) {
            console.error('Error saving footer', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewFooterData({ ...newFooterData, [name]: value });
    };

    const handleColumnChange = (index, event) => {
        const { name, value } = event.target;
        const updatedColumns = newFooterData.columns.map((column, colIndex) => {
            if (colIndex === index) {
                return { ...column, [name]: value };
            }
            return column;
        });
        setNewFooterData({ ...newFooterData, columns: updatedColumns });
    };

    const handleSubMenuChange = (colIndex, menuIndex, event) => {
        const { name, value } = event.target;
        const updatedColumns = newFooterData.columns.map((column, index) => {
            if (index === colIndex) {
                const updatedSubMenu = column.subMenu.map((menu, subIndex) => {
                    if (subIndex === menuIndex) {
                        return { ...menu, [name]: value };
                    }
                    return menu;
                });
                return { ...column, subMenu: updatedSubMenu };
            }
            return column;
        });
        setNewFooterData({ ...newFooterData, columns: updatedColumns });
    };

    const addColumn = () => {
        setNewFooterData({
            ...newFooterData,
            columns: [...newFooterData.columns, { heading: '', headingLink: '', subMenu: [{ text: '', link: '' }] }]
        });
    };

    const addSubMenu = (colIndex) => {
        const updatedColumns = newFooterData.columns.map((column, index) => {
            if (index === colIndex) {
                return { ...column, subMenu: [...column.subMenu, { text: '', link: '' }] };
            }
            return column;
        });
        setNewFooterData({ ...newFooterData, columns: updatedColumns });
    };

    const removeColumn = (colIndex) => {
        const updatedColumns = newFooterData.columns.filter((_, index) => index !== colIndex);
        setNewFooterData({ ...newFooterData, columns: updatedColumns });
    };

    const handleDeleteFooter = async(id)=>{
        try {
            const conf = await window.confirm('Do you want to delete this footer?');
            if(!conf){
                return;
            }
            await axios.delete(`${Env.server}/api/footer/${id}`)
            .then(()=>{
                fetchFooters();
            })
        } catch (error) {
            console.log('Error:: Unable to delete Footer ',error);
        }
    }

    const removeSubMenu = (colIndex, menuIndex) => {
        const updatedColumns = newFooterData.columns.map((column, index) => {
            if (index === colIndex) {
                const updatedSubMenu = column.subMenu.filter((_, subIndex) => subIndex !== menuIndex);
                return { ...column, subMenu: updatedSubMenu };
            }
            return column;
        });
        setNewFooterData({ ...newFooterData, columns: updatedColumns });
    };

    const uploadImage = async(image)=>{
        const formData = new FormData();
        formData.append('image',image);
        await axios.post(`${Env.server}/api/uploadik/single`,formData)
        .then((res)=>{
            console.log('Faraz getting response=>',res.data);
            setNewFooterData({...newFooterData,logo:res.data.url});
        })
        .catch(error=>{
            console.log('Error Uploading Image',error);
        })
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        uploadImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result); 
        };
        reader.readAsDataURL(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const currentPath = window.location.pathname;

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}
                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <h2>Footer Setting</h2>
                        <Container>
                            <Row>
                                {footers && Array.isArray(footers) && footers.map((footer) => (
                                    <Col lg={4} key={footer._id}>
                                        <FooterCard footer={footer} onEdit={handleEdit} onSetActive={handleSetActive} onDelete={handleDeleteFooter} />
                                    </Col>
                                ))}
                            </Row>
                            <Button variant="primary" onClick={() => setShowModal(true)}>Add New Footer</Button>
                        </Container>
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{selectedFooter ? 'Edit Footer' : 'Add New Footer'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="formLogo" onDrop={handleDrop} onDragOver={handleDragOver}>
                                        <Form.Label>Logo URL</Form.Label>
                                        <Form.Control type="text" name="logo" value={newFooterData.logo} onChange={handleInputChange} />
                                        <p>Or drop an image here</p>
                                        {imagePreview && 
                                        <div style={{position:'relative'}} >
                                            <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                            <button className='danger' 
                                            style={{fontSize:'22px',color:'red',fontWeight:'bold',position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} 
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                setImagePreview(null);
                                            }} >&times;</button>
                                        </div>
                                        }
                                    </Form.Group>
                                    {newFooterData.columns && newFooterData.columns.map((column, colIndex) => (
                                        <div key={colIndex} className="mb-3">
                                            <Form.Label>Column {colIndex + 1} Heading</Form.Label>
                                            <Form.Control type="text" name="heading" value={column.heading} onChange={(event) => handleColumnChange(colIndex, event)} />
                                            <Form.Label>Column {colIndex + 1} Heading Link</Form.Label>
                                            <Form.Control type="text" name="headingLink" value={column.headingLink} onChange={(event) => handleColumnChange(colIndex, event)} />
                                            {column.subMenu && column.subMenu.map((menu, menuIndex) => (
                                                <div key={menuIndex} className="mt-2">
                                                    <Col>
                                                        <Form.Control type="text" name="text" placeholder="Text" value={menu.text} onChange={(event) => handleSubMenuChange(colIndex, menuIndex, event)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" name="link" placeholder="Link" value={menu.link} onChange={(event) => handleSubMenuChange(colIndex, menuIndex, event)} />
                                                    </Col>
                                                    <Button variant="danger" onClick={() => removeSubMenu(colIndex, menuIndex)}>Remove SubMenu</Button>
                                                </div>
                                            ))}
                                            <Button variant="link" onClick={() => addSubMenu(colIndex)}>Add SubMenu</Button>
                                            <Button variant="danger" onClick={() => removeColumn(colIndex)}>Remove Column</Button>
                                        </div>
                                    ))}
                                    {newFooterData.columns.length < 3 && (
                                        <Button variant="link" onClick={addColumn}>Add Column</Button>
                                    )}
                                    <Form.Group controlId="formAddress">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" name="address" value={newFooterData.address} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formPhoneNum">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" name="phoneNum" value={newFooterData.phoneNum} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formFacebook">
                                        <Form.Label>Facebook URL</Form.Label>
                                        <Form.Control type="text" name="facebook" value={newFooterData.facebook} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formInstagram">
                                        <Form.Label>Instagram URL</Form.Label>
                                        <Form.Control type="text" name="instagram" value={newFooterData.instagram} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formTwitter">
                                        <Form.Label>Twitter URL</Form.Label>
                                        <Form.Control type="text" name="twitter" value={newFooterData.twitter} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group controlId="formLinkedIn">
                                        <Form.Label>LinkedIn URL</Form.Label>
                                        <Form.Control type="text" name="linkedIn" value={newFooterData.linkedIn} onChange={handleInputChange} />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                                <Button variant="primary" onClick={handleSave}>{selectedFooter ? 'Save Changes' : 'Add Footer'}</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DynamicFooter;
