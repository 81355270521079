import React, { useEffect, useState } from 'react'
import Env from '../../../../../Environment/Env'
import AdminCard from './AdminCard';
import axios from 'axios';
import AddAdmin from './AddAdmin';
function OptionAdmin() {
    const [flag,setFlag]=useState(true);
    useEffect(() => {
        async function gettingAdmins() {
            try {
                const response = await axios.get(`${Env.server}/api/admin/getAll`)
                console.log("getting admins", response.data.admins)
                setAdmins(response.data.admins)
            } catch (error) {
                console.log("There is an error in fetching admins", error)
            }
        }
        gettingAdmins();
    }, [flag])
    const [admins, setAdmins] = useState('')
    const [isAddAdmin,setAddAdmin]=useState(false)
    return (
        <>
            <div className="" style={{ margin: '20px' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '40px', textDecoration: 'underline', color: 'black'
                }
                }>Admins</h1>
            </div>
            <div className="" style={{
                display:'flex',
                justifyContent:'end',
                width:'90%'
            }}>
                <button style={{
                    padding: '12px',
                    backgroundColor: '#4CAF50', 
                    color: 'white', 
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer', 
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
                    transition: 'background-color 0.3s, box-shadow 0.3s', 
                }}
                onClick={()=> setAddAdmin(true)}
                >Add Admin</button>
            </div>
            <div style={{
                display: 'flex',
                gap: '25px',
                alignItems: 'center',
                flexWrap: 'wrap',
                padding: '50px',
            }}>
                {
                    admins ? admins.map((data, index) => (
                        <AdminCard
                            key={index}
                            admin={data}
                            onAdminDel={setFlag}
                        />
                    ))
                    :
                    "No admins available"
                }
            </div>
            <AddAdmin
            showModal={isAddAdmin}
            setShowModal={setAddAdmin}
            onAddAdmin={setFlag}
             />
        </>
    )
}

export default OptionAdmin
