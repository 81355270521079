import React, { useEffect, useState } from 'react'
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import "./css/productseq.css";
import axios from 'axios';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';


function SortableItem({ id, name,image,price }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'grab'
    };

    return (
        <div 
        ref={setNodeRef} 
        className='product-item' style={style} {...attributes} {...listeners}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <img src={getAsset(image)} alt={name} width={40} height={40} style={{objectFit:'cover'}} />
                <p style={{flex:'2'}} >{name}</p>
                <p style={{flex:'1'}} >{price}</p>
            </div>
        </div>
    );
}

function ModelSequence() {
    const currentPath = window.location.pathname;

    const [products,setProducts] = useState([]);
    // const [catagories,setCatagories] = useState([]);
    const [subcatagories,setSubCatagories] = useState([]);
    const [subsubcatagories,setSubSubCatagories] = useState([]);
    // const [filtercatagory,setFilterCatagory] = useState("");
    const [filtersubcatagory,setFilterSubCatagory] = useState("");
    const [filtersubsubcatagory,setFilterSubSubCatagory] = useState("");
    const [defaultProducts,setDefaultProducts] = useState([]);
    const [defaultSubSubCats,setDefaultSubSubCats] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [reload,setReload] = useState(false);


    const getAllSubCatagories = async() =>{ // Brand = Category
        axios.get(`${Env.server}/api/catagory/GetAllCatagory`)
            .then((res)=>{
                console.log('All Brands are',res.data.catagories);
                setSubCatagories(res.data.catagories)
            })
            .catch(error=>{
                console.log(error);
            })
        }
        
    

    const handleFilterProducts = () =>{
        let fprods = [...defaultProducts];
        if(filtersubcatagory.trim() !== ""){
            fprods = fprods.filter(prod=>prod.catagory === filtersubcatagory);
        }
        console.log('filter sub cat ===>',filtersubcatagory,fprods);
        if(filtersubsubcatagory.trim() !== ""){
            fprods = fprods.filter(prod => prod.subcatagory === filtersubsubcatagory);
        }
        console.log('filter sub sub cat ===>',filtersubsubcatagory,fprods);
        setProducts(fprods);
    }

    const getAllProducts = async()=>{ // Product = Model = SubCatagory
        axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
            .then((res) => {
                setProducts(res.data.subcatagories);
                console.log(res.data.subcatagories);
                setDefaultProducts(res.data.subcatagories);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }

    useEffect(()=>{
        handleFilterProducts();
    },[filtersubcatagory])

    const handleSubChange = (e) =>{
        setFilterSubCatagory(e.target.value)
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setProducts((prods) => {
                const oldIndex = prods.findIndex((item) => item._id === active.id);
                const newIndex = prods.findIndex((item) => item._id === over.id);
                console.log(`Item id ${products[oldIndex].subcatagory} has been moved to place of ${products[newIndex].subcatagory}`)
                updateProductSequence(products[oldIndex]._id,products[newIndex]._id);
                return arrayMove(prods, oldIndex, newIndex);
            });
        }
    };

    const updateProductSequence = async (subcatagoryId, targetSubcatagoryId) => {
        setIsLoading(true);
        try {
            // Send request to backend to update product sequence
            const response = await axios.put(`${Env.server}/api/subcatagory/updateSubcatagorySequence`, {
                subcatagoryId,
                targetSubcatagoryId,
            });
            setFilterSubCatagory(prev=>prev);
            setReload(prev=>!prev);
            console.log('Product sequence updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating product sequence:', error);
        }
        setIsLoading(false);
    };

    useEffect(()=>{
        getAllProducts();
        getAllSubCatagories();
    },[reload])

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    
                    <div className="content">
                        
                        <div className='seq-box-container' >
                            <div className='header-seq'>
                                <div className="">
                                    <h2 className="text-center noselect">Set Model Sequence</h2>
                                </div>
                                <div className="">
                                    <button disabled className="btn btn-primary" data-toggle="modal"  data-target="#exampleModalLong">
                                        <>{isLoading ?'Updating...' :'Updated ✅'}</>
                                    </button>
                                </div>
                            </div>

                            <div className='seq-filter-box'>
                                
                                <select 
                                    value={filtersubcatagory}
                                    className='seq-select' 
                                    onChange={handleSubChange} 
                                >
                                    <option value="" >All Brand-Types</option>
                                    {
                                        subcatagories && subcatagories.map(subcatagory=>(
                                            <option key={subcatagory.catagory} value={subcatagory.catagory} >{subcatagory.catagory}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className='seq-items-box'>
                                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                    <SortableContext items={products.map(item => item._id)} strategy={verticalListSortingStrategy}>
                                        {products && products.map((item) => (
                                            <SortableItem key={item._id} id={item._id}  name={item.subcatagory} image={item.imageSubCatagory[0]}  />
                                        ))}
                                    </SortableContext>
                                </DndContext>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModelSequence;


