import React, { useRef, Component, useEffect, useState } from "react";
import Linechart from "./linechart";
import Nextcharts from "./nextcharts";
import Morecharts from "./morecharts";
import Sidebar from "../main/sidebar";
import Searchbar from "../main/navigation/searchbar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";
import JoditEditor from "jodit-react";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import "./css/sscatagory.css";
// import { Grid, TextField, Chip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Label } from "@material-ui/icons";

const VideosSection = () => {
  const editor = useRef(null);

  const currentPath = window.location.pathname;

  const [UpdateImageData, setUpdateImageData] = useState({});
  const [EdtImageGaleryIndex, setEdtImageGaleryIndex] = useState();
  const [imagesFront, setImagesFront] = useState([]);
  const [imagesFront1, setImagesFront1] = useState([]);
  const [UpdateImageActive, setUpdateImageActive] = useState(false);
  const [image, setImages] = useState("");
  const [image1, setImages1] = useState("");
  const [responseImages1, setResponseImages1] = useState([]);
  const [responseImages, setResponseImages] = useState([]);
  const [allgetVideoData, setAllgetVideoData] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    channelName: '',
    videoLink: ''
  });

  useEffect(()=>{
    getAllVideos();
  },[])


  const getAllVideos = () => {
    axios.get(`${Env.server}/api/videoLanding/GetAllVideo`)
    .then((res)=>{
        console.log("getAllVideos====", res.data.data)
        setAllgetVideoData(res.data.data)
    })
    .catch((err) => {
        console.log("abc====", err)
    })
  }

  const handleDelete = async(id)=>{
    const conf = await window.confirm('Do you really want to delete this vedio?');
    if(!conf){
      return;
    }
    await axios.delete(`${Env.server}/api/videoLanding/delete/${id}`)
    .then(()=>{
      getAllVideos();
    })
    .catch(error=>{
      console.log('Error::Unable to delete the video',error);
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const submit = (name) => {
    // Assuming formData is defined somewhere in your code
    formData.Thumbnail = name; // Adding Thumbnail field with the value from name parameter
  
    console.log("Thumbnail:", name);
    console.log("Form data:", formData);
    axios.post(`${Env.server}/api/videoLanding/AddVideo`, formData)
    .then((res)=>{
        console.log("res",res.data)
        getAllVideos();
    })
    .catch((err) => {
        console.log("abc====", err)
    })
  };
  

  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => { 
      
    
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImagesFront(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      ); 
  };

  const removeImage = (index) => {
    
      const updatedImagesfront = [...imagesFront];
      updatedImagesfront.splice(index, 1);
      setImagesFront(updatedImagesfront);

      const updatedImages = [...image];
      updatedImages.splice(index, 1);
      setImages(updatedImages); 
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const handleUpdateImage = () => {
    const formData = new FormData();
    formData.append("imagefront", imagesFront1);
    console.log("muzz1-====", imagesFront1);
    image1.forEach((img, index) => {
      formData.append(`images`, img);
    });
    let objectNames = [];
    axios
      .post(`${Env.server}/api/uploadik/multiple`, formData)
      .then((res) => {
        // let resp = res.data.file;
        // for (let i = 0; i < resp.length; i++) {
        //   objectNames.push("/images/" + resp[i].filename);
        // }
        objectNames = res.data.urls;
        setResponseImages1(objectNames);
        // if (res.data.message === "imagesSaved") {
          console.log("avc-=-=", objectNames);
          setUpdateImageActive(false); 
            EditDataImage(
              { imageSubsubCatagory: objectNames },
              UpdateImageData._id,
              EdtImageGaleryIndex
            );
           
          // UpdateEditData({ imageSubsubCatagory: objectNames }, UpdateImageData._id)
        // } else {
        //   alert("error not in catch");
        // }
      })
      .catch((err) => {
        console.log("err------ooo", err);
        alert("catch error");
      });
  };

  const EditDataImage = (data, id, index) => {
    axios
      .patch(
        `${Env.server}/api/subsubcatagory/EditedDataimage/${id}/${index}`,
        data
      )
      .then((res) => {
        console.log("abc====", res.data); 
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const UpdateEditDataImage = (data, id) => {
    axios
      .patch(
        `${Env.server}/api/subsubcatagory/updateEditedDataimage/${id}`,
        data
      )
      .then((res) => {
        console.log("abc====", res.data); 

      })
      .catch((err) => {
        console.log("err====", err);
      });
  };



  const publishImage = () => {
    const formData = new FormData();
    formData.append("imagefront", imagesFront);
    console.log("muzz1-====", imagesFront);
    console.log("muzz2-====", image);
    image.forEach((img, index) => {
      formData.append(`images`, img);
    });
    let objectNames = [];
    axios
      .post(`${Env.server}/api/upload/multiple`, formData)
      .then((res) => {
        // let resp = res.data.file;
        // for (let i = 0; i < resp.length; i++) {
        //   objectNames.push("/images/" + resp[i].filename);
        // }
        objectNames = res.data.urls;
        setResponseImages(objectNames);

        // if (res.data.message === "imagesSaved") {
          submit(objectNames);
        // } else {
        //   alert("error not in catch");
        // }
      })
      .catch((err) => {
        console.log("err------ooo", err);
        alert("catch error");
      });
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}
          

          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">

{/* first line */}

                <div className="d-flex justify-content-between">
                    <div className="card-header">
                      <h2 className="text-center f-bold">
                        <b>Trade Site Video Section</b>
                      </h2>
                    </div>

                    <button
                      className="btn btn-primary m-4"
                      data-toggle="modal"
                      data-target="#addVideo"
                      style={{ height: "40px" }}
                    >
                      Add New Video
                    </button>
                  </div> 



                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {/* Replace the data in the template with actual data from your database */}
            {allgetVideoData && allgetVideoData.map((video, index) => (
                <div key={index} style={{ width: 'calc(33.33% - 20px)', marginBottom: '20px', border: '1px solid #ccc', padding: '10px', boxSizing: 'border-box' }}>
                    <h2 style={{ fontWeight: 'bold', marginBottom: '5px' }}>{video && video.title}</h2>
                    <div style={{ width: '100%', height: '200px', position: 'relative', overflow: 'hidden', marginBottom: '10px' }}>
                        <div style={{ width: '100%', height: '100%', backgroundImage: `url(${getAsset(video && video.Thumbnail && video.Thumbnail[0])})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative' }}>
                            <img src="https://ik.imagekit.io/cy8phtesy/118620_play_icon.png?updatedAt=1714770321890" alt="Play Icon" style={{ width: '50px', height: '50px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 0.8 }} />
                        </div>
                    </div>
                    <p style={{ marginBottom: '10px' }}>{video.description}</p>
                    <p style={{ fontStyle: 'italic', color: '#666' }}>Channel: {video.channelName}</p>
                    <a href={video.videoLink} target="_blank">Watch Video</a>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(video._id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                </div>
            ))}
        </div>

          </div>
          </div>
          </div>
          </div>

                    <div
                      class="modal fade"
                      id="addVideo"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Delete Product
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                           <input 
        className="form-control" 
        type="text" 
        name="title" 
        placeholder="title" 
        value={formData.title} 
        onChange={handleChange} 
      /> <br/>
      <input 
        className="form-control" 
        type="text" 
        name="description" 
        placeholder="description" 
        value={formData.description} 
        onChange={handleChange} 
      /> <br/>
      <input 
        className="form-control" 
        type="text" 
        name="channelName" 
        placeholder="channelName" 
        value={formData.channelName} 
        onChange={handleChange} 
      /><br/>
      <input 
        className="form-control" 
        type="text" 
        name="videoLink" 
        placeholder="videoLink" 
        value={formData.videoLink} 
        onChange={handleChange} 
      /><br/>
  
                            



                                <div
                                  onClick={handleInsideClick}
                                  style={{
                                    marginLeft: "20px",
                                    width: "100px",
                                    height: "100px",
                                    border: "1px dashed #aaa",
                                    borderRadius: "20px",
                                    cursor: "pointer",
                                    position: "relative",
                                    overflow: "hidden",
                                    backgroundColor: "black",
                                  }}
                                >
                                  {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                                  <h5
                                    style={{
                                      color: "white",
                                      marginTop: "30px",
                                    }}
                                  >
                                    Thumbnail
                                  </h5>
                                  <input
                                    {...getInputProps()}
                                    id="fileInput" // Ensure this ID is present
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      opacity: 0,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div> 
                            {/* image sectopin thumbnail */}

   <center>
                            {" "}
                            <div className="container" spacing={1}>
                              {imagesFront.map((file, index) => (
                                <div item key={file.name}>
                                  <div>
                                    <img
                                      src={file.preview}
                                      alt={file.name}
                                      width="300"
                                    />
                                    <span
                                      onClick={() => removeImage(index)}
                                      size="small"
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "30px",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {" "}
                                        &times;
                                      </i>
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </center>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                              onClick={publishImage}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>



                      
                    </div>

        </div>
      </div>
    </>
  );
};

export default VideosSection;
