import React, { Component, useEffect, useState, useRef } from "react";
import Switch from '@material-ui/core/Switch';
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const NavBarSetting = () => {
    const editor = useRef(null);

    const currentPath = window.location.pathname;
    const [getAllProductType, setgetAllProductType] = useState([]); 
    const [usefectTrue, setUsefectTrue] = useState(false);
    const [DataEdit, setDataEdit] = useState({});
    const [DataEditID, setDataEditID] = useState({});
    const [EditImage, setEditImage] = useState([]);
    const [ShowEditModal, setShowEditModal] = useState(false);

    

    useEffect(() => {
        getAllProduct();
    }, []);

    useEffect(() => {
        getAllProduct();
    }, [usefectTrue]);
 
    const [isRetailSelected, setIsRetailSelected] = useState(true); 
    const handleRetailClick = () => {
      setIsRetailSelected(true); 
    };

    const handleEditType=(data)=>{
        setDataEdit(data);
        setDataEditID(data);
        setEditImage(data.image)
        setTypeData(data.type)
        setShowEditModal(true);
    }

    const handleCloseModal=()=>{
        setShowEditModal(false);
        setDataEdit({});
        setEditImage([]);
        setImagesFront([])
        setTypeData("");
    }
  
    const handleTradeClick = () => {
      setIsRetailSelected(false);
    };
 
    const handleChangeTrade = (productId , check) => {
        console.log("abc-----", productId)
        console.log("check-----", !check)
        if(check === undefined){
            check=true
        } 
        toggleButtonTrade(productId, !check);
    };

    const handleChange = (productId , check) => {
        console.log("abc-----", productId)
        console.log("check-----", !check)
        if(check === undefined){
            check=true
        } 
        toggleButton(productId, !check);
    };

    
    const toggleButtonTrade = (productId, status) => {
        const navbarProducts = getAllProductType.filter(product => product.showNavbarTrade === true); 
        const navbarProductsCount = navbarProducts.length;
        console.log("navbarProductsCount===", navbarProductsCount);
        if (status === false){
            let body = { showNavbarTrade: status };      
            axios.patch(`${Env.server}/api/filters/updatetypeONOFF/${productId}`, body)
                .then((res) => {
                    console.log("abc====", res.data);   
                    getAllProduct()
                })
                .catch((err) => {
                    console.log("err====", err);
                });
        }else if(navbarProductsCount < 12 && status === true){
            let body = { showNavbarTrade: status };
            axios.patch(`${Env.server}/api/filters/updatetypeONOFF/${productId}`, body)
                .then((res) => {
                    console.log("abc====", res.data);
                    getAllProduct()
                })
                .catch((err) => {
                    console.log("err====", err);
                });
            }else{
                alert("You can only Activate 12 At a time")
            }
    };


    const toggleButton = (productId, status) => {
        const navbarProducts = getAllProductType.filter(product => product.showNavbar === true); 
        const navbarProductsCount = navbarProducts.length;
        console.log("navbarProductsCount===", navbarProductsCount);
        if (status === false){
            let body = { showNavbar: status };      
            axios.patch(`${Env.server}/api/filters/updatetypeONOFF/${productId}`, body)
                .then((res) => {
                    console.log("abc====", res.data);   
                    getAllProduct()
                })
                .catch((err) => {
                    console.log("err====", err);
                });
        }else if(navbarProductsCount < 12 && status === true){
            let body = { showNavbar: status };
            axios.patch(`${Env.server}/api/filters/updatetypeONOFF/${productId}`, body)
                .then((res) => {
                    console.log("abc====", res.data);
                    getAllProduct()
                })
                .catch((err) => {
                    console.log("err====", err);
                });
            }else{
                alert("You can only Activate 12 At a time")
            }
    };


    const getAllProduct = () => {
        axios
            .get(`${Env.server}/api/filters/gettype`)
            .then((res) => {
                // console.log("abc===>>", res.data.message)
                console.log("abc===>>", res.data.allType) 
                let resp = res.data.allType; 
                setgetAllProductType(resp);
            })
            .catch((err) => {
                console.log("err====>>>", err);
            });
    };

    const [imagesFront, setImagesFront] = useState([]);
    const [imagesFront1, setImagesFront1] = useState([]);
    const [image, setImages] = useState([]);
    const [UpdateImageActive, setUpdateImageActive] = useState(false);
    const [image1, setImages1] = useState("");
    const [responseImages, setResponseImages] = useState([]);
    const [typeData, setTypeData] = useState("");


    const publishImage = () => {
      console.log("abc====",typeData)
        const formData = new FormData();
        formData.append("imagefront", imagesFront1);
        console.log("muzz1-====", imagesFront1);
        console.log("muzz2-====", image);
        image.forEach((img, index) => {
          formData.append(`images`, img);
        });
        let objectNames = [];
        axios
          .post(`${Env.server}/api/uploadik/multiple`, formData)
          .then((res) => {
            // let resp = res.data.file;
            // for (let i = 0; i < resp.length; i++) {
            //   objectNames.push("/images/" + resp[i].filename);
            // }
            objectNames = res.data.urls;
            setResponseImages(objectNames);
            // if (res.data.message === "imagesSaved") {
              submit(objectNames);
            // } else {
            //   alert("error not in catch");
            // }
          })
          .catch((err) => {
            console.log("err------ooo", err);
            alert("catch error");
          });
      };
    
      const submit  = (imageFile) => {
        let data = { id: DataEdit._id, type: typeData, image: imageFile.length === 0 ? EditImage : imageFile };
 
if(ShowEditModal === true){
          axios   
          .patch(`${Env.server}/api/filters/updatetype/${DataEdit._id}`,data)
          .then((res) => {
            console.log("abc===>>", res.data.message);
            console.log("abc===>>", res.data.subsubcatagories);
            handleCloseModal();
            getAllProduct();
          })
          .catch((err) => {
            console.log("err====>>>", err);
          }); 
}else{
          axios 
          .post(`${Env.server}/api/filters/addtype`,data)
          .then((res) => {
            console.log("abc===>>", res.data.message);
            console.log("abc===>>", res.data.subsubcatagories);
            handleCloseModal();
            getAllProduct();
          })
          .catch((err) => {
            console.log("err====>>>", err);
          });
        }
      };


      


      const handleInsideClick = (e) => {
        e.stopPropagation();
        const isClickable =
          e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
        if (isClickable) {
          if (e.target.tagName === "DIV") {
            document.getElementById("fileInput").click(); // Trigger the file input click event
          }
        }
      };
    
      const onDrop = (acceptedFiles) => {
        setImages(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setImagesFront(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      };

      const removeImage = (index) => {
        const updatedImagesfront = [...imagesFront];
        updatedImagesfront.splice(index, 1);
        setImagesFront(updatedImagesfront);
    
        const updatedImages = [...image];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
      };

      const handleDelete = async(id)=>{
        try{
          const conf = await window.confirm('Do you really want to delete this type?');
          if(!conf){
            return;
          }
          await axios.delete(`${Env.server}/api/filters/deletetype/${id}`)
          .then(()=>{
            console.log('Deleted the type Successfully');
            getAllProduct();
          })
        }catch(error){
          console.log('Error occured while deleting type ', error);
        }
      }
    
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });




    return (
        <>
            <div
                className={
                    currentPath.includes("signup") || currentPath.includes("signin")
                        ? ""
                        : "wrapper"
                }
            >
                {currentPath.includes("signup") ||
                    currentPath.includes("signin") ? null : (
                    <Sidebar />
                )}

                <div
                    className={
                        currentPath.includes("signup") || currentPath.includes("signin")
                            ? ""
                            : "page-wrapper"
                    }
                >
                    {currentPath.includes("signup") ||
                        currentPath.includes("signin") ? null : (
                        <Header />
                    )}
                    <br/>
                    <div>
                      <button onClick={handleRetailClick} style={{ marginRight: '10px', backgroundColor: isRetailSelected ===  true ? 'green' : 'grey', color: 'white' , padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"    }}>Retail</button>
                      <button onClick={handleTradeClick} style={{ backgroundColor: isRetailSelected === false ? 'green' : 'grey', color: 'white' , padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"}}>Trade</button>
                    </div>
                    
                    <button className="btn btn-primary" data-toggle="modal"
                      data-target="#ModalDeleteFinal"  style={{  padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"}}>Add Type</button>

                    {isRetailSelected ? (
                        <>
                        <div className="container" style={{margin:"40px", backgroundColor:"white", borderRadius:"10px" , border:"1px solid transparent"}}>
                          <h1>Retail Navbar</h1> 
                          <br/>
                          <div className="row container">
                          {getAllProductType.map((product, index) => (
                              <div className="col-md-3" key={index}>
                                  <div className="card mb-3">
                                      <div className="card-body">
                                          <img src={getAsset(product && product.image)} style={{ width: "100px", minHeight: "80px" }} />
                                          <h5 className="card-title">{product.type}</h5>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                              <Switch
                                                  checked={product.showNavbar}
                                                  onChange={() => handleChange(product._id, product.showNavbar)}
                                                  inputProps={{ 'aria-label': 'controlled' }}

                                              />
                                              <EditIcon style={{ marginLeft: "10px", cursor: "pointer" }} data-toggle="modal" data-target="#ModalDeleteFinal" 
                                              onClick={() => handleEditType(product)}  />
                                              <DeleteIcon style={{ marginLeft: "10px", cursor: "pointer" }} 
                                              onClick={() => handleDelete(product._id)}  />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ))}
                          </div>
                        </div>
                        </>
                    ):(
                        <>
                          <div className="container" style={{margin:"40px", backgroundColor:"white", borderRadius:"10px" , border:"1px solid transparent"}}>
                            <h1>Trade Navbar</h1>
                            <br/>
                            <div className="row container">
                                {getAllProductType.map((product, index) => (
                                    <div className="col-md-3" key={index}>
                                        <div className="card mb-3">
                                            <div className="card-body">
                                            <img src={getAsset(product && product.image)} style={{width:"100px", minHeight:"80px"}} />
                                                <h5 className="card-title">{product.type}</h5>
                                                <Switch
                                                    checked={product.showNavbarTrade}
                                                    onChange={() => handleChangeTrade(product._id, product.showNavbarTrade )}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /> 
                                                  <EditIcon style={{ marginLeft: "10px", cursor: "pointer" }} data-toggle="modal" data-target="#ModalDeleteFinal" 
                                                  onClick={() => handleEditType(product)}  />
                                                  <DeleteIcon style={{ marginLeft: "10px", cursor: "pointer" }}  
                                                  onClick={() => handleDelete(product._id)}  />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                          </div>
                        </>
                    )}                    
                
                </div>
            </div>


            <div
                className="modal fade"
                id="ModalDeleteFinal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
                style={{ zIndex: "19990" }}
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-right"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="exampleModalLongTitle"
                      >
                        {ShowEditModal ? "Edit Type" : "Add New Type"}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={handleCloseModal}
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                      <div className="modal-body text-center p-5">


<input type="text" className="form-control" value={typeData} onChange={(e) => setTypeData(e.target.value)} placeholder="Enter Type" /><br/> 


                    
<div
                        onClick={handleInsideClick}
                        style={{
                          marginLeft: "20px",
                          width: "100px",
                          height: "100px",
                          border: "1px dashed #aaa",
                          borderRadius: "20px",
                          cursor: "pointer",
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "black",
                        }}
                      >
                        {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                        <h5 style={{ color: "white", marginTop: "30px" }}>
                          Choose Image
                        </h5>
                        <input
                          {...getInputProps()}
                          id="fileInput" // Ensure this ID is present
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <br />

{imagesFront.length > 0 ? (
<center>
                        {" "}
                        <div className="container" spacing={1}>
                          {imagesFront.map((file, index) => (
                            <div item key={file.name}>
                              <div>
                                <img
                                  src={file.preview}
                                  alt={file.name}
                                  width="350"
                                  height="auto"
                                />
                                <span
                                  onClick={() => removeImage(index)}
                                  size="small"
                                >
                                  <i
                                    class="fa fa-times-circle-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </center>
):null}
                  



                      {ShowEditModal  ? (
                        <>
                    <img src={getAsset(EditImage && EditImage)} alt="PhoneShop" style={{ width: "300px", height: "auto" }} />

                        </>
                      ):null}
              
<div></div> 



<button className="btn btn-primary btn-pill mt-3" data-dismiss="modal" aria-label="Close" onClick={publishImage}>{ShowEditModal ? "Update" : "Publish"}</button>




                      </div>

                  </div>
                </div>
            </div>
        </>
    );
};

export default NavBarSetting;