import React, { useEffect, useState } from 'react'
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import "./css/productseq.css";
import axios from 'axios';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';


function SortableItem({ id, name }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'grab'
    };

    return (
        <div 
        ref={setNodeRef} 
        className='product-item' style={style} {...attributes} {...listeners}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <p style={{flex:'2'}} >{name}</p>
            </div>
        </div>
    );
}


function BrandSequence() {
    const currentPath = window.location.pathname;

    const [products,setProducts] = useState([]);
    const [defaultProducts,setDefaultProducts] = useState([]);
    const [filtersubcatagory,setFilterSubCatagory] = useState("");
    const [filtersubsubcatagory,setFilterSubSubCatagory] = useState("");
    const [defaultSubSubCats,setDefaultSubSubCats] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [reload,setReload] = useState(false);
    const [types,setTypes] = useState([]);



    const getAllProducts = async()=>{
        axios.get(`${Env.server}/api/catagory/GetAllCatagory`)
            .then((res) => {
                setProducts(res.data.catagories);
                setDefaultProducts(res.data.catagories);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }

    // const getAllTypes = async() =>{
    //     axios.get(`${Env.server}/api/filters/gettype`)
    //     .then(res=>{
    //         console.log('aaaaaaaaaaaa',res.data.allType);
    //         setTypes(res.data.allType);
    //     })
    //     .catch((err)=>{
    //         console.error(err);
    //     })
    // }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setProducts((prods) => {
                const oldIndex = prods.findIndex((item) => item._id === active.id);
                const newIndex = prods.findIndex((item) => item._id === over.id);
                console.log(`Item id ${products[oldIndex].catagory} has been moved to place of ${products[newIndex].catagory}`)
                updateProductSequence(products[oldIndex]._id,products[newIndex]._id);
                return arrayMove(prods, oldIndex, newIndex);
            });
        }
    };

    // const handleTypeChange = (e)=>{
    //     setFilterSubCatagory(e.target.value);
    //     if(e.target.value === ""){
    //         setProducts(defaultProducts);
    //     }else{
    //         console.log(defaultProducts);
    //         const newProds = defaultProducts.filter(item=>item.type === e.target.value);
    //         console.log('aaaaaaaa',newProds);
    //     }
    // }

    const updateProductSequence = async (catagoryId, targetCatagoryId) => {
        setIsLoading(true);
        try {
            const response = await axios.put(`${Env.server}/api/catagory/updateCatagorySequence`, {
                catagoryId,
                targetCatagoryId,
            });
            setReload(prev=>!prev);
            console.log('Product sequence updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating product sequence:', error);
        }
        setIsLoading(false);
    };

    useEffect(()=>{
        // getAllTypes();
        getAllProducts();
    },[reload])

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    
                    <div className="content">
                        
                        <div className='seq-box-container' >
                            <div className='header-seq'>
                                <div className="">
                                    <h2 className="text-center noselect">Set Brand-Type Sequence</h2>
                                </div>  
                                <div className="">
                                    <button disabled className="btn btn-primary" data-toggle="modal"  data-target="#exampleModalLong">
                                        <>{isLoading ?'Updating...' :'Updated ✅'}</>
                                    </button>
                                </div>
                            </div>

                            {/* <div className='seq-filter-box'>
                                
                                <select 
                                    value={filtersubcatagory}
                                    className='seq-select' 
                                    onChange={handleTypeChange} 
                                >
                                    <option value="" >All Types</option>
                                    {
                                        types && types.map(type=>(
                                            <option key={type._id} value={type.type} >{type.type}</option>
                                        ))
                                    }
                                </select>
                            </div> */}

                            <div className='seq-items-box'>
                                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                    <SortableContext items={products.map(item => item._id)} strategy={verticalListSortingStrategy}>
                                        {products && products.map((item) => (
                                            <SortableItem key={item._id} id={item._id}  name={item.catagory} />
                                        ))}
                                    </SortableContext>
                                </DndContext>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default BrandSequence


