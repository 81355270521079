import React, { Component, useEffect, useState } from "react";
import Linechart from "./linechart";
import Nextcharts from "./nextcharts";
import Morecharts from "./morecharts";
import Sidebar from "../main/sidebar";
import Searchbar from "../main/navigation/searchbar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";

const AddCatagory = () => {
  const currentPath = window.location.pathname;
  const [CatagoryName, setCatagoryName] = useState("");
  const [getAllProductRes, setgetAllProductRes] = useState([]);
  const [usefectTrue, setUsefectTrue] = useState(false);
  const [image, setImages] = useState("");
  const [responseImages, setResponseImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [editID, setEditID] = useState("");

  useEffect(() => {
    getAllProduct();
  }, []);
  useEffect(() => {
    getAllProduct();
  }, [usefectTrue]);

  const handleDeleteFun = () => {
    console.log("c====>>>", deleteID);
    axios
      .delete(`${Env.server}/api/catagory/DeleteSingle/${deleteID}`)
      .then((res) => {
        console.log("abc===>>>", res.data.message);
        getAllProduct();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const submit = () => {
    const data = {
      catagory: CatagoryName,
    };
    if (!data) {
      alert("Fill All Fields");
    }
    console.log("abc=====>>", data);
    axios
      .post(`${Env.server}/api/catagory/addnew`, data)
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abc===>>", res.data.products);
        getAllProduct();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };
  const update = () => {
    const data = {
      catagory: CatagoryName,
    };
    if (!data) {
      alert("Fill All Fields");
    }
    axios
      .put(`${Env.server}/api/catagory/update/${editID}`, data)
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abc===>>", res.data.products);
        getAllProduct();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const publishImage = () => {
    const formData = new FormData();
    formData.append("imagefront", imagesFront);
    console.log("muzz1-====", imagesFront);
    console.log("muzz2-====", image);
    image.forEach((img, index) => {
      formData.append(`images`, img);
    });
    let objectNames = [];
    axios
      .post(`${Env.server}/api/uploadik/multiple`, formData)
      .then((res) => {
        // let resp = res.data.file;
        // for (let i = 0; i < resp.length; i++) {
        //   objectNames.push("/images/" + resp[i].filename);
        // }
        objectNames = res.data.urls;
        setResponseImages(objectNames);
        // if (res.data.message === "imagesSaved") {
          submit(objectNames);
        // } else {
        //   alert("error not in catch");
        // }
      })
      .catch((err) => {
        console.log("err------ooo", err);
        alert("catch error");
      });
  };

  const getAllProduct = () => {
    axios
      .get(`${Env.server}/api/catagory/GetAllCatagory`)
      .then(async (res) => {
        // console.log("abc===>>", res.data.message)
        // console.log("abc===>>", res.data.catagories)
        setgetAllProductRes(res.data.catagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="d-flex justify-content-between">
                    <div className="card-header">
                      <h2 className="text-center f-bold">
                        <b>Add Brand-Type Form</b>
                      </h2>
                    </div>
                    <button
                      className="btn btn-primary m-4"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                      style={{ height: "40px" }}
                    >
                      Add New Brand-Type
                    </button>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      height: "auto",
                    }}
                  >
                    <div class="container">
                      <div class="row">
                        {getAllProductRes.map((data, index) => (
                          <div
                            class="col-md-3"
                            key={index}
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="card" style={{ position: "relative" }}>
                              <span
                                className="edit-icon"
                                data-toggle="modal"
                                data-target="#editBrand"
                                onClick={() =>{
                                  setEditID(data._id)
                                  setCatagoryName(data.catagory);
                                  }}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  color: "green",
                                  fontSize: "20px",
                                  top: "3px",
                                  right: "40px",
                                  zIndex: 2,
                                }}
                              >
                                {/* &#9999; */}
                                ✏️
                              </span>
                              <span
                                className="delete-icon"
                                data-toggle="modal"
                                data-target="#exampleModalLong1"
                                onClick={() => setDeleteID(data._id)}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  color: "red",
                                  fontSize: "20px",
                                  top: "3px",
                                  right: "10px",
                                  zIndex: 2,
                                }}
                              >
                                &#x2716; {/* Unicode for an "X" character */}
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                {/* <img
                                                                    src={getAsset(data.imageCatagory[0])} // Add your image source here
                                                                    class="card-img-top"
                                                                    alt="..."
                                                                    style={{ height: '50px', width: '60px', marginRight: '10px' }}
                                                                /> */}
                                <div>
                                  <h5 className="mt-2">
                                    {" "}
                                    &nbsp;{data.catagory}
                                  </h5>{" "}
                                  {/* Use your data property */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="exampleModalLong"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Add Brand-Type
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="form-group">
                              <label
                                htmlFor="item"
                                style={{ display: "block", textAlign: "left" }}
                              >
                                Brand-Type Name:
                              </label>
                              <input
                                type="text"
                                id="item"
                                className="form-control"
                                value={CatagoryName}
                                onChange={(e) =>
                                  setCatagoryName(e.target.value)
                                }
                                placeholder="Type Brand Name"
                              />
                            </div>

                            <br />
                            <center>
                              {" "}
                              <div className="container" spacing={1}>
                                {imagesFront.map((file, index) => (
                                  <div item key={file.name}>
                                    <div>
                                      <img
                                        src={file.preview}
                                        alt={file.name}
                                        width="300"
                                      />
                                      <span
                                        onClick={() => removeImage(index)}
                                        size="small"
                                      >
                                        <i
                                          class="fa fa-times-circle-o"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </center>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                              onClick={submit}
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="editBrand"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Edit Brand-Type
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="form-group">
                              <label
                                htmlFor="item"
                                style={{ display: "block", textAlign: "left" }}
                              >
                                Brand-Type Name:
                              </label>
                              <input
                                type="text"
                                id="item"
                                className="form-control"
                                value={CatagoryName}
                                onChange={(e) =>
                                  setCatagoryName(e.target.value)
                                }
                                placeholder="Type Brand Name"
                              />
                            </div>
                            <br />
                            <center>
                              {" "}
                              <div className="container" spacing={1}>
                                {imagesFront.map((file, index) => (
                                  <div item key={file.name}>
                                    <div>
                                      <img
                                        src={file.preview}
                                        alt={file.name}
                                        width="300"
                                      />
                                      <span
                                        onClick={() => removeImage(index)}
                                        size="small"
                                      >
                                        <i
                                          class="fa fa-times-circle-o"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </center>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                              onClick={update}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="exampleModalLong1"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Delete Brand-Type
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <h4>Are you sure to delete?</h4>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                              onClick={handleDeleteFun}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCatagory;
