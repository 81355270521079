import React, { Component, useEffect, useState, useRef } from "react";
import Switch from '@material-ui/core/Switch';
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";

const DeliveryCharges = () => {
    const editor = useRef(null);

    const currentPath = window.location.pathname;
    const [standardDeliveryCharges, setStandardDeliveryCharges] = useState('');
    const [FreeDeliveryCharges, setFreeDeliveryCharges] = useState('');
    const [expressDeliveryCharges, setExpressDeliveryCharges] = useState('');
    const [standardDeliveryChargesTrade, setStandardDeliveryChargesTrade] = useState(''); // Trade
    const [FreeDeliveryChargesTrade, setFreeDeliveryChargesTrade] = useState(''); // Trade
    const [expressDeliveryChargesTrade, setExpressDeliveryChargesTrade] = useState(''); // Trade
    const [getdata, setgetdata] = useState({});

useEffect(()=>{
  getAllDeliveryCharges();
},[])

const [isRetailSelected, setIsRetailSelected] = useState(true); 
const handleRetailClick = () => {
  setIsRetailSelected(true); 
};

const handleTradeClick = () => {
  setIsRetailSelected(false); 
};


const getAllDeliveryCharges=()=>{
    axios.get(`${Env.server}/api/deliverycharges/getAll`)
        .then((res) => {
            console.log("res=====", res.data.data);
            // setgetdata(res.data.data)
            setStandardDeliveryCharges(res.data.data.standard)
            setExpressDeliveryCharges(res.data.data.express)
            setFreeDeliveryCharges(res.data.data.freelimit)
            setStandardDeliveryChargesTrade(res.data.data.standardTrade)
            setExpressDeliveryChargesTrade(res.data.data.expressTrade)
            setFreeDeliveryChargesTrade(res.data.data.freelimitTrade)
        })
        .catch((err) => {
            console.log("err====", err)
        })
}

    const handleStandardDeliveryChargesChange = (event) => {
        setStandardDeliveryCharges(event.target.value);
    };
    const handleFreeDeliveryChargesChange = (event) => {
        setFreeDeliveryCharges(event.target.value);
    };

    const handleExpressDeliveryChargesChange = (event) => {
        setExpressDeliveryCharges(event.target.value);
    };



    const handleStandardDeliveryChargesChangeTrade = (event) => {
        setStandardDeliveryChargesTrade(event.target.value);
    };
    const handleFreeDeliveryChargesChangeTrade = (event) => {
        setFreeDeliveryChargesTrade(event.target.value);
    };

    const handleExpressDeliveryChargesChangeTrade = (event) => {
        setExpressDeliveryChargesTrade(event.target.value);
    };

    const handleSubmit = () => {
        console.log("Standard Delivery Charges:", standardDeliveryCharges);
        console.log("Express Delivery Charges:", expressDeliveryCharges);
        console.log("Free Delivery Charges:", FreeDeliveryCharges);

        
        let data = {
            standard: standardDeliveryCharges,
            express: expressDeliveryCharges,
            freelimit: FreeDeliveryCharges
        }
        axios.post(`${Env.server}/api/deliverycharges/addnew`, data)
        .then((res) => {
            console.log("res=====", res.data)
        })
        .catch((err) => {
            console.log("err====", err)
        })

    };


    const handleSubmitTrade = () => {
        console.log("Standard Delivery Charges Trade:", standardDeliveryChargesTrade);
        console.log("Express Delivery Charges Trade:", expressDeliveryChargesTrade);
        console.log("Free Delivery Charges Trade:", FreeDeliveryChargesTrade);

        
        let data = {
            standardTrade: standardDeliveryChargesTrade,
            expressTrade: expressDeliveryChargesTrade,
            freelimitTrade: FreeDeliveryChargesTrade
        }
        axios.post(`${Env.server}/api/deliverycharges/addnew`, data)
        .then((res) => {
            console.log("res=====", res.data)
        })
        .catch((err) => {
            console.log("err====", err)
        })

    }; 

    return (
        <>
            <div
                className={
                    currentPath.includes("signup") || currentPath.includes("signin")
                        ? ""
                        : "wrapper"
                }
            >
                {currentPath.includes("signup") ||
                    currentPath.includes("signin") ? null : (
                    <Sidebar />
                )}

                <div
                    className={
                        currentPath.includes("signup") || currentPath.includes("signin")
                            ? ""
                            : "page-wrapper"
                    }
                >
                    {currentPath.includes("signup") ||
                        currentPath.includes("signin") ? null : (
                        <Header />
                    )}




                    <div>
      <button onClick={handleRetailClick} style={{ marginRight: '10px', backgroundColor: isRetailSelected ===  true ? 'green' : 'grey', color: 'white' , padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"    }}>Retail</button>
      <button onClick={handleTradeClick} style={{ backgroundColor: isRetailSelected === false ? 'green' : 'grey', color: 'white' , padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"}}>Trade</button>
    </div>
{isRetailSelected === true? (
    <>
    <div className="container" style={{ marginTop: "40px", marginBottom: "40px" }}>
    <h2>Retail Site Delivery Charges</h2>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label htmlFor="standardDeliveryCharges">Standard Charges:</label>
                                <input
                                    type="number"
                                    id="standardDeliveryCharges"
                                    className="form-control"
                                    value={standardDeliveryCharges}
                                    onChange={handleStandardDeliveryChargesChange}
                                    placeholder="Standard Delivery Charges"
                                />
                            </div> 
                            <div className="col-md-5">
                                <label htmlFor="expressDeliveryCharges">Express Charges:</label>
                                <input
                                    type="number"
                                    id="expressDeliveryCharges"
                                    className="form-control"
                                    value={expressDeliveryCharges}
                                    onChange={handleExpressDeliveryChargesChange}
                                    placeholder="Express Delivery Charges"
                                />
                            </div>
                            <div className="col-md-1"></div>

                        </div> 
<br/><br/> 

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label htmlFor="standardDeliveryCharges">Free Charges limit:</label>
                                <input
                                    type="number"
                                    id="standardDeliveryCharges"
                                    className="form-control"
                                    value={FreeDeliveryCharges}
                                    onChange={handleFreeDeliveryChargesChange}
                                    placeholder="Standard Delivery Charges"
                                />
                            </div>
                            <div className="col-md-5">
                            </div>
                            <div className="col-md-1"></div>

                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
                            </div>
                        </div>
                    </div>
    </>
):(
    <>
    <div className="container" style={{ marginTop: "40px", marginBottom: "40px" }}>
    <h2>Trade Site Delivery Charges</h2>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label htmlFor="standardDeliveryCharges">Standard Charges:</label>
                                <input
                                    type="number"
                                    id="standardDeliveryCharges"
                                    className="form-control"
                                    value={standardDeliveryChargesTrade}
                                    onChange={handleStandardDeliveryChargesChangeTrade}
                                    placeholder="Standard Delivery Charges"
                                />
                            </div> 
                            <div className="col-md-5">
                                <label htmlFor="expressDeliveryCharges">Express Charges:</label>
                                <input
                                    type="number"
                                    id="expressDeliveryCharges"
                                    className="form-control"
                                    value={expressDeliveryChargesTrade}
                                    onChange={handleExpressDeliveryChargesChangeTrade}
                                    placeholder="Express Delivery Charges"
                                />
                            </div>
                            <div className="col-md-1"></div>

                        </div>



<br/><br/>


                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label htmlFor="standardDeliveryCharges">Free Charges limit:</label>
                                <input
                                    type="number"
                                    id="standardDeliveryCharges"
                                    className="form-control"
                                    value={FreeDeliveryChargesTrade}
                                    onChange={handleFreeDeliveryChargesChangeTrade}
                                    placeholder="Standard Delivery Charges"
                                />
                            </div>
                            <div className="col-md-5">
                            </div>
                            <div className="col-md-1"></div>

                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <button className="btn btn-primary" onClick={handleSubmitTrade}>Update</button>
                            </div>
                        </div>
                    </div>
    </>
)}
                   
                </div>
            </div>


        </>
    );
};

export default DeliveryCharges;