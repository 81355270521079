import React, { Component, useEffect, useState, useRef } from "react";
import Switch from '@material-ui/core/Switch';
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import TradeNewArrival from "../charts/TradeNewArrival";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";
import RetailNewArrival from "./RetailNewArrival";


const NewArrivals = () => {
    const editor = useRef(null);

    const currentPath = window.location.pathname;
    const [isRetailSelected, setIsRetailSelected] = useState(true); 
    const handleRetailClick = () => {
        setIsRetailSelected(true); 
    };  

    const handleTradeClick = () => {
        setIsRetailSelected(false); 
    };
    
    return (
        <>
            <div
                className={
                    currentPath.includes("signup") || currentPath.includes("signin")
                        ? ""
                        : "wrapper"
                }
            >
                {currentPath.includes("signup") ||
                    currentPath.includes("signin") ? null : (
                    <Sidebar />
                )}

                <div
                    className={
                        currentPath.includes("signup") || currentPath.includes("signin")
                            ? ""
                            : "page-wrapper"
                    }
                >
                    {currentPath.includes("signup") ||
                        currentPath.includes("signin") ? null : (
                        <Header />
                    )}

                    <div>
      <button onClick={handleRetailClick} style={{ marginRight: '10px', backgroundColor: isRetailSelected ===  true ? 'green' : 'grey', color: 'white' , padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"    }}>Retail</button>
      <button onClick={handleTradeClick} style={{ backgroundColor: isRetailSelected === false ? 'green' : 'grey', color: 'white' , padding:"10px" , border:"1px solid transparent" , borderRadius:"10px"}}>Trade</button>
    </div>

        {isRetailSelected === false ? (
            <>
                <TradeNewArrival />
            </>
        ):(
            <>
                <RetailNewArrival/>
            </>
        )}
    
                </div>
            </div> 
          

        </>
    );
};

export default NewArrivals;