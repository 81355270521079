import React, { Component , useState} from "react";
import * as actionTypes from "../../store/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../../Environment/Env";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false
    };
    this.getTradeOnOff();
  }

  getTradeOnOff = () => {
    axios.get(`${Env.server}/api/traderOnOff/GetShowHide`)
    .then((res) => {
      console.log("response====", res.data.data[0].TradeShow)
      this.setState({isChecked : res.data.data[0].TradeShow})
    })
    .catch((err)=>{
      console.log("Errr======", err)
    })
  }

  handleToggle = () => {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked
    }), () => {
      console.log(this.state.isChecked , 'Toggle button is now:', this.state.isChecked ? 'on' : 'off');
      axios.post(`${Env.server}/api/traderOnOff/TradeOnOff`, {data : this.state.isChecked})
      .then((res) => {
        console.log("abc====", res.data)
        this.getTradeOnOff();
      })
      .catch((err)=>{
        console.log("Errr======", err)
      })
    });
  };

 

  render() {
    const currentPath = window.location.pathname;

    const { isChecked } = this.state;

    const divStyle = {
      position: 'relative',
      cursor: 'pointer',
      display: 'block',
      width: '50px',
      height: '30px',
      background: isChecked ? '#000000' : '#cccccc',
      borderRadius: '50px',
      transition: '300ms linear'
    };

    const labelStyle = {
      position: 'absolute',
      top: '50%',
      left: isChecked ? '23px' : '2px',
      transform: 'translateY(-50%)',
      content: '',
      display: 'block',
      width: '25px',
      height: '25px',
      background: '#ffffff',
      borderRadius: '50%',
      transition: 'left 300ms linear',
      cursor:"pointer"
    };

    const inputStyle = {
      display: 'none'
    };
    
    return (
      <aside className="left-sidebar bg-sidebar">
        <div id="sidebar" className="sidebar sidebar-with-footer">
          <div className="app-brand">
            <a href="/">
              <svg
                className="brand-icon"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                width="30"
                height="33"
                viewBox="0 0 30 33"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    className="logo-fill-blue"
                    fill="#7DBCFF"
                    d="M0 4v25l8 4V0zM22 4v25l8 4V0z"
                  />
                  <path
                    className="logo-fill-white"
                    fill="#FFF"
                    d="M11 4v25l8 4V0z"
                  />
                </g>
              </svg>
              <span className="brand-name">Dashboard</span>
            </a>
          </div>
          <div className="sidebar-scrollbar">
            <ul className="nav sidebar-inner" id="sidebar-menu">
              <li
                className={
                  this.props.hasPages ||
                  this.props.hasChart ||
                  currentPath.includes("profile") ||
                  currentPath.includes("invoice") ||
                  currentPath.includes("error")
                    ? ""
                    : "active"
                }
                onClick={this.props.dashboard}
              >
                <Link
                  className="sidenav-item-link"
                  to="/"
                  data-toggle="collapse"
                  data-target="#dashboard"
                  aria-expanded="false"
                  aria-controls="dashboard"
                >
                  <i className="mdi mdi-view-dashboard-outline"></i>
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>

              <li>
                <Link to="/profile" className="sidenav-item-link">
                  <i className="mdi mdi-account"></i>
                  <span className="nav-text">Profile</span>
                </Link>
              </li>

              {/* <li
                className={
                  this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"
                }
                onClick={this.props.product}
              >
                <a
                  className={
                    this.props.hasSubAuth
                      ? "sidenav-item-link"
                      : "sidenav-item-link collapsed"
                  }
                  href="javascript:void(0)"
                  data-toggle="collapse"
                  data-target="#products"
                  aria-expanded="false"
                  aria-controls="products"
                >
                  <i className="mdi mdi-image-filter-none"></i>
                  <span className="nav-text">Products</span>{" "}
                  <b className="caret"></b>
                </a>
                <ul
                  className={
                    this.props.hasSubAuth ? "collapse show" : "collapse"
                  }
                  id="products"
                  data-parent="#sidebar-menu"
                >
                  <div className="sub-menu">
                    <li>
                      <Link className="sidenav-item-link" to="/AddProducts">
                        <span className="nav-text">Add Product</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/Sellerproducts">
                        <span className="nav-text">Seller Product</span>
                      </Link>
                    </li>
                  </div>
                </ul>
              </li> */}

              <li
                className={
                  this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"
                }
                onClick={this.props.addCategory}
              >
                <a
                  className={
                    this.props.hasSubAuth
                      ? "sidenav-item-link"
                      : "sidenav-item-link collapsed"
                  }
                  href="javascript:void(0)"
                  data-toggle="collapse"
                  data-target="#addCategory"
                  aria-expanded="false"
                  aria-controls="addCategory"
                >
                  <i className="mdi mdi-plus"></i>
                  <span className="nav-text">Add Products</span>{" "}
                  <b className="caret"></b>
                </a>
                <ul
                  className={
                    this.props.hasSubAuth ? "collapse show" : "collapse"
                  }
                  id="addCategory"
                  data-parent="#sidebar-menu"
                >
                  <div className="sub-menu">
                    <li>
                      <Link className="sidenav-item-link" to="/AddCatagory">
                        <span className="nav-text">Add Brand-Type</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/AddSubCatagory">
                        <span className="nav-text">Add Brand Models</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/AddSubSubCatagory"
                      >
                        <span className="nav-text">Add Accessories</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/TypesSequence"
                      >
                        <span className="nav-text">Types Sequence</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/BrandSequence"
                      >
                        <span className="nav-text">Brand-Type Sequence</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/ModelSequence"
                      >
                        <span className="nav-text">Model Sequence</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/AccessorySequence"
                      >
                        <span className="nav-text">Accessories Sequence</span>
                      </Link>
                    </li>

                  </div>
                </ul>
              </li>

              <li
                className={
                  this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"
                }
                onClick={this.props.charts}
              >
                <a
                  className={
                    this.props.hasSubAuth
                      ? "sidenav-item-link"
                      : "sidenav-item-link collapsed"
                  }
                  href="javascript:void(0)"
                  data-toggle="collapse"
                  data-target="#orders"
                  aria-expanded="false"
                  aria-controls="orders"
                >
                  <i className="mdi mdi-chart-pie"></i>
                  <span className="nav-text">Orders</span>{" "}
                  <b className="caret"></b>
                </a>
                <ul
                  className={
                    this.props.hasSubAuth ? "collapse show" : "collapse"
                  }
                  id="orders"
                  data-parent="#sidebar-menu"
                > 
                    <div className="sub-menu">
                      <li>
                        <Link className="sidenav-item-link" to="/AllOrders">
                          <span className="nav-text">All Orders</span>
                        </Link>
                      </li> 
                  </div>
                </ul>
              </li>

            
              <li
                className={
                  this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"
                }
                onClick={this.props.reports}
              >
                <a
                  className={
                    this.props.hasSubAuth
                      ? "sidenav-item-link"
                      : "sidenav-item-link collapsed"
                  }
                  href="javascript:void(0)"
                  data-toggle="collapse"
                  data-target="#reports"
                  aria-expanded="false"
                  aria-controls="reports"
                >
                  <i className="mdi mdi-file-chart"></i>
                  <span className="nav-text">Manage Site</span>{" "} 
                  <b className="caret"></b>
                </a>
                <ul
                  className={
                    this.props.hasSubAuth ? "collapse show" : "collapse"
                  }
                  id="reports"
                  data-parent="#sidebar-menu"
                >
                  <div className="sub-menu">
                    <li>
                      <Link className="sidenav-item-link" to="/NavBarSetting">
                        <span className="nav-text">NavBar</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/BrandInfo">
                        <span className="nav-text">Website Info</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/deliveryCharges">
                        <span className="nav-text">Delivery Charges</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/dynamicmodels">
                        <span className="nav-text">Models</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/dynamicltos">
                        <span className="nav-text">LTOs</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/newarrivals">
                        <span className="nav-text">New Arivals</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/dynamichotsell">
                        <span className="nav-text">Hot Selling</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="sidenav-item-link" to="/Footer">
                        <span className="nav-text">Footer</span>
                      </Link>
                    </li>
                  </div>
                </ul>
              </li>

              <li
                className={
                  this.props.hasSubAuth ? "has-sub active expand  " : "has-sub"
                }
                onClick={this.props.dealsAndOffers}
              >
                <a
                  className={
                    this.props.hasSubAuth
                      ? "sidenav-item-link"
                      : "sidenav-item-link collapsed"
                  }
                    href="javascript:void(0)"
                  data-toggle="collapse"
                  data-target="#tradeSite"
                  aria-expanded="false"
                  aria-controls="tradeSite"
                  style={{cursor:"default"}}
                >
                  <i className="mdi mdi-gift"></i>
                  <span className="nav-text">Trade Site</span>{" "} 
                  {/* <b className="caret"></b> */}
                  
              <span style={divStyle} onClick={this.handleToggle}>
                  <input type="checkbox" id="toggle-btn" style={inputStyle} checked={isChecked} />
                  <label htmlFor="toggle-btn" style={labelStyle}></label>
                </span>

                </a>
                <ul
                  className={
                    this.props.hasSubAuth ? "collapse show" : "collapse"
                  }
                  id="tradeSite"
                  data-parent="#sidebar-menu"
                >
                  <div className="sub-menu">
                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/VideosSection" 
                      >
                        <span className="nav-text">Video Section</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="sidenav-item-link"
                        to="/SignupApproval"
                      >
                        <span className="nav-text">Trade Approval</span>
                      </Link>
                    </li>
                  </div>
                </ul>
              </li>


              <li>
                <Link to="/subscribers" className="sidenav-item-link">
                  <i className="mdi mdi-email"></i>
                  <span className="nav-text">Subscribers</span>
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    hasChart: state.hasChart,
    hasPages: state.hasPages,
    hasSubAuth: state.hasSubAuth,
    hasProfile: state.hasProfile,
    hasOtherPages: state.hasOtherPages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profiles: () => dispatch({ type: actionTypes.HAS_PROFILE }),
    charts: () => dispatch({ type: actionTypes.HAS_CHART }),
    pages: () => dispatch({ type: actionTypes.HAS_PAGES }),
    auths: () => dispatch({ type: actionTypes.HAS_SUB_AUTH }),
    others: () => dispatch({ type: actionTypes.HAS_OTHER_PAGES }),
    dashboard: () => dispatch({ type: actionTypes.DASHBOARD }),
    invoice: () => dispatch({ type: actionTypes.INVOICE_MENU }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
