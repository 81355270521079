import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../main/sidebar";
import axios from "axios";
import Env from "../../Environment/Env";

import Header from "../main/header";

const SignUpApproval = () => {
  const [traders, setTraders] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedTrader, setSelectedTrader] = useState(null);
  const [AllDataSelectedModal, setAllDataSelectedModal] = useState({});
  

  const toggleApproval = (id) => {
    setTraders(traders.map(trader =>
      trader.id === id ? { ...trader, approved: !trader.approved } : trader
    ));
  };

useEffect(() => {
  getAllUserForAprove()
},[])


const getAllUserForAprove=()=>{ 
  axios.get(`${Env.server}/api/trader/GetAllInActiveUser`)
  .then((res) => {
    console.log("abcbbbb=====", res.data.trader)
    setTraders(res.data.trader)
  })
  .catch((err) => {
    console.log("err====", err);
  })
}

const ApproveUser=(id)=>{
  axios.patch(`${Env.server}/api/trader/VerifyUserByAdmin/${id}`)
  .then((res) => {
    console.log("abcbbbb=====", res.data.trader)
    toggleModal(null);
    getAllUserForAprove();
  })
  .catch((err) => {
    console.log("err====", err);
  })
}


  const toggleModal = (trader) => {
    setShowModal(!showModal);
    setSelectedTrader(trader);
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <div className="page-wrapper">
          <Header />
          <div className="content" style={{ backgroundColor: "white" }}>
            <h2>Trader Sign Up Approval</h2> 
          </div>
          <div className="row">
      <div className="col-12 col-md-12 col-lg-12">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {traders.map((item, index) => (
              <tr key={index}>
                <td>{item.firstName}{" "}{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.contactNumber}</td>
                <td>
                  <button className="btn btn-primary" onClick={() => {
                    setShowModal(true)
                    setAllDataSelectedModal(item)
                    console.log("abc-----", item)
                    }}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    
        </div>
      </div>
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Trader Details</h5>
                <button type="button" className="close" onClick={() => toggleModal(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body"> 
                  <>
                   
                  {/* AllDataSelectedModal */}
                  (
    <div className="user-details">
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>First Name:</span>
        <span className="value">{AllDataSelectedModal.firstName}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Last Name:</span>
        <span className="value">{AllDataSelectedModal.lastName}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Email:</span>
        <span className="value">{AllDataSelectedModal.email}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Company Name:</span>
        <span className="value">{AllDataSelectedModal.companyName}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Address:</span>
        <span className="value">{AllDataSelectedModal.address}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Company Address:</span>
        <span className="value">{AllDataSelectedModal.companyAddress}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>City:</span>
        <span className="value">{AllDataSelectedModal.city}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Contact Number:</span>
        <span className="value">{AllDataSelectedModal.contactNumber}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Trading Contact Number:</span>
        <span className="value">{AllDataSelectedModal.tradingContactNumber}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Trading Address:</span>
        <span className="value">{AllDataSelectedModal.tradingAddress}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Currently Selling Phones:</span>
        <span className="value">{AllDataSelectedModal.currentlySellingPhones}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>More Information:</span>
        <span className="value">{AllDataSelectedModal.moreInformation}</span>
      </div>
      <div className="detail" style={{ marginBottom: '10px' }}>
        <span className="variable" style={{ marginRight: '10px', fontWeight: 'bold' }}>Email Verified:</span>
        <span className="value">{AllDataSelectedModal.verified ? "Yes" : "No"}</span>
      </div>
    </div>
                   
                  </> 
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => toggleModal(null)}>Close</button>
                <button className="btn btn-primary" type="button" onClick={() => ApproveUser(AllDataSelectedModal._id)}>Approve</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpApproval;
