import React, { useState, useEffect } from 'react';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';

const DynamicModels = () => {
    const currentPath = window.location.pathname;
    const [cards, setCards] = useState([]);
    const [currentCard, setCurrentCard] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        fetchCards();
    }, []);

    const fetchCards = async () => {
        try {
            const res = await axios.get(`${Env.server}/api/models/getAll`);
            console.log(res.data);
            setCards(res.data);
        } catch (err) {
            console.error("Error fetching cards", err);
        }
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImage(file);
        setPreview(URL.createObjectURL(file));
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: handleDrop
    });



    const handleSave = async () => {
        try {
            let imageUrl = currentCard ? currentCard.image : '';

            if (image) {
                const formData = new FormData();
                formData.append('image', image);
                const uploadRes = await axios.post(`${Env.server}/api/uploadik/single`, formData);
                // imageUrl = `/images/${uploadRes.data.filename}`;
                imageUrl = uploadRes.data.url;
            }

            const cardData = { image: imageUrl, title, description, link };

            if (currentCard) {
                await axios.put(`${Env.server}/api/models/update/${currentCard._id}`, cardData);
            } else {
                await axios.post(`${Env.server}/api/models/create`, cardData);
            }

            setModalOpen(false);
            fetchCards();
            clearForm();
        } catch (err) {
            console.error("Error saving card", err);
        }
    };


    // const handleSave = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('image',image);
    //         let imageUrl = "";
    //         axios
    //             .post(`${Env.server}/api/upload/single`, formData)
    //             .then(async(res) => {
    //                 console.log('aaaaaaaaaaaaaaaaa',res.data);
    //                 imageUrl = "/images/" + res.data.filename;
    //                 console.log(imageUrl,title,description,link);
    //                 if (currentCard) {
    //                     await axios.put(`${Env.server}/api/models/update/${currentCard._id}`, {image:imageUrl, title,description,link});
    //                 } else {
    //                     await axios.post(`${Env.server}/api/models/create`, {image:imageUrl,title,description,link});
    //                 }
    //                 setModalOpen(false);
    //                 fetchCards();
    //                 clearForm();
    //             })
    //             .catch(error=>{
    //                 console.log(error);
    //             });
    //     } catch (err) {
    //         console.error("Error saving card", err);
    //     }
    // };


    const handleDelete = async(id)=>{
        const conf = await window.confirm('Do you realy want to delete this model?');
        if(!conf){
            return;
        }
        await axios.delete(`${Env.server}/api/models/${id}`)
        .then((res)=>{
            console.log('Deleted Model Successfuly!');
            fetchCards();
        })
        .catch((err)=>{
            console.log('Error Deleting Model:',err);
        })
    }

    const handleEdit = (card) => {
        setCurrentCard(card);
        setTitle(card.title);
        setDescription(card.description);
        setLink(card.link);
        setPreview(getAsset(card.image));
        setModalOpen(true);
    };

    const handleAdd = () => {
        clearForm();
        setModalOpen(true);
    };

    const handleSetMain = async (card) => {
        try {
            const updatedCards = cards.map(c => ({ ...c, isMain: c._id === card._id }));
            setCards(updatedCards);
            await axios.post(`${Env.server}/api/models/setMain/${card._id}`);
        } catch (err) {
            console.error("Error setting main card", err);
        }
    };

    const clearForm = () => {
        setCurrentCard(null);
        setTitle('');
        setDescription('');
        setLink('');
        setImage(null);
        setPreview(null);
    };

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflowY = modalOpen ? 'hidden' : 'auto';
    }, [modalOpen]);

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}
                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="container-fluid">
                        <div className="pb-3">
                            <h1 className="text-center mb-4 fs-2" style={{ fontFamily: "poppins", fontWeight: "bolder" }}>Models Dynamic Setting</h1>
                        </div>
                        <button className="btn btn-primary mb-4" onClick={handleAdd}>Add New Card</button>
                        <div className="row">
                            {cards && cards.map(card => (
                                <div className="col-lg-4 mb-4" key={card._id}>
                                    <div className={`card ${card.isMain ? 'border-primary' : ''}`}>
                                        <img src={getAsset(card.image)} className="card-img-top" alt={card.title} />
                                        <div className="card-body">
                                            <h5 className="card-title">{card.title}</h5>
                                            <p className="card-text">{card.description}</p>
                                            <p style={{fontSize:'16px',fontWeight:'bold'}} >Link: <span style={{fontSize:'14px',fontWeight:'normal'}}>{card.link}</span></p>
                                            <button className="btn btn-warning mt-2" onClick={() => handleDelete(card._id)}>Delete</button>
                                            <button className="btn btn-secondary mt-2" onClick={() => handleEdit(card)}>Edit</button>
                                            {!card.isMain && (
                                                <button className="btn btn-info mt-2" onClick={() => handleSetMain(card)}>Set as Main</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {modalOpen && (
                <div className="modal show d-block" style={{overflowY:'scroll'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{currentCard ? "Edit Card" : "Add New Card"}</h5>
                                <button type="button" className="close" onClick={() => setModalOpen(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                    <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="link">Link</label>
                                    <input type="text" className="form-control" id="link" value={link} onChange={(e) => setLink(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>Image</label>
                                    <div {...getRootProps()} className="dropzone" style={{border:'1px dashed #7f00ff',borderRadius:'8px'}} >
                                        <input {...getInputProps()} />
                                        {preview ? (
                                            <img src={preview} alt="Preview" className="img-thumbnail" />
                                        ) : (
                                            <p>Drag 'n' drop an image, or click to select one</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DynamicModels;