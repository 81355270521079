import React, { Component, useEffect, useState, useRef } from "react";
import Switch from '@material-ui/core/Switch';
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";

const TradeNewArrival = () => {
    const editor = useRef(null);

    const currentPath = window.location.pathname;
    const [standardDeliveryCharges, setStandardDeliveryCharges] = useState('');
    const [FreeDeliveryCharges, setFreeDeliveryCharges] = useState('');
    const [expressDeliveryCharges, setExpressDeliveryCharges] = useState('');
    const [getdata, setgetdata] = useState({});


    const [ContentName, setContentName] = useState("");
    const [imagesFront, setImagesFront] = useState([]);
    const [image, setImages] = useState("");
    const [DataNumber, setDataNumber] = useState(0);
    const [selectedType, setSelectedType] = useState("");
    const [UniqueTypes, setUniqueTypes] = useState([]);
    const [responseImages, setResponseImages] = useState([]);
    const [NewArrivalOne, setNewArrivalOne] = useState([]);
    const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
    const [NewArrivalThree, setNewArrivalThree] = useState([]);
    const [NewArrivalFour, setNewArrivalFour] = useState([]);
    
    
  

    useEffect(() => {
        getAllDeliveryCharges();
        GetAllTypes();
        getAllData();
    }, [])

    const GetAllTypes = () => {
        axios
            .get(`${Env.server}/api/filters/gettypePermitedToShow`)
            .then((res) => {
                let resp = res.data.allType;
                // Extracting unique types
                // const types = Array.from(new Set(resp.map(item => item.typeName)));
                // console.log("types====", resp)
                const typesArray = resp.map((item) => item.type);

                console.log("typesArray====", typesArray);
                setUniqueTypes(typesArray);
            })
            .catch((err) => {
                console.log("err====", err);
            });
    };

const getAllData = () => {
    axios.post(`${Env.server}/api/tradenewarrival/getAll`)
        .then((res) => {
            console.log("Abresc===", res.data.data[0])
            setNewArrivalOne(res.data.data[0].One[0])
            setNewArrivalTwo(res.data.data[0].Two[0])
            setNewArrivalThree(res.data.data[0].Three[0])
            setNewArrivalFour(res.data.data[0].Four[0])
        })
        .catch((err) => {
            console.log("err====", err)
        })
}

    // Images======
    const handleInsideClick = (e) => {
        e.stopPropagation();
        const isClickable =
            e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
        if (isClickable) {
            if (e.target.tagName === "DIV") {
                document.getElementById("fileInput").click(); // Trigger the file input click event
            }
        }
    };
    const onDrop = (acceptedFiles) => {
        setImages(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
        setImagesFront(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    };
    const removeImage = (index) => {
        const updatedImagesfront = [...imagesFront];
        updatedImagesfront.splice(index, 1);
        setImagesFront(updatedImagesfront);

        const updatedImages = [...image];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop,
    });

    const publishImage = () => {
        const formData = new FormData();
        formData.append("imagefront", imagesFront);
        console.log("muzz1-====", imagesFront);
        console.log("muzz2-====", image);
        image.forEach((img, index) => {
            formData.append(`images`, img);
        });
        let objectNames = [];
        axios
            .post(`${Env.server}/api/uploadik/multiple`, formData)
            .then((res) => {
                // let resp = res.data.file;
                // for (let i = 0; i < resp.length; i++) {
                //     objectNames.push("/images/" + resp[i].filename);
                // }
                objectNames = res.data.urls;
                setResponseImages(objectNames);
                // if (res.data.message === "imagesSaved") {
                    submit(objectNames);
                // } else {
                //     alert("error not in catch");
                // }
            })
            .catch((err) => {
                console.log("err------ooo", err);
                alert("catch error");
            });
    };

    const submit = (imageName) => {
        console.log("abc=====>>", imageName)
        console.log("abc=====>>", selectedType)
        console.log("abc=====>>", ContentName)
       
        let Data = {
            DataNumber: DataNumber,
            type: selectedType,
            content: ContentName,
            image: imageName,  
        }
        axios.post(`${Env.server}/api/tradenewarrival/AddUpdate`, {Data : Data})
        .then((res)=>{
            console.log("Abresc===", res.data)
            setImagesFront([]);
            setImages([]);
            setSelectedType("");
            setContentName("");
            setResponseImages([]);
            GetAllTypes();
            getAllData();
        })
        .catch((err) => {
            console.log("err====", err)
        })

      

    }


    // Images

    const getAllDeliveryCharges = () => {
        axios.get(`${Env.server}/api/deliverycharges/getAll`)
            .then((res) => {
                console.log("res=====", res.data.data);
                // setgetdata(res.data.data)
                setStandardDeliveryCharges(res.data.data.standard)
                setExpressDeliveryCharges(res.data.data.express)
                setFreeDeliveryCharges(res.data.data.freelimit)
            })
            .catch((err) => {
                console.log("err====", err)
            })
    }

    const handleStandardDeliveryChargesChange = (event) => {
        setStandardDeliveryCharges(event.target.value);
    };
    const handleFreeDeliveryChargesChange = (event) => {
        setFreeDeliveryCharges(event.target.value);
    };

    const handleExpressDeliveryChargesChange = (event) => {
        setExpressDeliveryCharges(event.target.value);
    };

    const handleSubmit = () => {
        console.log("Standard Delivery Charges:", standardDeliveryCharges);
        console.log("Express Delivery Charges:", expressDeliveryCharges);
        console.log("Free Delivery Charges:", FreeDeliveryCharges);

        let data = {
            standard: standardDeliveryCharges,
            express: expressDeliveryCharges,
            freelimit: FreeDeliveryCharges
        }
        axios.post(`${Env.server}/api/deliverycharges/addnew`, data)
            .then((res) => {
                console.log("res=====", res.data)
            })
            .catch((err) => {
                console.log("err====", err)
            })

    };






   
    return (
        <>
            <div
                className={
                    currentPath.includes("signup") || currentPath.includes("signin")
                        ? ""
                        : "wrapper"
                }
            >
                {currentPath.includes("signup") ||
                    currentPath.includes("signin") ? null : (
                    <Sidebar />
                )}

                <div
                    className={
                        currentPath.includes("signup") || currentPath.includes("signin")
                            ? ""
                            : "page-wrapper"
                    }
                >
                    {currentPath.includes("signup") ||
                        currentPath.includes("signin") ? null : (
                        <Header />
                    )}

                   
 

                        <div className="container-fluid">
                            <div className="pb-3">
                                <h1 className="text-center mb-4 fs-2" style={{ fontFamily: "poppins", fontWeight: "bolder" }}>Trade NEW ARRIVALS</h1>
                            </div>
                            <div className="row justify-content-center align-items-center">
    <div className="col-lg-3" style={{ padding: '5px', minWidth: '300px' }}>
        <div className="card bg-dark text-white" data-toggle="modal"
            data-target="#exampleModalLong1" onClick={() => setDataNumber(1)} style={{ cursor: "pointer", minHeight: '400px', position: 'relative' }}>
            <img className="card-img" src={getAsset(NewArrivalOne && NewArrivalOne.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
            <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bold' }}>{NewArrivalOne.type}</p>
                <p className="card-text" style={{ margin: '0', marginTop: '10px' }}>{NewArrivalOne.content}</p>
            </div>
        </div>
    </div>
    <div className="col-lg-3" style={{ padding: '5px', minWidth: '300px' }}>
        <div className="card bg-dark text-white" data-toggle="modal"
            data-target="#exampleModalLong1" onClick={() => setDataNumber(2)} style={{ cursor: "pointer", minHeight: '400px', position: 'relative' }}>
            <img className="card-img" src={getAsset(NewArrivalTwo && NewArrivalTwo.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
            <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bold' }}>{NewArrivalTwo && NewArrivalTwo.type}</p>
                <p className="card-text " style={{ margin: '0', marginTop: '10px' }}>{NewArrivalTwo && NewArrivalTwo.content}</p>
            </div>
        </div>
    </div>
    <div className="col-lg-3" style={{ padding: '5px', minWidth: '300px' }}>
        <div className="card bg-dark text-white" data-toggle="modal"
            data-target="#exampleModalLong1" onClick={() => setDataNumber(3)} style={{ cursor: "pointer", minHeight: '400px', position: 'relative' }}>
            <img className="card-img" src={getAsset(NewArrivalThree && NewArrivalThree.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
            <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bold' }}>{NewArrivalThree && NewArrivalThree.type}</p>
                <p className="card-text" style={{ margin: '0', marginTop: '10px' }}>{NewArrivalThree && NewArrivalThree.content}</p>
            </div>
        </div>
    </div>
    <div className="col-lg-3" style={{ padding: '5px', minWidth: '300px' }}>
        <div className="card bg-dark text-white" data-toggle="modal"
            data-target="#exampleModalLong1" onClick={() => setDataNumber(4)} style={{ cursor: "pointer", minHeight: '400px', position: 'relative' }}>
            <img className="card-img" src={getAsset(NewArrivalFour && NewArrivalFour.image)} alt="Card image" style={{ objectFit: 'cover', height: '100%' }} />
            <div className="card-img-overlay" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <p className="card-text fs-6" style={{ margin: '0', fontWeight: 'bold' }}>{NewArrivalFour && NewArrivalFour.type}</p>
                <p className="card-text" style={{ margin: '0', marginTop: '10px' }}>{NewArrivalFour && NewArrivalFour.content}</p>
            </div>
        </div>
    </div>
</div>

                        </div>

  
                </div>
            </div>





            <div
                class="modal fade"
                id="exampleModalLong1"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Update {DataNumber}'s Data
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="form-group">
                                <label htmlFor="type" style={{ display: "block", textAlign: "left" }}>
                                    Type:
                                </label>
                                <select
                                    id="type"
                                    className="form-control"
                                    value={selectedType}
                                    onChange={(e) => {
                                        setSelectedType(e.target.value);
                                        console.log("Selected type:", e.target.value);
                                    }}
                                >
                                    <option value="">Select Type</option>
                                    {UniqueTypes.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </div>



                            <div className="form-group">
                                <label
                                    htmlFor="item"
                                    style={{ display: "block", textAlign: "left" }}
                                >
                                    Content:
                                </label>
                                <input
                                    type="text"
                                    id="item"
                                    className="form-control"
                                    value={ContentName}
                                    onChange={(e) =>
                                        setContentName(e.target.value)
                                    }
                                    placeholder="Type Brand Name"
                                />
                            </div>

                            <div
                                onClick={handleInsideClick}
                                style={{
                                    marginLeft: "20px",
                                    width: '100px',
                                    height: '100px',
                                    border: '1px dashed #aaa',
                                    borderRadius: '20px',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    backgroundColor: "black"
                                }}
                            >
                                <h5 style={{ color: "white", marginTop: "30px" }}>Choose Image</h5>
                                <input
                                    {...getInputProps()}
                                    id="fileInput" // Ensure this ID is present
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                                />

                            </div>
                            <br />
                            <center>
                                {" "}
                                <div className="container" spacing={1}>
                                    {imagesFront.map((file, index) => (
                                        <div item key={file.name}>
                                            <div>
                                                <img
                                                    src={file.preview}
                                                    alt={file.name}
                                                    width="300"
                                                    style={{display:'block'}}
                                                />
                                                <button 
                                                onClick={() => removeImage(index)}
                                                className={'btn btn-danger'}
                                                >Remove</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </center>
                        </div>

                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                data-dismiss="modal"
                                onClick={publishImage}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default TradeNewArrival;