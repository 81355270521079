import React, { useState } from 'react';
import { CiMenuKebab } from "react-icons/ci";
import './AdminCard.css';
import axios from 'axios';
import Env from '../../../../../Environment/Env';
function AdminCard({ admin, onAdminDel }) {
    async function delAdmin(id) {
        const confirm = window.confirm("Do you really want to delete this admin.")
        if (confirm) {
            try {
                const response = await axios.delete(`${Env.server}/api/admin/delete/${id}`)
                if (response) {
                    console.log("Deleting admin", response)
                    onAdminDel((prev) => !prev)
                }
            } catch (error) {
                console.log("Error in deleting", error)
            }
        }
    }
    return (
        <div className="cardContainer">
            <div className="">
                <p className='adminName'>{admin.username}</p>
            </div>
            <div className="cardMenu">
                <div className="dropdown">
                    <button className=" menuIcon  dropdown-toggle" type="button"  data-toggle="dropdown" >
                        {/* <CiMenuKebab /> */}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#"
                            onClick={() => delAdmin(admin._id)}
                        >Delete</a>
                        <a className="dropdown-item" href="#">Info</a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AdminCard;
