// import React, { Component, useEffect, useState, useRef } from "react";
// import Linechart from "./linechart";
// import Nextcharts from "./nextcharts";
// import Morecharts from "./morecharts";
// import Sidebar from "../main/sidebar";
// import Searchbar from "../main/navigation/searchbar";
// import Header from "../main/header";
// import axios from "axios";
// import Env from "../../Environment/Env";
// import { useDropzone } from "react-dropzone";
// import { getAsset } from "../../utils/helper";
// import JoditEditor from "jodit-react";

// const AllOrders = () => {
//     const editor = useRef(null);

//     const currentPath = window.location.pathname;
//     const [getAllProductRes, setgetAllProductRes] = useState([]);
//     const [usefectTrue, setUsefectTrue] = useState(false);

//     useEffect(() => {
//         getAllProduct();
//     }, []);

//     useEffect(() => {
//         getAllProduct();
//     }, [usefectTrue]);

//     const getAllProduct = () => {
//         axios
//             .get(`${Env.server}/api/order/getall`)
//             .then((res) => {

//                 console.log("abc===>>", res.data.orders)
//                 console.log("abc===>>", res.data.orders)
//                 let resp = res.data.orders;

//                 let orders = res.data.orders;
//                 let flattenedOrders = [];

//                 orders.forEach(order => {
//                     let customerName = order.customerName;
//                     let customerEmail = order.customerEmail;
//                     let orderNumber = order.orderNumber;

//                     order.productInfo.forEach(product => {
//                         let flattenedOrder = {
//                             customerName: customerName,
//                             customerEmail: customerEmail,
//                             orderNumber: orderNumber,
//                             product: product
//                         };

//                         flattenedOrders.push(flattenedOrder);
//                     });
//                 });

//                 console.log("flattenedOrders=====",flattenedOrders);
//                 setgetAllProductRes(flattenedOrders);
//             })
//             .catch((err) => {
//                 console.log("err====>>>", err);
//             });
//     };

//     return (
//         <>
//             <div
//                 className={
//                     currentPath.includes("signup") || currentPath.includes("signin")
//                         ? ""
//                         : "wrapper"
//                 }
//             >
//                 {currentPath.includes("signup") ||
//                     currentPath.includes("signin") ? null : (
//                     <Sidebar />
//                 )}

//                 <div
//                     className={
//                         currentPath.includes("signup") || currentPath.includes("signin")
//                             ? ""
//                             : "page-wrapper"
//                     }
//                 >
//                     {currentPath.includes("signup") ||
//                         currentPath.includes("signin") ? null : (
//                         <Header />
//                     )}

//                     <div className="content">
//                     <h2 className="m-3">All Orders</h2>
//                         <div className="row">
//                             <div className="col-12">
//                                 <div className="card card-default">
//                                     <div className="container">
//                                         <div className="row mt-4">
//                                             <div className="col-md-2">
//                                                 <h5 className="card-title">Image</h5>
//                                             </div>
//                                             <div className="col-md-1">
//                                                 <h5 className="card-title">Order No.</h5>
//                                             </div>
//                                             <div className="col-md-2">
//                                                 <h5 className="card-title">Prod Name</h5>
//                                             </div>
//                                             <div className="col-md-1">
//                                                 <h5 className="card-title">Price</h5>
//                                             </div>
//                                             <div className="col-md-1">
//                                                 <h5 className="card-title">Quan</h5>
//                                             </div>
//                                             <div className="col-md-2">
//                                                 <h5 className="card-title">Cust. Name</h5>
//                                             </div>
//                                             <div className="col-md-2">
//                                                 <h5 className="card-title">Cust. Email</h5>
//                                             </div>
//                                             <div className="col-md-1">
//                                                 <h5 className="card-title"></h5>
//                                             </div>

//                                         </div>

//                                         {getAllProductRes.slice().reverse().map((order, index) => (
//                                             <div className="row" key={index}>
//                                                 <div className="col-md-12">
//                                                     <div className="card mb-3" >
//                                                         <div className="row no-gutters">

//                                                             <div className="col-md-2">
//                                                                 <img src={getAsset(order.product.imageSubsubCatagory[0])} style={{height:"100px" , width:"auto" , margin:"7px"}} alt="Product" className="img-fluid" />
//                                                             </div>
//                                                             <div className="col-md-1">
//                                                                 <p>{order.orderNumber}</p>
//                                                             </div>
//                                                             <div className="col-md-2">
//                                                                 <p>{order.product.subsubcatagory}</p>
//                                                             </div>
//                                                             <div className="col-md-1">
//                                                                 <p>{order.product.productPrice}</p>
//                                                             </div>
//                                                             <div className="col-md-1">
//                                                                 <p>{order.product.quantity}</p>
//                                                             </div>
//                                                             <div className="col-md-2">
//                                                                 <p>{order.customerName}</p>
//                                                             </div>
//                                                             <div className="col-md-2">
//                                                                 <p>{order.customerEmail}</p>
//                                                             </div>
//                                                             <div className="col-md-1">
//                                                                 <button className="btn btn-primary " style={{height:"35px" , width:"50px"}} data-toggle="modal" data-target="#OrderShowModalOpen" >view</button>
//                                                             </div>

//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="modal fade" id="OrderShowModalOpen" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ zIndex: '19990' }}>
//                 <div className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-right" role="document">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="exampleModalLongTitle">
//                                 Alert!
//                             </h5>
//                             <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                                 <span aria-hidden="true">&times;</span>
//                             </button>
//                         </div>
//                         <div className="modal-body">

//                             <div className="container">
//                                 <div className="row mt-4">

//                                     <div className="col-md-6 border-right border-secondary">
//                                         <h5 className="card-title">Data Values</h5>
//                                         <p>Image Data</p>
//                                         <hr />
//                                         <p>Order No. Data</p>
//                                         <hr />
//                                         <p>Product Name Data</p>
//                                         <hr />
//                                         <p>Price Data</p>
//                                         <hr />
//                                         <p>Quantity Data</p>
//                                         <hr />
//                                         <p>Customer Name Data</p>
//                                         <hr />
//                                         <p>Customer Email Data</p>
//                                         <hr />
//                                         <p></p>
//                                     </div>

//                                     <div className="col-md-6">
//                                         <div className="row">
//                                             <div className="col-md-12">
//                                                 <h5 className="card-title">Name</h5>
//                                             </div>
//                                             <div className="col-md-12">
//                                                 <img src="https://ik.imagekit.io/cy8phtesy/FONECARE%20Img.jpg?updatedAt=1712421427035" style={{}} alt="Product" className="img-fluid" ></img>
//                                                 <hr />
//                                                 <p>Order No.</p>
//                                                 <hr />
//                                                 <p>Prod Name</p>
//                                                 <hr />
//                                                 <p>Price</p>
//                                                 <hr />
//                                                 <p>Quan</p>
//                                                 <hr />
//                                                 <p>Cust. Name</p>
//                                                 <hr />
//                                                 <p>Cust. Email</p>
//                                                 <hr />
//                                                 <p></p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default AllOrders;

import React, { Component, useEffect, useState, useRef } from "react";
import Linechart from "./linechart";
import Nextcharts from "./nextcharts";
import Morecharts from "./morecharts";
import Sidebar from "../main/sidebar";
import Searchbar from "../main/navigation/searchbar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";
import JoditEditor from "jodit-react";

const AllOrders = () => {
  const [getAllProductRes, setgetAllProductRes] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    getAllProduct();
  }, []);

  const getAllProduct = () => {
    axios
      .get(`${Env.server}/api/order/getall`)
      .then((res) => {
        let orders = res.data.orders;
        let flattenedOrders = [];

        // orders.forEach((order) => {
        //     let customerName = order.customerName;
        //     let customerEmail = order.customerEmail;
        //     let orderNumber = order.orderNumber;
        //     let productInfo = order.productInfo;
        //     let orderId = order._id;

        //     order.productInfo.forEach((product) => {
        //         let flattenedOrder = {
        //             customerName: customerName,
        //             customerEmail: customerEmail,
        //             orderNumber: orderNumber,
        //             orderId: orderId,
        //             product: product,
        //         };

        //         flattenedOrders.push(flattenedOrder);
        //     });
        // });
        console.log("orders====", orders);
        setSelectedOption1(orders.readyStatus);

        setgetAllProductRes(orders);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    console.log("Order", order);
  };

  const currentPath = window.location.pathname;

  // CODE OF THE THIRD ROW OF DROPDOWN SELECTION AND SUBMIT BUTTON
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [submittedOption, setSubmittedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    // setSubmittedOption(selectedOption);
    console.log("selectedOption", selectedOption);
    console.log("selectedOrder", selectedOrder.orderNumber);

    axios.patch(`${Env.server}/api/order/updateStatus/${selectedOrder.orderNumber}`, { readyStatus: selectedOption })
    .then((res) => {
        console.log("res", res)
        getAllProduct();
    })
    .catch((err) => {
        console.log("err", err)
    })
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}
        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}
          <div className="content">
            <h2 className="m-3">All Orders</h2>
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-2">
                  <h6 className="card-title">Sr.</h6>
                </div>
                <div className="col-md-1">
                  <h6 className="card-title">Order No.</h6>
                </div>
                <div className="col-md-2">
                  <h6 className="card-title">Payment</h6>
                </div>
                <div className="col-md-1">
                  <h6 className="card-title">Price</h6>
                </div>
                <div className="col-md-1">
                  <h6 className="card-title">Quan</h6>
                </div>
                <div className="col-md-2">
                  <h6 className="card-title">Cust. Name</h6>
                </div>
                <div className="col-md-2">
                  <h6 className="card-title">Cust. Email</h6>
                </div>
                <div className="col-md-1">
                  <h6 className="card-title"></h6>
                </div>
              </div>
              {getAllProductRes.map((order, index) => (
                <div className="row " key={index}>
                  <div className="col-md-12">
                    <div className="card mb-3 ">
                      <div className="row no-gutters">
                        <div className="col-md-2">
                          <p>{index + 1}</p>
                        </div>
                        <div className="col-md-1">
                          <p>{order.orderNumber}</p>
                        </div>
                        <div className="col-md-2">
                          <p>{order.paymentType}</p>
                        </div>
                        <div className="col-md-1">
                          <p>
                            {" "}
                            ₤{" "}
                            {order.productInfo.reduce(
                              (sum, product) => sum + product.productPrice,
                              0
                            )}
                          </p>
                        </div>
                        <div className="col-md-1">
                          <p>{order.productInfo.length}</p>
                        </div>
                        <div className="col-md-2">
                          <p>{order.customerName}</p>
                        </div>
                        <div className="col-md-2">
                          <p>{order.customerEmail}</p>
                        </div>
                        <div className="col-md-1">
                          <button
                            className="btn btn-primary "
                            style={{ height: "35px", width: "50px" }}
                            data-toggle="modal"
                            data-target="#OrderShowModalOpen"
                            onClick={() => {
                              handleViewOrder(order);
                              console.log(
                                "This is Order to show Modal:    ",
                                order
                              );
                            }}
                          >
                            view
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="OrderShowModalOpen"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        style={{ zIndex: "19990" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-right"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Order Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row mt-4">
                  {selectedOrder &&
                    selectedOrder.productInfo.map((product, index) => (
                      <>
                        <div className="col-12" key={index}>
                          <div className="d-flex justify-content-between">
                            <p>SKU: </p>
                            <p>{product.SKU}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Subsubcategory: </p>
                            <p>{product.subsubcatagory}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Product Price: </p>
                            <p>{product.productPrice}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Quantity: </p>
                            <p>{product.quantity}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Total Price: </p>
                            <p>{product.productPrice * product.quantity}</p>
                          </div>
                          <hr />
                        </div>
                      </>
                    ))}
                  <div className="col-12 dropdown-container mt-5">
                    <div className="dropdown">
                      <select
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <option value="">Select delivery option</option>
                        <option value="standard">Standard Delivery</option>
                        <option value="express">Express Delivery</option>
                        <option value="next-day">Next Day Delivery</option>
                        <option value="same-day">Same Day Delivery</option>
                      </select>
                      {selectedOption && (
                        <div className="selected-option">
                          Selected: {selectedOption}
                        </div>
                      )}


                      <h3>{selectedOption1? selectedOption1 : "Select delivery option"}</h3>

                    </div>
                    <button
                      className="submit-button btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {/* {submittedOption && (
        <div className="submitted-option">
          Submitted: {submittedOption}
        </div>
      )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllOrders;