import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Env from '../../../../../Environment/Env';
function AddAdmin({ showModal, setShowModal,onAddAdmin }) {
    const { register, handleSubmit } = useForm()
    const [error,setError]=useState('')
    const handleCloseModal = () => setShowModal(false);
    async function addNewAdmin(data) {
        setError('');
        try {
            const response = await axios.post(`${Env.server}/api/admin/create`,data)
            console.log("Admin creating ", response)
            if(response){
                onAddAdmin((prev)=>!prev)
                setShowModal(false)
            }
        } catch (error) {
            console.log("Error in creating admin", error)
            setError(error.response.data.message)
        }
    }
    return (
        <div className="App">
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(addNewAdmin)}
                        className="" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                        <p style={{color:'red'}}>{error ? error :''}</p>
                        <label htmlFor="username" style={{ display: 'block', marginBottom: '8px' }}>Username</label>
                        <input
                            type="text"
                            id="username"
                            placeholder="Enter username"
                            {...register('username', {
                                required: true
                            })}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                marginBottom: '16px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        />

                        <label htmlFor="email" style={{ display: 'block', marginBottom: '8px' }}>Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter email"
                            {...register('email', {
                                required: "Email is required",
                                validate: {
                                    matchPattern: (value) =>
                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                                        "Email address must be a valid address",
                                },
                            })}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                marginBottom: '16px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        />

                        <label htmlFor="password" style={{ display: 'block', marginBottom: '8px' }}>Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter password"
                            {...register('password', {
                                required: true
                            })}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                marginBottom: '16px',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        />
                        <Button type='submit' variant="primary"  >
                            Add
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddAdmin;
