import Env from "../Environment/Env";

export const getAsset = (filename) => {
  if(Array.isArray(filename)){
    filename = filename[0];
  }
  if(filename && filename.includes('http')){
    return filename;
  }
  return Env.server + "/assets" + filename;
};