import React, { Component, useEffect, useState, useRef } from "react";
import Switch from '@material-ui/core/Switch';
import Sidebar from "../main/sidebar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";

const BrandInfo = () => {
// FOR LOGO
    const [image, setImage] = useState(null);

    const handleImageUpload = (e) => {
      const uploadedImage = e.target.files[0];
      setImage(URL.createObjectURL(uploadedImage));
    };

// FOR FAVICON
    const [favImage, setfavImage] = useState(null);

    const handlefavImageUpload = (e) => {
      const uploadedfavImage = e.target.files[0];
      setImage(URL.createObjectURL(uploadedfavImage));
    };

// FOR SOCIAL MEDIA ICONS
const [socialMediaFields, setSocialMediaFields] = useState([{ id: 1, value: '' }]);

const handleAddField = () => {
    const newId = socialMediaFields.length + 1;
    setSocialMediaFields([...socialMediaFields, { id: newId, value: '' }]);
};

const handleRemoveField = (idToRemove) => {
    const updatedFields = socialMediaFields.filter(field => field.id !== idToRemove);
    setSocialMediaFields(updatedFields);
};

const handleInputChange = (id, value) => {
    const updatedFields = socialMediaFields.map(field =>
        field.id === id ? { ...field, value: value } : field
    );
    setSocialMediaFields(updatedFields);
};






    const editor = useRef(null);

    const currentPath = window.location.pathname;
    const [standardDeliveryCharges, setStandardDeliveryCharges] = useState('');
    const [FreeDeliveryCharges, setFreeDeliveryCharges] = useState('');
    const [expressDeliveryCharges, setExpressDeliveryCharges] = useState('');
    const [getdata, setgetdata] = useState({});

useEffect(()=>{
  getAllDeliveryCharges();
},[])

const getAllDeliveryCharges=()=>{
    axios.get(`${Env.server}/api/deliverycharges/getAll`)
        .then((res) => {
            console.log("res=====", res.data.data);
            // setgetdata(res.data.data)
            setStandardDeliveryCharges(res.data.data.standard)
            setExpressDeliveryCharges(res.data.data.express)
            setFreeDeliveryCharges(res.data.data.freelimit)
        })
        .catch((err) => {
            console.log("err====", err)
        })
}

    const handleStandardDeliveryChargesChange = (event) => {
        setStandardDeliveryCharges(event.target.value);
    };
    const handleFreeDeliveryChargesChange = (event) => {
        setFreeDeliveryCharges(event.target.value);
    };

    const handleExpressDeliveryChargesChange = (event) => {
        setExpressDeliveryCharges(event.target.value);
    };

    const handleSubmit = () => {
        console.log("Standard Delivery Charges:", standardDeliveryCharges);
        console.log("Express Delivery Charges:", expressDeliveryCharges);
        console.log("Free Delivery Charges:", FreeDeliveryCharges);
        
        let data = {
            standard: standardDeliveryCharges,
            express: expressDeliveryCharges,
            freelimit: FreeDeliveryCharges
        }
        axios.post(`${Env.server}/api/deliverycharges/addnew`, data)
        .then((res) => {
            console.log("res=====", res.data)
        })
        .catch((err) => {
            console.log("err====", err)
        })

    };

    return (
        <>
            <div
                className={
                    currentPath.includes("signup") || currentPath.includes("signin")
                        ? ""
                        : "wrapper"
                }
            >
                {currentPath.includes("signup") ||
                    currentPath.includes("signin") ? null : (
                    <Sidebar />
                )}

                <div
                    className={
                        currentPath.includes("signup") || currentPath.includes("signin")
                            ? ""
                            : "page-wrapper"
                    }
                >
                    {currentPath.includes("signup") ||
                        currentPath.includes("signin") ? null : (
                        <Header />
                    )}

                    <div className="container" style={{ marginTop: "40px", marginBottom: "40px" }}>
                       {/* ADD YOUR LOGO */}
                        <div className="row">
                          
                            <div className="col-md-2"></div>
                            <div className="col">
                            <div style={{ display: 'flex', flexDirection: 'column' , alignItems: 'center' }}>
      <div style={{}}>
        {/* Your logo component goes here */}
        <h5>Add Your Logo</h5>
      </div>
      <div style={{ }}>
        <label htmlFor="image-upload-input">
          {image ? (
            <img src={image} alt="Uploaded" style={{ width: '100%', borderRadius: '50%' }} />
          ) : (
            <div
              style={{
                width: '100px',
                height: '100px',
                border: '2px dashed #ccc',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgray'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'initial'}
            >
              Upload Logo
            </div>
          )}
        </label>
        <input
          id="image-upload-input"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
      </div>
    </div>
                            </div>
                            <div className="col">
                            <div style={{ display: 'flex', flexDirection: 'column' , alignItems: 'center' }}>
      <div style={{}}>
        {/* Your logo component goes here */}
        <h5>Add Favicon</h5>
      </div>
      <div style={{ }}>
        <label htmlFor="image-upload-input">
          {image ? (
            <img src={image} alt="Uploaded" style={{ width: '100%', borderRadius: '50%' }} />
          ) : (
            <div
              style={{
                width: '100px',
                height: '100px',
                border: '2px dashed #ccc',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgray'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'initial'}
            >
              Upload Fav..
            </div>
          )}
        </label>
        <input
          id="image-upload-input"
          type="file"
          accept="image/*"
          onChange={handlefavImageUpload}
          style={{ display: 'none' }}
        />
      </div>
    </div>
                            </div>
                            <div className="col-md-2"></div>    

                        </div>
                        <br/><br/>
                       
                        





                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label htmlFor="Title">Title</label>
                                <input
                                    type="text"
                                    id="Title"
                                    className="form-control"
                                    // value={FreeDeliveryCharges}
                                    // onChange={handleFreeDeliveryChargesChange}
                                    placeholder="Enter Your Title"
                                />
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="Address">Address</label>
                                <input
                                    type="text"
                                    id="Address"
                                    className="form-control"
                                    // value={FreeDeliveryCharges}
                                    // onChange={handleFreeDeliveryChargesChange}
                                    placeholder="Enter Your Address"
                                />
                            </div>
                            <div className="col-md-1"></div>

                        </div>

<div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label htmlFor="PhoneNumber">Phone Number</label>
                                <input
                                    type="Number"
                                    id="PhoneNumber"
                                    className="form-control"
                                    // value={FreeDeliveryCharges}
                                    // onChange={handleFreeDeliveryChargesChange}
                                    placeholder="Enter Your Phone Number"
                                />
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="Postal Code">Postal Code</label>
                                <input
                                    type="text"
                                    id="Postal Code"
                                    className="form-control"
                                    // value={FreeDeliveryCharges}
                                    // onChange={handleFreeDeliveryChargesChange}
                                    placeholder="Enter Your Postal Code"
                                />
                            </div>
                            <div className="col-md-1"></div>

                        </div>

                        <div className="mt-4">
            {socialMediaFields.map(field => (
                <div key={field.id} className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                    <label htmlFor="SocialMedia">Social Media</label>
                    <div className="d-flex">
                       
                        <input
                            type="text"
                            id={`SocialMedia${field.id}`}
                            className="form-control"
                            value={field.value}
                            onChange={(e) => handleInputChange(field.id, e.target.value)}
                            placeholder="Enter Social Media"
                        />
                        {field.id !== 1 && (
                            <button
                                className="btn btn-primary ml-2"
                                onClick={() => handleRemoveField(field.id)}
                            >
                                -
                            </button>
                        )}
                        {field.id === socialMediaFields.length && (
                            <button className="btn btn-primary ml-2" onClick={handleAddField}>
                                +
                            </button>
                        )}
                    </div>
                    </div>
                    
                </div>
            ))}
        </div>

                        
                    </div>
                </div>
            </div>


        </>
    );
};

export default BrandInfo;