import React from 'react';

const timeline = (props) => {

    return (

        <div className="tab-pane active show" id="profile#rofile" role="tabpanel" aria-labelledby="profile-tab"><p>Timeline section</p></div>

    );
}

export default timeline;