import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Profile extends Component {
    constructor() {
        super();
        this.state = {
            'showProfile': false
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleOutsideClick(e) {

        if (this.node) {

            if (this.node.contains(e.target)) {
                this.handleClick();
                return;
            }

        }
        this.handleClick();
    }

    handleClick() {

        if (!this.state.showProfile) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            showProfile: !prevState.showProfile
        }));
    }
    render() {

        return (
            <div className="dropdown user-menu show" ref={node => { this.node = node; }}>
                <button className="dropdown-toggle nav-link" onClick={this.handleClick}>
                    <span className="d-none d-lg-inline-block">Information</span>
                </button>
                <ul className={this.state.showProfile ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
                    <li>
                        <Link to="/profile">
                            <i className="mdi mdi-account"></i> My Profile
                        </Link>
                    </li>
                    <li>
                        <a href="email-inbox.html">
                            <i className="mdi mdi-email"></i> Message
                        </a>
                    </li>
                    <li>
                        <a href="#"> <i className="mdi mdi-diamond-stone"></i> Projects </a>
                    </li>
                    <li>
                        <Link to="/RequestChangePassword">
                            <i className="mdi mdi-settings"></i> Account Setting
                        </Link>
                    </li>
                    <li>
                        {
                            localStorage.getItem('isPrimary') ==="true" && (
                                <Link to="/adminSetting">
                                    <i className="mdi mdi-settings"></i> Admin's Setting
                                </Link>
                            )
                        }
                    </li>
                    <li 
                        onClick={() => {
                            localStorage.setItem("isUser", false)
                            localStorage.setItem("isPrimary", false)

                        }}
                    >
                        <Link to="/">
                        <i className="mdi mdi-logout"></i> Log Out 
                        </Link>
                        
                    </li>
                   
                </ul>
            </div >
        );
    }
}



export default Profile;