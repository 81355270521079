import React, { useEffect, useState } from 'react'
import Header from '../../main/header'
import Sidebar from '../../main/sidebar'
import { useNavigate } from 'react-router-dom'
import AdminSettingInner from './adminSettingInner/AdminSettingInner'
import './AdminsSetting.css'

function AdminsSetting() {
    const navigate = useNavigate()
    const [option, setOption] = useState('')
    useEffect(() => {
        if (localStorage.getItem("isPrimary") === "false") {
            if (localStorage.getItem("isUser") === "true") {
                navigate('/dashboard')
            }
            else {
                navigate('/')
            }
        }
    }, [])

    return (
        <div>
            <div className="wrapper">
                <Sidebar />

                <div className="page-wrapper">
                    <Header />
                    <div>
                        <nav style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            backgroundColor: 'Black',
                            padding: '18px',
                            fontSize: '25px',
                            gap:'28px',
                        }}>
                            <p className='option' onClick={(e) =>setOption(e.target.innerText)} style={{
                                fontSize: '25px',
                            }}>
                                Admins
                            </p>
                            <p className='option' onClick={(e) =>setOption(e.target.innerText)} style={{
                                fontSize: '25px',
                            }}>Add more</p>
                            <p className='option' onClick={(e) =>setOption(e.target.innerText)} style={{
                                fontSize: '25px',
                            }}>Add more</p>
                            <p className='option' onClick={(e) =>setOption(e.target.innerText)} style={{
                                fontSize: '25px',
                            }}>Add more</p>
                            <p className='option' onClick={(e) =>setOption(e.target.innerText)} style={{
                                fontSize: '25px',
                            }}>Add more</p>
                        </nav>
                    </div>
                    <div className="">
                        <AdminSettingInner
                            options={option}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminsSetting
