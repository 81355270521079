import React, { useState, useEffect } from 'react';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';

function DynamicHotSell() {
    const currentPath = window.location.pathname;
    const [cards, setCards] = useState([]);
    const [currentCard, setCurrentCard] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [description, setDescription] = useState('');
    const [tagLine, setTagLine] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        fetchCards();
    }, []);

    const fetchCards = async () => {
        try {
            const res = await axios.get(`${Env.server}/api/hotselling/getAll`);
            setCards(res.data);
        } catch (err) {
            console.error("Error fetching cards", err);
        }
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImage(file);
        setPreview(URL.createObjectURL(file));
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: handleDrop
    });

    const handleSave = async () => {
        try {
            let imageUrl = preview;    
            // If a new image is uploaded, handle the image upload
            if (image) {
                const formData = new FormData();
                formData.append('image', image);
    
                try {
                    const uploadResponse = await axios.post(`${Env.server}/api/uploadik/single`, formData);
                    // imageUrl = "/images/" + uploadResponse.data.filename;
                    imageUrl = uploadResponse.data.url;
                } catch (uploadError) {
                    console.error("Error uploading image", uploadError);
                    return;
                }
            }
    
            const payload = { image: imageUrl, title, link, description, tagLine, isActive };
    
            if (currentCard) {
                // Update existing card
                try {
                    await axios.put(`${Env.server}/api/hotselling/update/${currentCard._id}`, payload);
                } catch (updateError) {
                    console.error("Error updating card", updateError);
                    return;
                }
            } else {
                // Create new card
                try {
                    await axios.post(`${Env.server}/api/hotselling/create`, payload);
                } catch (createError) {
                    console.error("Error creating card", createError);
                    return;
                }
            }
    
            // Close the modal, fetch cards and clear the form
            setModalOpen(false);
            fetchCards();
            clearForm();
        } catch (err) {
            console.error("Error saving card", err);
        }
    };
    

    // const handleSave = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('image', image);
    //         let imageUrl = "";
    //         axios
    //             .post(`${Env.server}/api/upload/single`, formData)
    //             .then(async (res) => {
    //                 imageUrl = "/images/" + res.data.filename;
    //                 if (currentCard) {
    //                     await axios.put(`${Env.server}/api/hotselling/update/${currentCard._id}`, {
    //                         image: imageUrl, title, link, description, tagLine, isActive
    //                     });
    //                 } else {
    //                     await axios.post(`${Env.server}/api/hotselling/create`, {
    //                         image: imageUrl, title, link, description, tagLine, isActive
    //                     });
    //                 }
    //                 setModalOpen(false);
    //                 fetchCards();
    //                 clearForm();
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             });
    //     } catch (err) {
    //         console.error("Error saving card", err);
    //     }
    // };

    const handleEdit = (card) => {
        setCurrentCard(card);
        setTitle(card.title);
        setLink(card.link);
        setDescription(card.description);
        setTagLine(card.tagLine);
        setIsActive(card.isActive);
        setPreview(getAsset(card.image));
        setModalOpen(true);
    };

    const handleAdd = () => {
        clearForm();
        setModalOpen(true);
    };

    const handleDelete = async(id)=>{
        await axios.delete(`${Env.server}/api/hotselling/${id}`)
        .then((res)=>{
            console.log(res.data.message);
            fetchCards();
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const clearForm = () => {
        setCurrentCard(null);
        setTitle('');
        setLink('');
        setDescription('');
        setTagLine('');
        setIsActive(false);
        setImage(null);
        setPreview(null);
    };

    const handleToggleActive = async (card) => {
        try {
            await axios.put(`${Env.server}/api/hotselling/update/${card._id}`, {
                ...card,
                isActive: !card.isActive
            });
            fetchCards();
        } catch (err) {
            console.error("Error toggling active status", err);
        }
    };

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflowY = modalOpen ? 'hidden' : 'auto';
    }, [modalOpen]);

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}

                    <div className="container-fluid">
                        <div className="pb-3">
                            <h1 className="text-center mb-4 fs-2" style={{ fontFamily: "poppins", fontWeight: "bolder" }}>Hot Selling Dynamic Setting</h1>
                        </div>
                        <button className="btn btn-primary mb-4" onClick={handleAdd}>Add New Card</button>
                        <div className="row">
                        {cards && cards.map(card => (
                            <div className="col-lg-4 mb-4" key={card._id}>
                                <div className="card">
                                    <img src={getAsset(card.image)} style={{maxWidth:'200px',display:'block',margin:'2px auto'}} className="card-img-top" alt={card.title} />
                                    <div className="card-body">
                                        <h5 className="card-title">{card.title}</h5>
                                        <p className="card-text">
                                            <strong>Link:</strong> {card.link}<br />
                                            <strong>Description:</strong> {card.description}<br />
                                            <strong>Tagline:</strong> {card.tagLine}<br />
                                            <strong>Active:</strong> {card.isActive ? "Yes" : "No"}
                                        </p>
                                        <button className="btn btn-danger" onClick={()=>handleDelete(card._id)}>Delete</button>
                                        <button className="btn btn-secondary mt-2" onClick={() => handleEdit(card)}>Edit</button>
                                        <button className="btn btn-info mt-2" onClick={() => handleToggleActive(card)}>
                                            {card.isActive ? "Deactivate" : "Activate"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>

                    {modalOpen && (
                        <div className="modal show d-block" style={{ overflowY: 'scroll' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{currentCard ? "Edit Hot Sell" : "Add New Hot Sell"}</h5>
                                        <button type="button" className="close" onClick={() => setModalOpen(false)}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input type="text" className="form-control" id="title" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="link">Link</label>
                                            <input type="text" className="form-control" id="link" placeholder="Enter link" value={link} onChange={(e) => setLink(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Description</label>
                                            <textarea className="form-control" id="description" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="tagLine">Tagline</label>
                                            <input type="text" className="form-control" id="tagLine" placeholder="Enter tagline" value={tagLine} onChange={(e) => setTagLine(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="isActive">Active</label>
                                            <input type="checkbox" id="isActive" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            <div {...getRootProps()} className="dropzone">
                                                <input {...getInputProps()} />
                                                {preview ? (
                                                    <img src={preview} alt="Preview" className="img-thumbnail" />
                                                ) : (
                                                    <p>Drag 'n' drop an image, or click to select one</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DynamicHotSell;
