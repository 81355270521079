import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import Sidebar from '../main/sidebar';
import Header from '../main/header'; 
import Env from '../../Environment/Env';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

function Subscribers() {
    const currentPath = window.location.pathname;
    const [subscribers, setSubscribers] = useState([]);

    const fetchAllEmails = async () => {
        try {
            const response = await axios.get(`${Env.server}/api/subscribe/getall`);
            setSubscribers(response.data.subscribers);
            console.log('All Subscribers are::', response.data.subscribers);
        } catch (error) {
            console.log('Error loading Subscribers', error);
        }
    };

    const handleDelete = async(id) =>{
        const conf  = window.confirm('Do you really want to delete this subscriber')
        console.log('aaaaaaaaaaaaa',conf);
        if(!conf){
            return;
        }
        try{
            await axios.delete(`${Env.server}/api/subscribe/delete/${id}`)
            .then((res)=>{
                console.log('Deleted Successfully');
                fetchAllEmails();
            })
        }catch(error){
            console.log('Error deleting Subscriber');
        }
    }

    useEffect(() => {
        fetchAllEmails();
    }, []);

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}
                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}
                    <div className="content">
                        <Container className='my-4'>
                            <Row>
                                <Col>
                                    <div className='seq-box-container'>
                                        <div className='header-seq'>
                                            <div className="">
                                                <h2 className="text-center noselect">Subscribers</h2>
                                            </div>
                                        </div>
                                        <ListGroup variant="flush">
                                            {subscribers.map((subscriber, index) => (
                                                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                                    <span>{subscriber.email}</span>
                                                    <span className="text-muted">{new Date(subscriber.createdAt).toLocaleDateString()}</span>
                                                    <span onClick={()=>handleDelete(subscriber._id)} style={{color:'red',cursor:'pointer',fonetSize:'22px'}} >
                                                        <IconButton aria-label="delete">
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Subscribers;
