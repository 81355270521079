import React from 'react'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import Env from '../../../../Environment/Env'
import './AdminLogin.css'
import { useNavigate } from 'react-router-dom'

function AdminLogin() {
    const [errors, setErrors] = useState('')
    const { register, handleSubmit } = useForm()
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("isUser") === "true") {
            navigate('/dashboard')
        }
    }, [])
    async function login(data) {
        setErrors('')
        setLoader(true)
        try {
            const response = await axios.post(`${Env.server}/api/admin/login`, data);
            if (response) {
                setLoader(false)
                console.log(response)
                localStorage.setItem("isUser", true)
                localStorage.setItem("isPrimary", response.data.data.isPrimary)
                navigate('/dashboard')
            }
        } catch (error) {
            setLoader(false)
            setErrors(error.response.data.message);
            console.error('Error logging in:', error);
        }
    };
    return (
        <>
            <div className="login-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <p style={{ fontSize: '26px', fontWeight: 'bold' }}>Please login to access the dashboard.</p>
                </div>
                <div className="login-box">
                    <h2>Admin Login</h2>
                    <p style={{ color: 'red', marginBottom: '10px' }}>{errors ? errors : ''}</p>
                    <form className="login-form" onSubmit={handleSubmit(login)}>
                        <div className="form-group">
                            <label htmlFor="email">Admin Email</label>
                            <input
                                className="input"
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                {...register('email', {
                                    required: "Email is required",
                                    validate: {
                                        matchPattern: (value) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                                            "Email address must be a valid address",
                                    },
                                })}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                className="input"
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                {...register('password', {
                                    required: "Password is required",
                                })}
                            />
                        </div>
                        <button className="button" type="submit">
                            {
                                loader ? "Logging " : 'Log In'
                            }
                        </button>
                    </form>
                </div>
            </div>

        </>
    )
}
export default AdminLogin
