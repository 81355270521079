import React, { Component } from 'react';
import Sidebar from "./sidebar";
import Header from './header';
import { Route, BrowserRouter as Router,Routes } from 'react-router-dom'
import Dashboard from '../dashboard/dashboard';
import Profile from '../pages/profile';
import RequestChangePassword from './navigation/RequestChangePassword';
import Signin from '../pages/auth/signin';
import Invoice from '../pages/others/invoice';
import Errors from '../pages/others/errors';
import Sellerproducts from '../charts/Sellerproducts';
import AddProducts from '../charts/AddProducts';
import AddCatagory from '../charts/AddCatagory';
import AddSubCatagory from '../charts/AddSubCtagory';
import AddSSCatagory from '../charts/AddSSCatagory';
import AllOrders from '../charts/AllOrders';
import NavBarSetting from '../charts/NavBarSetting';
import BrandInfo from '../charts/BrandInfo';
import DeliveryCharges from '../charts/DeliveryCharges';
import NewArrival from '../charts/NewArrival';
import VideosSection from '../charts/VideosSection';
import SignUpApproval from '../charts/SignUpApproval';
import ProductSequence from '../charts/ProductSequence';
import Subscribers from '../charts/Subscribers';
import ModelSequence from '../charts/ModelSequence';
import BrandSequence from '../charts/BrandSequence';
import DynamicModels from '../charts/DynamicModels';
import DynamicLTOs from '../charts/DynamicLTOs';
import DynamicHotSell from '../charts/DynamicHotSell';
import TypesSequence from '../charts/TypesSequence';
import DynamicFooter from '../charts/DynamicFooter';
import AdminLogin from '../pages/auth/adminLogin/AdminLogin';
import AdminsSetting from '../pages/adminSetting/AdminsSetting';



function Main() {
    return(
        <Router>
            <Routes>
                <Route path='/' element={<AdminLogin />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/RequestChangePassword' element={<RequestChangePassword />} />
                <Route path='/signin' element={<Signin />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/error" element={<Errors />} />
                <Route path="/Sellerproducts" element={<Sellerproducts />} />
                <Route path="/AddProducts" element={<AddProducts />} />
                <Route path="/AllOrders" element={<AllOrders />} />
                <Route path="/NavBarSetting" element={<NavBarSetting />} />
                <Route path="/BrandInfo" element={<BrandInfo />} />
                <Route path="/deliveryCharges" element={<DeliveryCharges />} />
                <Route path="/newarrivals" element={<NewArrival />} />
                <Route path="/AddCatagory" element={<AddCatagory />} />
                <Route path="/AddSubCatagory" element={<AddSubCatagory />} />
                <Route path="/AddSubSubCatagory" element={<AddSSCatagory />} />
                <Route path="/VideosSection" element={<VideosSection />} />
                <Route path="/SignupApproval" element={<SignUpApproval />} />
                <Route path="/AccessorySequence" element={<ProductSequence />} />
                <Route path="/ModelSequence" element={<ModelSequence />} />
                <Route path="/BrandSequence" element={<BrandSequence />} />
                <Route path="/subscribers" element={<Subscribers />} />
                <Route path="/dynamicmodels" element={<DynamicModels />} />
                <Route path="/dynamicltos" element={<DynamicLTOs />} />
                <Route path="/dynamichotsell" element={<DynamicHotSell />} />
                <Route path="/TypesSequence" element={<TypesSequence />} />
                <Route path="/Footer" element={<DynamicFooter />} />
                <Route path="/adminSetting" element={<AdminsSetting />} />
            </Routes>
        </Router>
    )
}
export default Main;

