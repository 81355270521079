import React, { useState, useEffect } from 'react';
import Sidebar from '../main/sidebar';
import Header from '../main/header';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Env from '../../Environment/Env';
import { getAsset } from '../../utils/helper';
import  './DynamicTos.css'

function DynamicLTOs() {
    const currentPath = window.location.pathname;
    const [cards, setCards] = useState([]);
    const [currentCard, setCurrentCard] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [endDate, setEndDate] = useState('');
    const [oldPrice, setOldPrice] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [link, setLink] = useState('');
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        fetchCards();
    }, []);

    const fetchCards = async () => {
        try {
            const res = await axios.get(`${Env.server}/api/ltos/getAll`);
            console.log('aaaaaaaaaaaaaa', res.data);
            setCards(res.data);
        } catch (err) {
            console.error("Error fetching cards", err);
        }
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImage(file);
        setPreview(URL.createObjectURL(file));
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: handleDrop
    });

    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append('image', image);
            let imageUrl = "";
            if(currentCard){
                // I want to update;
                if(image){
                    // uploading image first
                    const img = await axios.post(`${Env.server}/api/uploadik/single`, formData);
                    imageUrl = img.data.url;
                }else{
                    imageUrl = preview;
                }
                await axios.put(`${Env.server}/api/ltos/update/${currentCard._id}`, {
                    image: imageUrl, title, endDate, oldPrice, newPrice, link
                });
            }else{
                // Creating case;
                if(image){
                    const img = await axios.post(`${Env.server}/api/uploadik/single`, formData);
                    imageUrl = img.data.url;
                }
                await axios.post(`${Env.server}/api/ltos/create`, {
                    image: imageUrl, title, endDate, oldPrice, newPrice, link
                });
            }
            setModalOpen(false);
            fetchCards();
            clearForm();
            // axios
            //     .post(`${Env.server}/api/uploadik/single`, formData)
            //     .then(async (res) => {
            //         // imageUrl = "/images/" + res.data.filename;
            //         imageUrl = res.data.url;
            //         if (currentCard) {
            //             await axios.put(`${Env.server}/api/ltos/update/${currentCard._id}`, {
            //                 image: preview, title, endDate, oldPrice, newPrice, link
            //             });
            //         } else {
            //             await axios.post(`${Env.server}/api/ltos/create`, {
            //                 image: imageUrl, title, endDate, oldPrice, newPrice, link
            //             });
            //         }
            //         setModalOpen(false);
            //         fetchCards();
            //         clearForm();
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        } catch (err) {
            console.error("Error saving card", err);
        }
    };

    const handleEdit = (card) => {
        setCurrentCard(card);
        setTitle(card.title);
        setEndDate(card.endDate);
        setOldPrice(card.oldPrice);
        setNewPrice(card.newPrice);
        setLink(card.link);
        setPreview(getAsset(card.image));
        setModalOpen(true);
    };

    const handleAdd = () => {
        clearForm();
        setModalOpen(true);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${Env.server}/api/ltos/${id}`)
            .then((res) => {
                console.log(res.data.message);
                fetchCards();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const clearForm = () => {
        setCurrentCard(null);
        setTitle('');
        setEndDate('');
        setOldPrice('');
        setNewPrice('');
        setLink('');
        setImage(null);
        setPreview(null);
    };

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflowY = modalOpen ? 'hidden' : 'auto';
    }, [modalOpen]);

    const clearImage = () => {
        setImage(null);
        setPreview(null);
    };


    const calculateRemainingTime = (comDate) => {
        let endTime = new Date(comDate).getTime();
        //  endTime=endTime.days()
        const now = new Date().getTime();
        const distance = endTime - now;

        if (distance < 0) {
            return "Expired";
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return `${days}d ${hours}h `;
    };

    return (
        <div>
            <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "wrapper"}>
                {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Sidebar />}

                <div className={currentPath.includes('signup') || currentPath.includes('signin') ? "" : "page-wrapper"}>
                    {currentPath.includes('signup') || currentPath.includes('signin') ? null : <Header />}

                    <div className="container-fluid">
                        <div className="pb-3">
                            <h1 className="text-center mb-4 fs-2" style={{ fontFamily: "poppins", fontWeight: "bolder" }}>Limited Time Offers Dynamic Setting</h1>
                        </div>
                        <button className="btn btn-primary mb-4" onClick={handleAdd}>Add New Card</button>
                        {/* <div className="row"> */}
                        {cards && cards.length > 0
                            ?
                            <div className="hot-container w-100 mt-5" >
                                {cards.map((card, index) =>

                                <div key={index} className="hot">
                                    <div className="hot-image">
                                        <img src={getAsset(card.image)} alt="" />
                                    </div>
                                    <div className="hot-content">
                                        <div className='hot-gift'>
                                            <h6>Limited Offer</h6>
                                            <div className="hot-end-time">{card.remainingTime}</div>

                                        </div>
                                        <div className="hot-title">
                                            <h3>{card.title}</h3>
                                        </div>
                                        <div className="hot-price">
                                            <p className="new-price"> <span>£</span>{card.newPrice}</p>
                                            <p className="old-price"> <span>£</span>{card.oldPrice}</p>
                                        </div>
                                        <p>Time Remaining:{calculateRemainingTime(card.endDate)}</p>
                                        <div className=""
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <button className="delete-button" onClick={() => handleDelete(card._id)}>Delete</button>
                                            <button className='edit-button'
                                                onClick={() => handleEdit(card)}
                                            >
                                                Edit
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                )}
                            </div>
                            :
                            <p>No cards available. Create a new card to get started.</p>
                        }
                        {/* </div> */}
                    </div>

                    {modalOpen && (
                        <div className="modal show d-block" style={{ overflowY: 'scroll' }} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{currentCard ? "Edit LTO" : "Add New LTO"}</h5>
                                        <button type="button" className="close" onClick={() => setModalOpen(false)}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input type="text" className="form-control" id="title" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="oldPrice">Old Price</label>
                                            <input type="number" className="form-control" id="oldPrice" placeholder="Enter old price" value={oldPrice} onChange={(e) => setOldPrice(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="newPrice">New Price</label>
                                            <input type="number" className="form-control" id="newPrice" placeholder="Enter new price" value={newPrice} onChange={(e) => setNewPrice(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="endDate">End Date</label>
                                            <input type="datetime-local" className="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="link">Link</label>
                                            <input type="text" className="form-control" id="link" value={link} onChange={(e) => setLink(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            <div {...getRootProps()} className="dropzone">
                                                <input {...getInputProps()} />
                                                {preview ? (
                                                    <div className="preview-container">
                                                        <img src={preview} alt="Preview" className="img-thumbnail" />
                                                        <button type="button" className="btn btn-danger remove-button" onClick={clearImage}>Remove</button>
                                                    </div>
                                                ) : (
                                                    <p>Drag 'n' drop an image, or click to select one</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DynamicLTOs;
