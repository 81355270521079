const Env = {
  // server: "http://localhost:8089",
  // logout1: "http://localhost:3000",
  server: "https://accback.selectezi.com",
  dashboard: "https://accdash.selectezi.com",
};
export default Env;











