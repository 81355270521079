import React, { useRef, Component, useEffect, useState } from "react";
import Sidebar from "../main/sidebar";
import Searchbar from "../main/navigation/searchbar";
import Header from "../main/header";
import axios from "axios";
import Env from "../../Environment/Env";
import { useDropzone } from "react-dropzone";
import { getAsset } from "../../utils/helper";
import JoditEditor from "jodit-react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Dropdown, DropdownItem } from 'react-bootstrap';
import "./css/sscatagory.css";
// import { Grid, TextField, Chip } from "@material-ui/core";

import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { Container, Row, Col, Form, Button as Button1 } from 'react-bootstrap';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from "./loading.gif";

const AddSSCatagory = () => {
  const editor = useRef(null);

  const currentPath = window.location.pathname;

  const [EditIDProduct, setEditIDProduct] = useState({});
  const [description, setDescription] = useState("");
  const [specification, setSpecification] = useState("");
  const [ReviewPersonName, setReviewPersonName] = useState("");
  const [PersonReview, setPersonReview] = useState("");

  const [getAllSubCatagory, setgetAllSubCatagory] = useState([]);
  const [getSubSubCatagory, setGetSubSubCatagory] = useState([]);
  const [getSubSubCatagory2, setGetSubSubCatagory2] = useState([]);
  const [subSubCatagoryName, setSubSubCatagoryName] = useState("");
  const [SKU, setSKU] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [VarHookShow, setVarHookShow] = useState(false);
  const [TypeHookShow, setTypeHookShow] = useState(false);
  const [ManfacHookShow, setManfacHookShow] = useState(false);
  const [colorHookShow, setColorHookShow] = useState(false);
  const [categoryHookShow, setCategoryHookShow] = useState(false);

  const [subCatagoryID, setSubCatagoryID] = useState("");
  const [subCatagoryName, setSubCatagoryName] = useState("");
  const [CatagoryID, setCatagoryID] = useState("");
  const [ProductQty, setProductQty] = useState("");

  const [CatagoryName, setCatagoryName] = useState("");
  const [image, setImages] = useState("");
  const [image1, setImages1] = useState("");
  const [responseImages, setResponseImages] = useState([]);
  const [responseImages1, setResponseImages1] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [imagesFront1, setImagesFront1] = useState([]);
  const [deleteID, setDeleteID] = useState("");

  const [variantArray, setVariantArray] = useState([]);
  const [TypeArray, setTypeArray] = useState([]);
  const [manufacturerArray, setManufacturerArray] = useState([]);
  const [colorArray, setColorArray] = useState([]);

  const [variantShow, setVariantShow] = useState(undefined);
  const [typeShow, setTypeShow] = useState("");
  const [manufacturerShow, setManufacturerShow] = useState("");

  const [selectedColors, setSelectedColors] = useState([]);
  const [SelectedCManufacture, setSelectedCManufacture] = useState([]);
  const [SelectedVarient, setSelectedVarient] = useState([]);
  const [SelectedCCatagor, setSelectedCatagory] = useState([]);
  const [SelectedModels, setSelectedModels] = useState([]);


  const [newVariant, setNewVariant] = useState("");
  const [NewType, setNewType] = useState("");
  const [newManufacturer, setNewManufacturer] = useState("");
  const [newColor, setNewColor] = useState("");
  const [newCategory, setNewCategory] = useState("");

  const [SelectedVarientName, setSelectedVarientName] = useState(undefined);
  const [SelectedTypeName, setSelectedTypeName] = useState("");
  const [SelectedManufactureName, setSelectedManufactureName] = useState("");
  const [selectedColorName, setSelectedColorName] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const [selectedVariant, setSelectedVariant] = useState({ id: "", name: "" });
  const [selectedType, setSelectedType] = useState({ id: "", name: "" });
  const [selectedManufacturer, setSelectedManufacturer] = useState({
    id: "",
    name: "",
  });
  const [selectedColor, setSelectedColor] = useState({ id: "", name: "" });
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });
  const [isButtonOn, setIsButtonOn] = useState(false);
  const [productStates, setProductStates] = useState({});
  const [GetAllBrands, setGetAllBrands] = useState([]);
  const [GetAllModel, setGetAllModel] = useState([]);
  const [UpdateImageActive, setUpdateImageActive] = useState(false);
  const [UpdateImageData, setUpdateImageData] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checked, setChecked] = React.useState(false);

  // Optional
  const [optionalwholesalePrice, setOptionalwholesalePrice] = useState("");
  const [optionalPrice, setOptionalPrice] = useState("");
  const [optionalSKU, setOptionalSKU] = useState("");
  const [optionalQty, setOptionalQty] = useState("");
  const [EdtImageGaleryIndex, setEdtImageGaleryIndex] = useState();

  const [ModalBrand, setModalBrand] = useState([]);
  const [currentPage,setCurrentPage] = useState(1);
  const [productItems,setProductItems] = useState(50);
  const [totalPages,setTotalPages] = useState(1);
  const [loading,setLoading] = useState(false);




  const removeFromModalHook = (id, index) => {
    console.log("abc====", id, index);
    
    const updatedModels = [...SelectedModels];
    updatedModels.splice(index, 1); 

    const updatedModels1 = [...modelSelected];
    updatedModels1.splice(index, 1); 
    setModelSelected(updatedModels1)
    
    console.log("updatedModels====", updatedModels);
    
    // Update the state with the updated array
    setSelectedModels(updatedModels);

  };

  const removeFromVarientHook = (id, index) => {
    console.log("abc====", id, index);
    
    const updatedModels = [...SelectedVarient];
    updatedModels.splice(index, 1); 

    const updatedModels1 = [...selectedVariants];
    updatedModels1.splice(index, 1);

  
    console.log("updatedModels====", updatedModels);
    console.log("updatedModels====", updatedModels1);
    
    // Update the state with the updated array
    setSelectedVarient(updatedModels);
    setSelectedVariants(updatedModels1)
  };
  

  const removeFromBrandHook = (id, index) => {
    console.log("abc====", id, index);
    
    const updatedModels = [...SelectedBrandForShow];
    updatedModels.splice(index, 1); 

    const updatedModels1 = [...GetAllCatagoryBrand];
    updatedModels1.splice(index, 1);

  
    console.log("updatedModels====", updatedModels);
    console.log("updatedModels1====", updatedModels1);
    
    // Update the state with the updated array
    setSelectedBrandForShow(updatedModels);
    setGetAllCatagoryBrand(updatedModels1)
  };

  
  const removeFromManufactureHook = (id, index) => {
    console.log("abc====", id, index);
    
    const updatedModels = [...SelectedCManufacture];
    updatedModels.splice(index, 1); 

    const updatedModels1 = [...selectedManufactures];
    updatedModels1.splice(index, 1); 

    
    console.log("updatedModels====", updatedModels);
    console.log("updatedModels====", updatedModels1);
    
    // Update the state with the updated array
    setSelectedCManufacture(updatedModels);
    setSelectedManufactures(updatedModels1);
  };
  

  const removeFromColorHook = (id, index) => {
    console.log("abc====", id, index);
    console.log('aaaaaaaaaaaaaaaa',updatedModels);
    
    const updatedModels = [...selectedColors];
    updatedModels.splice(index, 1); 

    const updatedModels1 = [...selectedColorss];
    updatedModels1.splice(index, 1); 

    
    console.log("updatedModels====", updatedModels);
    console.log("updatedModels====", updatedModels1);
    
    // Update the state with the updated array
    setSelectedColors(updatedModels);
    setSelectedColorss(updatedModels1);
  };
  
  
  
// Sraech type in modal


const TypeSearchModalData = (e) => {
  console.log("a", e.target.value)
  let data = e.target.value;
  console.log("data===", data);
  console.log("TypeArray===", TypeArray);
  
  if(e.target.value === ""){
    axios.get(`${Env.server}/api/filters/gettype`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allType)
      setTypeArray(res.data.allType) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }else{
    axios.get(`${Env.server}/api/filters/gettypebyString/${data}`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allType)
      setTypeArray(res.data.allType) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }

};



const TypeSearchVarientData = (e) => {
  console.log("a", e.target.value)
  let data = e.target.value;
  console.log("data===", data);
  console.log("variantArray===", variantArray);
  
  if(e.target.value === ""){
    axios.get(`${Env.server}/api/filters/getvariant`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allVariants)
      setVariantArray(res.data.allVariants) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }else{
    axios.get(`${Env.server}/api/filters/getVarientbyString/${data}`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allVariants)
      setVariantArray(res.data.allVariants) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }

};

const TypeSearchModelData = (e) => {
  console.log("a", e.target.value)
  let data = e.target.value;
  console.log("data===", data);
  console.log("variantArray===", GetAllModel);
  
  if(e.target.value === ""){
    axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
    .then((res) => {
      console.log("abc===>>", res.data.subcatagories);
      setGetAllModel(res.data.subcatagories);
    })
    .catch((err) => {
      console.log("err===>>", err);
    })
  }else{
    axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagorybySearch/${data}`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.subcatagories)
      setGetAllModel(res.data.subcatagories) 
      setGetAllModel(res.data.subcatagories) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }

};




const TypeSearchManufactureData = (e) => {
  console.log("a", e.target.value)
  let data = e.target.value;
  console.log("data===", data);
  console.log("manufacturerArray===", manufacturerArray);
  
  if(e.target.value === ""){
    axios.get(`${Env.server}/api/filters/getmanufacturer`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allManufacturer)
      setManufacturerArray(res.data.allManufacturer) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }else{
    axios.get(`${Env.server}/api/filters/getManufacturebyString/${data}`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allManufacturer)
      setManufacturerArray(res.data.allManufacturer) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }

};

const TypeSearchColorData = (e) => {
  console.log("a", e.target.value)
  let data = e.target.value;
  console.log("data===", data);
  console.log("colorArray===", colorArray);
  
  if(e.target.value === ""){
    axios.get(`${Env.server}/api/filters/getcolor`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allColors)
      setColorArray(res.data.allColors) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }else{
    axios.get(`${Env.server}/api/filters/getColorbyString/${data}`)
    .then((res)=>{
      console.log("Mmmmmm====", res.data.allColors)
      setColorArray(res.data.allColors) 
    })
    .catch((err) => {
      console.log("err===", err)
    })
  }

};


// Sraech type in color

  const handleOptionalPriceChange = (event) => {
    setOptionalPrice(event.target.value);
  };

  const handleOptionalWholeSalePriceChange = (event) => {
    setOptionalwholesalePrice(event.target.value);
  };

  const handleOptionalSKUChange = (event) => {
    setOptionalSKU(event.target.value);
  };

  const handleOptionalQtyChange = (event) => {
    setOptionalQty(event.target.value);
  };

  // Optional

  // Select All and delete
  const handleToggleItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleDeleteImage = async (imageId) => {
    setUpdateImageActive(false);
    console.log(imageId);
    console.log(UpdateImageData._id);
    axios
      .delete(
        `${Env.server}/api/subsubcatagory/DeleteImage/${UpdateImageData._id}/${imageId}`
      )
      .then((res) => {
        console.log(res.data);
        AllSubCatagory();
        // GetSubSubCatagory();
        console.log('Deleted Images.....');
        GetSubSubCatagoryP();
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(response.data); // Log success message or handle response as needed
  };

  const handleEditImageGallery = async (imageId) => {
    console.log("ac====", imageId);
  };

  const handleToggleAll = () => {
    if (selectedItems.length === filteredSubSubCatagory.length) {
      setSelectedItems([]);
    } else {
      const allItemIds = filteredSubSubCatagory.map((data) => data._id);
      setSelectedItems(allItemIds);
    }
  };
  
  const handleDeleteSelected = () => {
    console.log("Deleting selected items:", selectedItems);
    axios
      .delete(`${Env.server}/api/subsubcatagory/DeleteSubsubcatagoryMany`, {
        data: { ids: selectedItems },
      })
      .then((res) => {
        console.log("abc===>>>", res.data.message);
        if (res.data.message === "SuccessDelete") {
          // GetSubSubCatagory();
          GetSubSubCatagoryP();
          setSelectedItems([]);
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };
  // Select All and delete

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  

  console.log("abc============================", getSubSubCatagory);
  let filteredSubSubCatagory = getSubSubCatagory && getSubSubCatagory.filter((data) => {
    console.log('I am running without any control!!!!');
    // Check if data and its properties are defined before accessing them
    return (
        data &&
        data.subsubcatagory &&
        data.subsubcatagory.toLowerCase() && // Check if subsubcatagory is defined
        data.subsubcatagory.toLowerCase().includes(searchTerm.toLowerCase()) || 
        data &&
        data.subcatagory &&
        data.subcatagory.toLowerCase() && // Check if subsubcatagory is defined
        data.subcatagory.toLowerCase().includes(searchTerm.toLowerCase()) || 
        data &&
        data.SKU &&
        data.SKU.toLowerCase().includes(searchTerm.toLowerCase()) || // Check if SKU is defined
        data.productPrice &&
        data.productPrice.toString().toLowerCase().includes(searchTerm && searchTerm.toLowerCase()) || // Check if productPrice is defined
        data &&
        data.qty &&
        data.qty.toString().toLowerCase().includes(searchTerm.toLowerCase()) // Check if qty is defined
    ); 
  });


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleButton = (productId, status) => {
    setProductStates((prevStates) => ({
      ...prevStates,
      [productId]: !prevStates[productId],
    }));
    console.log("Abc====", status);
    let body = { Show: status };
    UpdateEditData(body, productId);
  };

  useEffect(() => {
    GetAllBrandFun();
    fetchVariant();
    fetchType();
    fetchManufacturer();
    fetchColor();
    AllSubCatagory();
  }, []);

  const [editableIndex, setEditableIndex] = useState(-1); // Index of the currently editable item
  const [editedContent, setEditedContent] = useState(""); // Content being edited

  // name

  const handleEdit = (index, initialContent) => {
    setEditableIndex(index);
    setEditedContent(initialContent);
  };

  const handleSave = (index, id) => {
    // Perform saving operation with edited content
    console.log(
      `Saved changes for item at   ${id} :  ${index}: ${editedContent}`
    );
    setEditableIndex(-1); // Reset editable state
    if (editedContent === "") {
      UpdateEditData({ subsubcatagory: "none" }, id);
    } else {
      UpdateEditData({ subsubcatagory: editedContent }, id);
    }
  };

  // price
  const [editableIndex1, setEditableIndex1] = useState(-1); // Index of the currently editable item
  const [editedContent1, setEditedContent1] = useState(""); // Content being edited

  const handleEdit1 = (index, initialContent) => {
    setEditableIndex1(index);
    setEditedContent1(initialContent);
  };

  const handleSave1 = (index, id) => {
    // Perform saving operation with edited content
    console.log(
      `Saved changes for item at   ${id} :  ${index}: ${editedContent1}`
    );
    setEditableIndex1(-1); // Reset editable state
    if (editedContent1 === "") {
      UpdateEditData({ productPrice: 0 }, id);
    } else {
      UpdateEditData({ productPrice: editedContent1 }, id);
    }
  };

  const handleInputChange1 = (event) => {
    setEditedContent1(event.target.value);
  };

  // Whole Sale
  const [editableIndex11, setEditableIndex11] = useState(-1); // Index of the currently editable item
  const [editedContent11, setEditedContent11] = useState(""); // Content being edited

  const handleEdit11 = (index, initialContent) => {
    setEditableIndex11(index);
    setEditedContent11(initialContent);
  };

  const handleSave11 = (index, id) => {
    // Perform saving operation with edited content
    console.log(
      `Saved changes for item at   ${id} :  ${index}: ${editedContent11}`
    );
    setEditableIndex11(-1); // Reset editable state
    if (editedContent11 === "") {
      UpdateEditData({ wholesellerPrice: 0 }, id);
    } else {
      UpdateEditData({ wholesellerPrice: editedContent11 }, id);
    }
  };

  const handleInputChange11 = (event) => {
    setEditedContent11(event.target.value);
  };

  // SKU

  const [editableIndexSKU, setEditableIndexSKU] = useState(-1); // Index of the currently editable item
  const [editedContentSKU, setEditedContentSKU] = useState(""); // Content being edited

  const handleEditSKU = (index, initialContent) => {
    setEditableIndexSKU(index);
    setEditedContentSKU(initialContent);
  };

  const handleSaveSKU = (index, id) => {
    // Perform saving operation with edited content
    console.log(
      `Saved changes for item at   ${id} :  ${index}: ${editedContentSKU}`
    );
    setEditableIndexSKU(-1); // Reset editable state
    if (editedContentSKU === "") {
      UpdateEditData({ SKU: 0 }, id);
    } else {
      UpdateEditData({ SKU: editedContentSKU }, id);
    }
  };

  const handleInputChangeSKU = (event) => {
    setEditedContentSKU(event.target.value);
  };

  // Qty
  const [editableIndex2, setEditableIndex2] = useState(-1); // Index of the currently editable item
  const [editedContent2, setEditedContent2] = useState(""); // Content being edited

  const handleEdit2 = (index, initialContent) => {
    setEditableIndex2(index);
    setEditedContent2(initialContent);
  };

  const handleSave2 = (index, id) => {
    // Perform saving operation with edited content
    console.log(
      `Saved changes for item at   ${id} :  ${index}: ${editedContent2}`
    );
    setEditableIndex2(-1); // Reset editable state
    if (editedContent2 === "") {
      UpdateEditData({ qty: 0 }, id);
    } else {
      UpdateEditData({ qty: editedContent2 }, id);
    }
  };

  const handleInputChange2 = (event) => {
    setEditedContent2(event.target.value);
  };

  const [editableIndex3, setEditableIndex3] = useState(-1); // Index of the currently editable item
  const [editedContent3, setEditedContent3] = useState(""); // Content being edited

  const handleEdit3 = (index, initialContent) => {
    setEditableIndex3(index);
    setEditedContent3(initialContent);
  };

  const handleSave3 = (index) => {
    // Perform saving operation with edited content
    console.log(`Saved changes for item at index ${index}: ${editedContent3}`);
    setEditableIndex3(-1); // Reset editable <state></state>
  };

  const handleInputChange3 = (event) => {
    setEditedContent3(event.target.value);
  };

  const handleImageClick3 = (index, data) => {
    // Trigger the file input when the image is clicked
    setUpdateImageActive(true);
    setUpdateImageData(data);
  };

  const handleImageChange3 = (event, index) => {
    // Handle the image change event here
    const file = event.target.files[0];
    console.log(`New image selected for item at index ${index}:`, file);
    // You can perform further actions like uploading the image or displaying it preview
  };

  // Array to store file input references
  const fileInputs = Array(filteredSubSubCatagory.length).fill(null);

  const UpdateEditData = (data, id) => {
    axios
      .patch(`${Env.server}/api/subsubcatagory/updateEditedData/${id}`, data)
      .then((res) => {
        console.log("abc====", res.data);
        AllSubCatagory();
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const UpdateEditDataNewDb = (data, id) => {
    axios
      .patch(
        `${Env.server}/api/subsubcatagory/updateEditedDataNewDB/${id}`,
        data
      )
      .then((res) => {
        console.log("abc====", res.data);
        AllSubCatagory();
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const [GetAllCatagoryBrand, setGetAllCatagoryBrand] = useState([]);

  const handleBrandSelection1=(e)=>{
    console.log("brand===============>",e);
    console.log("brand====>", {id:e._id, name:e.catagory})
    console.log('Here is the selected>>>',e);
    setSelectedBrandForShow(prev => { 
      const existingIds = new Set(prev.map(item => item.id)); 
      if (!existingIds.has(e._id)) { 
          return [...prev, { id: e._id, name: e.catagory }];
      } else { 
          return prev;
      }
    });

    console.log('aaaaaaaaaaaaa',SelectedBrandForShow);
    axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagoryBrand/${e._id}`)
    .then((res) => {
      console.log('resp========>>>>',res.data);
      console.log("abc===>>", res.data.subcatagories);
      setGetAllModel(res.data.subcatagories);
    })
    .catch((err) => {
      console.log("err===>>", err);
    }) 

// Sepereate

    setSelectedBrand(prevSelectedVariants => {
      if (prevSelectedVariants.includes(e._id)) {
        console.log(`Deselecting variant: ${e}`);
        return prevSelectedVariants.filter(v => v !== editableIndex._id);
      } else {
        console.log(`Selecting variant: ${e}`, `Prev Selected Variants: ${prevSelectedVariants}`);
        return [...prevSelectedVariants, e._id];
      }
    }); 
  }

  const handleModelSelection = (event) => {
    setCurrentPage(1);
    setTotalPages(1);
    setProductItems(30);
    const currModel = GetAllModel.filter(mod=>mod._id === event.target.value);
    console.log('Current Model ===>>>',currModel);
    const selectedModelId = event.target.value;
    console.log("Selected Brand ID:", selectedModelId);
    if (selectedModelId === "all") {
      GetSubSubCatagory();
      // GetSubSubCatagoryP();
    } else {
      GetSubSubCatagory3(selectedModelId);
    }
  };

  const handleTypeSelection = (event) => {
    setCurrentPage(1);
    setTotalPages(1);
    setProductItems(30);
    const selectedModelId = event.target.value;
    console.log("Selected Brand ID:", selectedModelId);
    if (selectedModelId === "all") {
      GetSubSubCatagory();
      // GetSubSubCatagoryP();
    } else {
      GetSubSubCatagory4(selectedModelId);
    }
  };

  const GetSubSubCatagory4 = async(selectedtypeId) => {
    setImages([]);
    setImagesFront([]);
    setLoading(true)
    await axios
      .get(`${Env.server}/api/subsubcatagory/getAllByTypeDD/${selectedtypeId}`)
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abclll===>>", res.data.subsubcatagories);
        setGetSubSubCatagory(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
    setLoading(false)
  };

  const GetSubSubCatagory3 = async(selectedBrandId) => {
    setImages([]);
    setImagesFront([]);
    setLoading(true)
    await axios
      .get(
        `${Env.server}/api/subsubcatagory/getAllByModelDD/${selectedBrandId}`
      )
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abclll===>>", res.data.subsubcatagories);
        setGetSubSubCatagory(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
    setLoading(false)
  };

  const handleBrandSelection = (event) => {
    setCurrentPage(1);
    // setTotalPages(1);
    setProductItems(30);
    const selectedBrandId = event.target.value;
    console.log("Selected Brand ID:", selectedBrandId);
    if (selectedBrandId === "all") {
      GetSubSubCatagory();
      // GetSubSubCatagoryP();
      GetAllModelFun();
    } else {
      GetSubSubCatagory2(selectedBrandId);
      GetAllModelFunFilterBySSCat(selectedBrandId);
    }
  };

  const GetSubSubCatagory2 = async(selectedBrandId) => {
    setImages([]);
    setImagesFront([]);
    setLoading(true)
    await axios
      .get(
        `${Env.server}/api/subsubcatagory/getAllByBrandDD/${selectedBrandId}`
      )
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abclll===>>", res.data.subsubcatagories);
        setGetSubSubCatagory(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
    setLoading(false)
  };

  const GetAllModelFun = () => {
    axios
      .get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
      .then((res) => {
        setGetAllModel(res.data.subcatagories);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const GetAllModelFunFilterBySSCat = (brandid) => {
    axios
      .get(`${Env.server}/api/subcatagory/GetAllSubCatagoryBrand/${brandid}`)
      .then((res) => {
        setGetAllModel(res.data.subcatagories);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const GetAllBrandFun = () => {
    axios
      .get(`${Env.server}/api/catagory/GetAllCatagory`)
      .then((res) => {
        setGetAllBrands(res.data.catagories);
        setModalBrand(res.data.catagories);
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const typeOptions = [
    "Cases",
    "Screen Protector",
    "Car Phone Holder",
    "Chargers & Cables",
    "Wireless Charges",
    "Spare Parts",
    "Smartwatches",
    "Kid Tech",
    "Life Style",
  ];

  const [typeOption, setTypeOption] = useState("");


  const handleDropdownItemClick = (item) => {
    console.log('Selected Item:', item.type);
    
    // Add any other logic you want to perform when an item is selected
  };
  
  const handleVariantChange1 = (item) => {
    console.log("e.target.value");
    const selectedId = item._id;
      setSelectedType({ id: selectedId, name: item.type });
      setTypeShow(selectedId);
      console.log("hhhhhhhhh", { id: selectedId, name: item.type });
      setSelectedTypeName(item.type); 
  };

  // Assending and Desending
  const [sortingOrder, setSortingOrder] = useState("ascending"); // State to track sorting order

  const handleSortingChange = (event) => {
    setSortingOrder(event.target.value); // Update sorting order based on select field value
  };

  const sortedData =
    sortingOrder === "ascending"
      ? filteredSubSubCatagory.slice().reverse()
      : filteredSubSubCatagory.slice();

  //deleteSystem

  const [matchedItemCount, setMatchedItemCount] = useState(0);
  const [matchedItem, setMatchedItem] = useState("");
  const [DeleteSelectedItem, setDeleteSelectedItem] = useState([]);

  const handleDeleteType = (event, itemId, label) => {
    // event.stopPropagation();
    const matchedItems = getSubSubCatagory.filter(
      (item) => item.type === itemId
    );
    setMatchedItemCount(matchedItems.length);
    setMatchedItem(label);
    console.log("matchedItems.length====", matchedItems.length);
    console.log("matchedItems====", matchedItems);
    const ids = matchedItems.map((item) => item._id);
    setDeleteSelectedItem(itemId);
    setSelectedItems(ids);
  };

  const handleDeleteVarient = (event, itemId, label) => {
    // event.stopPropagation();
    const matchedItems = getSubSubCatagory.filter(
      (item) => item.variant === itemId
    );
    setMatchedItemCount(matchedItems.length);
    setMatchedItem(label);
    console.log("matchedItems.length====", matchedItems.length);
    console.log("matchedItems====", matchedItems);
    const ids = matchedItems.map((item) => item._id);
    setDeleteSelectedItem(itemId);
    setSelectedItems(ids);
  };

  const handleDeleteManufacturer = (event, itemId, label) => {
    // event.stopPropagation(); // Stop event propagation to prevent selecting the field
    console.log("Deleted item id:", itemId);
    const matchedItems = getSubSubCatagory.filter(
      (item) => item.manufacturer === itemId
    );
    setMatchedItemCount(matchedItems.length);
    setMatchedItem(label);
    console.log("matchedItems.length====", matchedItems.length);
    console.log("matchedItems====", matchedItems);
    const ids = matchedItems.map((item) => item._id);
    setDeleteSelectedItem(itemId);
    setSelectedItems(ids);
  };

  const handleDelete = async (info) => {
    if (info === "all") {
      console.log("all====", info);
      console.log("ids====", selectedItems);
      await handleDeleteSelected(); 
    } else {
      console.log("noall====", info);
      if (matchedItem === "Type") {
        console.log("delete type");
        axios
          .delete(`${Env.server}/api/filters/deletetype/${DeleteSelectedItem}`)
          .then((res) => {
            console.log("Abc-----", res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log("err===", err);
          });
      } else if (matchedItem === "Variant") {
        console.log("delete variant");
        axios
          .delete(
            `${Env.server}/api/filters/deletevarient/${DeleteSelectedItem}`
          )
          .then((res) => {
            console.log("Abc-----", res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log("err===", err);
          });
      } else if (matchedItem === "Manufacturer") {
        console.log("delete manufacturer");
        axios
          .delete(
            `${Env.server}/api/filters/deletemanufacturer/${DeleteSelectedItem}`
          )
          .then((res) => {
            console.log("Abc-----", res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log("err===", err);
          });
      } else if (matchedItem === "Color") {
        console.log("delete color");
        axios
          .delete(`${Env.server}/api/filters/deletecolor/${DeleteSelectedItem}`)
          .then((res) => {
            console.log("Abc-----", res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log("err===", err);
          });
      }
    }
  };

  const handleDeleteColor = (event, itemId, label) => {
    // event.stopPropagation(); // Stop event propagation to prevent selecting the field
    console.log("Deleted item id:", itemId);
    
    const matchedItems = getSubSubCatagory.filter(
      (item) => item.filter[0].Color === itemId
    );
    setMatchedItemCount(matchedItems.length);
    setMatchedItem(label);
    console.log("matchedItems.length====", matchedItems.length);
    console.log("matchedItems====", matchedItems);
    const ids = matchedItems.map((item) => item._id);
    setDeleteSelectedItem(itemId);
    setSelectedItems(ids);
  };

  const handleDeleteFinal = () => {
    console.log("abc=====");
  };
  const [selectedVariants, setSelectedVariants] = useState([]);
  const handleVariantChange = (item) => {
    
  };


  const handleVariantChange11 = (e) => {
    setSelectedVariants(prevSelectedVariants => {
      if (prevSelectedVariants.includes(e._id)) {
        console.log(`Deselecting variant: ${e}`);
        return prevSelectedVariants.filter(v => v !== editableIndex._id);
      } else {
        console.log(`Selecting variant: ${e}`);
        return [...prevSelectedVariants, e._id];
      }
    });
    console.log("Abc====", e)
    const selectedIds = e._id;
 
    const selectedVarientItems = variantArray.filter((item) =>
      selectedIds.includes(item._id)
    );
 
    const selectedVarient = selectedVarientItems.map((item) => ({
      id: item._id,
      name: item.variant,
    }));

    console.log("selectedVarient=====", selectedVarient);
    

    setSelectedVarient(prevSelectedVarient => { 
      const combinedVariants = [...prevSelectedVarient, ...selectedVarient]; 
      const uniqueVariants = new Set(combinedVariants.map(variant => variant.id)); 
      const uniqueVariantArray = Array.from(uniqueVariants).map(id => { 
          return combinedVariants.find(variant => variant.id === id);
      }); 
      return uniqueVariantArray;
  });
};



  const [selectedBrand, setSelectedBrand] = useState([]);
  const [SelectedBrandForShow, setSelectedBrandForShow] = useState([]);

  const handleBrandChange11 = (e) => {
    setSelectedBrand(prevSelectedVariants => {
      if (prevSelectedVariants.includes(e._id)) {
        console.log(`Deselecting variant: ${e}`);
        return prevSelectedVariants.filter(v => v !== editableIndex._id);
      } else {
        console.log(`Selecting variant: ${e}`);
        return [...prevSelectedVariants, e._id];
      }
    });
    
  };


  

  const [selectedManufactures, setSelectedManufactures] = useState([]);

  const handleManufacturerChange = (e) => {
    setSelectedManufactures(prevSelectedVariants => {
      if (prevSelectedVariants.includes(e._id)) {
        console.log(`Deselecting variant: ${e}`);
        return prevSelectedVariants.filter(v => v !== editableIndex._id);
      } else {
        console.log(`Selecting variant: ${e}`);
        return [...prevSelectedVariants, e._id];
      }
    });

    const selectedIds = e._id;

    // Filter out selected color items based on selected IDs
    const selectedManufactureItems = manufacturerArray.filter((item) =>
      selectedIds.includes(item._id)
    );

    // Map selected color items to an array of selected colors
    const selectedManufacture = selectedManufactureItems.map((item) => ({
      id: item._id,
      name: item.manufacturer,
    }));

    console.log("colorselect=====", selectedManufacture);
 

  //   setSelectedCManufacture(prevSelectedManufacture => {
  //     const combinedManufacturers = [...prevSelectedManufacture, ...selectedManufacture]; 
  //     const uniqueManufacturers = new Set(combinedManufacturers.map(manufacturer => manufacturer.id)); 
  //     const uniqueManufacturerArray = Array.from(uniqueManufacturers).map(id => { 
  //         return combinedManufacturers.find(manufacturer => manufacturer.id === id);
  //     }); 
  //     return uniqueManufacturerArray;
  // });

  setSelectedCManufacture(prevSelectedManufacture => {
    const CombinedManufacture = [...prevSelectedManufacture, ...selectedManufacture];
    const uniqueManufacturer = new Set(CombinedManufacture.map(manufacturer => manufacturer.id)); 
    const uniqueManufacturerArray = Array.from(uniqueManufacturer).map(id => {
        return CombinedManufacture.find(manufacturer => manufacturer.id === id);
    }); 
    return uniqueManufacturerArray;
});

  
  };


  const [selectedColorss, setSelectedColorss] = useState([]);


  const handleColorChange = (e) => {

    setSelectedColorss(prevSelectedVariants => {
      if (prevSelectedVariants.includes(e._id)) {
        console.log(`Deselecting variant: ${e}`);
        return prevSelectedVariants.filter(v => v !== editableIndex._id);
      } else {
        console.log(`Selecting variant: ${e}`);
        return [...prevSelectedVariants, e._id];
      }
    });
    const selectedIds = e._id;
    
    // Filter out selected color items based on selected IDs
    const selectedColorItems = colorArray.filter((item) =>
      selectedIds.includes(item._id)
    );

    // Map selected color items to an array of selected colors
    const selectedColors = selectedColorItems.map((item) => ({
      id: item._id,
      name: item.color,
    }));

    console.log("colorselect=====", selectedColors);
    // setSelectedColors(prevSelectedColors => [
    //   ...prevSelectedColors,
    //   ...selectedColors
    // ]);

      setSelectedColors(prevSelectedColors => {
        // Combine the existing manufacturers and the new manufacturers into a single array
        const CombinedColor = [...prevSelectedColors, ...selectedColors];

        // Create a Set from the combined array to remove duplicates
        const uniqueColor = new Set(CombinedColor.map(color => color.id));

        // Convert the Set back to an array
        const uniqueColorArray = Array.from(uniqueColor).map(id => {
            // Find the manufacturer object corresponding to each unique ID
            return CombinedColor.find(color => color.id === id);
        });

        // Return the array of unique manufacturers
        return uniqueColorArray;
    });

  };

  const [selectedModelsModal, setSelectedModelsModal] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOptions = getAllSubCatagory.filter((item) =>
    item.subcatagory.toLowerCase().includes(searchTerm.toLowerCase())
  );

const [modelSelected , setModelSelected] = useState([]);

  const handleModelChange1 = (event) => {
    const selectedIds = Array.from(event.target.selectedOptions, option => option.value);
    const selectedModels = filteredOptions.filter(option => selectedIds.includes(option._id));
    // const selectingBrands = Array.from(new Set(selectedModels.map(model=>model.catagory)));
    // const finalBrandOptions = ModalBrand.filter(br=>selectingBrands.includes(br.catagory))
    // console.log('aaaaaaaaaaaaaaaaaaaBrandOptions===>>', ModalBrand);
    // console.log('aaaaaaaaaaaaaaaaaaaBrandOptions===>>', selectingBrands);
    // console.log('aaaaaaaaaaaaaaaaaaa=========>>', finalBrandOptions);
    // setSelectedBrandForShow(Array.from(finalBrandOptions.map((opt)=>({id:opt._id,name:opt.catagory}))));
    

    // Transform selected models into desired format
    const transformedModels = selectedModels.map(model => ({
      id: model._id,
      name: model.subcatagory,
      category: model.catagory,
      categoryId: model.catagoryid
    }));
  
    console.log("Transformed Models:", selectedModels);
  
    setModelSelected(selectedModels);
    setSelectedModelsModal(transformedModels);
    setSelectedModels(transformedModels);
  };
  


  const handleModelChange = (e) => {
    const selectedIds = e.target.value;

    // Filter out selected color items based on selected IDs
    const selectedCatgoryItems = getAllSubCatagory.filter((item) =>
      selectedIds.includes(item._id)
    ); 

    // Map selected color items to an array of selected colors
    const selectedCatagory = selectedCatgoryItems.map((item) => ({
      id: item._id,
      name: item.subcatagory,
      catagory: item.catagory,
      catagoryid: item.catagoryid,
    }));

    console.log("selectedCatagory=====", selectedCatagory);
    setSelectedModelsModal(selectedCatagory);
    setSelectedModels(selectedCatagory);
  };

  const fetchVariant = async () => {
    try {
      await axios
        .get(`${Env.server}/api/filters/getvariant`)
        .then((response) => {
          console.log(response.data.allVariants);
          // const { variants } = response.data;

          setVariantArray(response.data.allVariants);
          // setVariantArray([...response.data]);
        })
        .catch((error) => {
          console.log("Error whiling fetching Variants", error.message);
        });
    } catch (error) {
      console.error("Error whiling fetching Variants ====> ", error);
    }
  };

  const fetchType = async () => {
    try {
      await axios
        .get(`${Env.server}/api/filters/gettype`)
        .then((response) => {
          console.log(response.data.allTypes);
          // const { variants } = response.data;
          setTypeArray(response.data.allType);
        })
        .catch((error) => {
          console.log("Error whiling fetching Variants", error.message);
        });
    } catch (error) {
      console.error("Error whiling fetching Variants ====> ", error);
    }
  };

  const AddVariant = async () => {
    try {
      await axios
        .post(`${Env.server}/api/filters/addvariant`, {
          variant: newVariant,
        })
        .then(async (res) => {
          console.log("Variant added Successfully", res.data);
          await fetchVariant();
          setVarHookShow(false);
        })
        .catch((error) => {
          console.log("Error while adding new Variant", error.message);
        });
    } catch (error) {
      console.log("Error while adding new Variant ===> ", error.message);
    }
  };

  const AddType = async () => {
    try {
      await axios
        .post(`${Env.server}/api/filters/addtype`, {
          type: NewType,
        })
        .then(async (res) => {
          console.log("Variant added Successfully", res.data);
          await fetchType();
          setTypeHookShow(false);
        })
        .catch((error) => {
          console.log("Error while adding new Variant", error.message);
        });
    } catch (error) {
      console.log("Error while adding new Variant ===> ", error.message);
    }
  };

  const fetchManufacturer = async () => {
    try {
      await axios
        .get(`${Env.server}/api/filters/getmanufacturer`)
        .then((response) => {
          console.log(response.data.allManufacturer);
          setManufacturerArray(response.data.allManufacturer);
        })
        .catch((error) => {
          console.log("Error whiling fetching Manufacturers", error.message);
        });
    } catch (error) {
      console.log("Error whiling fetching Manufacturers ===> ", error);
    }
  };

  const addManufacturer = async () => {
    try {
      await axios
        .post(`${Env.server}/api/filters/addmanufacturer`, {
          manufacturer: newManufacturer,
        })
        .then((res) => {
          console.log("Manufacturer added Successfully", res.data);
          fetchManufacturer();
          setManfacHookShow(false);
        })
        .catch((error) => {
          console.log("Error while adding new Manufacturer", error.message);
        });
    } catch (error) {
      console.log("Error while adding new Manufacturer ===> ", error.message);
    }
  };

  const fetchColor = async () => {
    try {
      await axios
        .get(`${Env.server}/api/filters/getcolor`)
        .then((response) => {
          console.log(response.data.allColors);
          setColorArray(response.data.allColors);
        })
        .catch((error) => {
          console.log("Error while fetching Colors", error.message);
        });
    } catch (error) {
      console.log("Error whiling fetching Colors ==> ", error);
    }
  };

  const addColor = async () => {
    try {
      await axios
        .post(`${Env.server}/api/filters/addcolor`, {
          color: newColor,
        })
        .then((res) => {
          console.log("Color Added Successfully", res.data);
          fetchColor();
          setColorHookShow(false);
        })
        .catch((error) => {
          console.log("Error while adding new Color", error.message);
        });
    } catch (error) {
      console.log("Error while adding new Color", error.message);
    }
  };

  const [isProductNameEditable, setIsProductNameEditable] = useState(false);


  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState(["Variant", "Manufacturer"]);
  const [enteredValues, setEnteredValues] = useState({});
  const [enteredValuesArray, setEnteredValuesArray] = useState({});

  const handleOptionSelect = (event) => {
    const selectedOption = event.target.value;
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setOptions(options.filter((option) => option !== selectedOption));
      setEnteredValuesArray({ ...enteredValuesArray, [selectedOption]: [] });
    }
  };

  const handleInputChange = (event, option) => {
    setEnteredValues({ ...enteredValues, [option]: event.target.value });
    setEditedContent(event.target.value);
  };

  const handleInputKeyPress = (event, option) => {
    if (event.key === "Enter" && enteredValues[option].trim() !== "") {
      setEnteredValuesArray({
        ...enteredValuesArray,
        [option]: [...enteredValuesArray[option], enteredValues[option]],
      });
      setEnteredValues({ ...enteredValues, [option]: "" });
    }
  };

  const handleRemoveValue = (option, index) => {
    const updatedValuesArray = [...enteredValuesArray[option]];
    updatedValuesArray.splice(index, 1);
    setEnteredValuesArray({
      ...enteredValuesArray,
      [option]: updatedValuesArray,
    });
  };


  const handleDeleteFun = () => {
    console.log("c====>>>", deleteID);
    axios
      .delete(
        `${Env.server}/api/subsubcatagory/DeleteSubsubcatagory/${deleteID}`
      )
      .then((res) => {
        console.log("abc===>>>", res.data.message);
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const handleSelectChange5 = (event) => {
    const selectedSubCategoryId = event.target.value;
    const selectedSubCategory = getAllSubCatagory.find(
      (data) => data._id === selectedSubCategoryId
    );  
    if (selectedSubCategory) {
      setCatagoryID(selectedSubCategory.catagoryid);
      setCatagoryName(selectedSubCategory.catagory);
      console.log("Ac=====", selectedSubCategory.catagoryid);
    } else {
      console.log("Selected subcategory not found");
    }
    setSubCatagoryID(event.target.value);
    setSubCatagoryName(event.target.options[event.target.selectedIndex].text);
    setSubSubCatagoryName(
      event.target.options[event.target.selectedIndex].text
    );

    console.log(`Selected option: ${event.target.value}`);
    console.log(
      `Selected option: dd ${
        event.target.options[event.target.selectedIndex].text
      }`
    );
  };

  const handleSelectChange6 = (e) => {
    const selectedSubCatagoryID = e.target.value;
    const selectedSubCatagory = getAllSubCatagory.find(
      (data) => data._id === selectedSubCatagoryID
    );
    if (selectedSubCatagory) {
      setSubCatagoryID(selectedSubCatagoryID);
      setSubSubCatagoryName(selectedSubCatagory.subcatagory);
      setIsProductNameEditable(true);
    }
  };

  const [ViewEdit , setViewEdit] = useState(false);
  const [ViewEditImages , setViewEditImages] = useState([]);
  const [ViewEditAllData , setViewEditAllData] = useState({});
  const [ViewEditImage , setViewEditImage] = useState([]);
  

  const HandleViewProductDetail=(data)=>{
    setViewEdit(true)
    console.log("abc====", data)
    setViewEditAllData(data)
    // type
    setTypeShow(data.type)
    setSelectedTypeName(data.typeName)
    setSelectedType({ id: data.type, name: data.typeName })
    // Images
    setViewEditImages(data.imageSubsubCatagory)
    // Brands
    setSelectedBrandForShow([{id:data.catagoryid , name:data.catagory}])
    // Modals
    // console.log('Here is the SKU I recieved::',SKU)
    if(data.SKU !== '0'){
      const currentAccess = getSubSubCatagory.filter(subcat =>subcat.SKU === data.SKU);
      // const allModels1 = currentAccess.map(cat=>{id:cat.subcatagoryid,, name:cat.subcatagory,category:cat.catagory, categoryId:cat.catagoryid})
      console.log('I got all sub categories as',currentAccess);
      setSelectedModels(currentAccess.map(cat=>({id:cat.subcatagoryid, name:cat.subcatagory,category:cat.catagory, categoryId:cat.catagoryid})))
      setSelectedModelsModal(currentAccess.map(cat=>({id:cat.subcatagoryid , name:cat.subcatagory,category:cat.catagory, categoryId:cat.catagoryid})))
      setModelSelected(currentAccess.map(cat=>({catagory:cat.catagory , catagoryid: cat.catagoryid  , subcatagory:cat.subcatagory , _id:cat.subcatagoryid  })))

    }else{
      setSelectedModels([{id:data.subcatagoryid , name:data.subcatagory,category:data.catagory, categoryId:data.catagoryid}])
      setSelectedModelsModal([{id:data.subcatagoryid , name:data.subcatagory,category:data.catagory, categoryId:data.catagoryid}])
      setModelSelected([{catagory:data.catagory , catagoryid: data.catagoryid  , subcatagory:data.subcatagory , _id:data.subcatagoryid  }])
    }

    // Varient
    setSelectedVarient([{id:data.variant, name:data.variantName}])
    setSelectedVariants([data.variant]) 
    // Manufacture
    setSelectedCManufacture([{id:data.manufacturer, name:data.manufacturerName}])
    setSelectedManufactures([data.manufacturer])
    // Color
    setSelectedColors(data.color)
    setSelectedColorss(data.color.map(color => color.id))
    // price
    // setOptionalPrice(data.productPrice)
    // wholesale price
    // setOptionalwholesalePrice(data.wholesellerPrice)
    // SKU
    // setOptionalSKU(data.SKU)
    // QTY
    // setOptionalQty(data.qty)
    // image
    setViewEditImage(data.imageSubsubCatagory)
    
    console.log("ViewEditAllData",data.imageSubsubCatagory)
  }


  const clearAllHook=()=>{
    setViewEditAllData({});
    setTypeShow('');
    setSelectedTypeName('');
    setSelectedType({});
    setViewEditImages([]);
    setSelectedBrandForShow([]);
    setSelectedModels([]);
    setSelectedModelsModal([]);
    setModelSelected([]);
    setSelectedVarient([]);
    setSelectedVariants([]);
    setSelectedCManufacture([]);
    setSelectedManufactures([]);
    setSelectedColors([]);
    setSelectedColorss([]);
    setOptionalPrice('');
    setOptionalwholesalePrice('');
    setOptionalSKU('');
    setOptionalQty('');
  }

  const submit  = (imageFile) => {
    let data = {};
    console.log("ViewEditImage-SyedMuzzamal===", {imageSubsubCatagory: ViewEdit === true ? ViewEditImage : imageFile}) 
    if (optionalPrice && optionalSKU && optionalQty) {
      if(ViewEdit === true){
        data = {
          catagory: "",
          catagoryid: "",
          subcatagory: "",
          subcatagoryid: "",
          subsubcatagory: "",
          imageSubsubCatagory: ViewEdit === true ? ViewEditImage : imageFile,
          variant: variantShow && variantShow.trim() === "" ? undefined : variantShow,
          variantName: SelectedVarientName && SelectedVarientName.trim() === "" ? undefined : SelectedVarientName,
          manufacturer: manufacturerShow && manufacturerShow.trim() === "" ? undefined : manufacturerShow,
          manufacturerName: SelectedManufactureName && SelectedManufactureName.trim() === "" ? undefined : SelectedManufactureName,
          color: selectedColors,
          type: selectedType.id,
          typeName: SelectedTypeName,
          filter: [
            {
              Variant: [SelectedVarientName],
              Manufacturer: [SelectedManufactureName],
              Color: selectedColors.map((color) => color.name),
              ProductPrice: [productPrice],
            },
          ],
          extra: [
            {
              allmodels: SelectedModels,
            },
          ],
        };
      }else{
        data = {
          catagory: "",
          catagoryid: "",
          subcatagory: "",
          subcatagoryid: "",
          subsubcatagory: "",
          imageSubsubCatagory: ViewEdit === true ? ViewEditImage : imageFile,
          variant: variantShow && variantShow.trim() === "" ? undefined : variantShow,
          variantName: SelectedVarientName && SelectedVarientName.trim() === "" ? undefined : SelectedVarientName,
          manufacturer: manufacturerShow && manufacturerShow.trim() === "" ? undefined : manufacturerShow,
          manufacturerName: SelectedManufactureName && SelectedManufactureName.trim() === "" ? undefined : SelectedManufactureName,
          color: selectedColors,
          type: selectedType.id,
          typeName: SelectedTypeName,
          filter: [
            {
              Variant: [SelectedVarientName],
              Manufacturer: [SelectedManufactureName],
              Color: selectedColors.map((color) => color.name),
              ProductPrice: [productPrice],
            },
          ],
          extra: [
            {
              allmodels: SelectedModels,
            },
          ],
          // optional fields
          productPrice: optionalPrice,
          wholesellerPrice: optionalwholesalePrice,
          qty: optionalQty,
          SKU: optionalSKU,
          combine: true,
          // optional fields
        };
      }
    } else {
      data = {
        SKU: 0,
        catagory: "",
        catagoryid: "",
        subcatagory: "",
        subcatagoryid: "",
        subsubcatagory: "",
        productPrice: 0,
        imageSubsubCatagory: ViewEdit === true ? ViewEditImage : imageFile,
        variant: variantShow && variantShow.trim() === "" ? undefined : variantShow,
        variantName: SelectedVarientName && SelectedVarientName.trim() === "" ? undefined : SelectedVarientName,
        manufacturer: manufacturerShow && manufacturerShow.trim() === "" ? undefined : manufacturerShow,
        manufacturerName: SelectedManufactureName && SelectedManufactureName.trim() === "" ? undefined : SelectedManufactureName,
        color: selectedColors,
        type: selectedType.id,
        typeName: SelectedTypeName,
        qty: 0,
        filter: [
          {
            Variant: [SelectedVarientName],
            Manufacturer: [SelectedManufactureName],
            Color: selectedColors.map((color) => color.name),
            ProductPrice: [productPrice],
          },
        ],
      };
    }

    console.log("mmmm", selectedColors);
    if (!data) {
      alert("Fill All Fields");
    }

    let dataCopies = [];

    if((!SelectedBrandForShow || SelectedBrandForShow.length === 0) && (!SelectedVarient || SelectedVarient.length === 0)){
          console.log("abcbcbcbcbcbcbcbcbcbcbcb 4 44444")
        
          // SelectedBrandForShow.map(cat => {
            dataCopies = SelectedCManufacture.map((manufacturer) => {
              // return SelectedVarient.map((variant) => {
                return SelectedModels.map((modell) => {
                  return {
                    ...data,
                    subsubcatagory:
                      subCatagoryName +
                      " " +
                      SelectedTypeName +
                      " " +
                      // variant.name +
                      // " " +
                      manufacturer.name,
                      manufacturer: manufacturer.id,
                      manufacturerName: manufacturer.name,
                      // variant: variant.id,
                      catagory: modell.catagory,
                      catagoryid: modell.catagoryid,
                      subcatagoryid: modell.id,
                      subcatagory: modell.name,
                      // variantName: variant.name,
                      filter: [
                        {
                          // Variant: [variant.name],
                          Manufacturer: [manufacturer.name],
                          ProductPrice: [0],
                          Color: selectedColors.map((color) => color.name),
                        },
                      ],
                  };
                });
            });
            // }); 
          // });
    }
    else if((!SelectedModels || SelectedModels.length === 0) && (!SelectedVarient || SelectedVarient.length === 0)){
        console.log("abc=======muzzamal   5555")
        dataCopies = SelectedBrandForShow.map(cat => {
            return SelectedCManufacture.map((manufacturer) => {
              // return SelectedVarient.map((variant) => {
                // return SelectedModels.map((modell) => {
                  return {
                    ...data,
                    subsubcatagory:
                      subCatagoryName +
                      " " +
                      SelectedTypeName +
                      " " +
                      // variant.name +
                      // " " +
                      manufacturer.name,
                      manufacturer: manufacturer.id,
                      manufacturerName: manufacturer.name,
                      // variant: variant.id,
                      catagory: cat.name,
                      catagoryid: cat.id,
                      // subcatagoryid: modell.id,
                      // subcatagory: modell.name,
                      // variantName: variant.name,
                      filter: [
                        {
                          // Variant: [variant.name],
                          Manufacturer: [manufacturer.name],
                          ProductPrice: [0],
                          Color: selectedColors.map((color) => color.name),
                        },
                      ],
                  };
                });
              });
            // }); 
          // });
    }
    else if((!SelectedModels || SelectedModels.length === 0) && (!SelectedBrandForShow || SelectedBrandForShow.length === 0)){
          console.log("abcbcbcbcbcbcbcbcbcbcbcb   6666")
        
          // SelectedBrandForShow.map(cat => {
            dataCopies = SelectedVarient.map((variant) => {
              return SelectedCManufacture.map((manufacturer) => {
                // return SelectedModels.map((modell) => {
                  return {
                    ...data,
                    subsubcatagory:
                      subCatagoryName +
                      " " +
                      SelectedTypeName +
                      " " +
                      variant.name +
                      " " +
                      manufacturer.name,
                      manufacturer: manufacturer.id,
                      manufacturerName: manufacturer.name,
                      variant: variant.id,
                      // catagory: cat.name,
                      // catagoryid: cat.id,
                      // subcatagoryid: modell.id,
                      // subcatagory: modell.name,
                      variantName: variant.name,
                      filter: [
                        {
                          Variant: [variant.name],
                          Manufacturer: [manufacturer.name],
                          ProductPrice: [0],
                          Color: selectedColors.map((color) => color.name),
                        },
                      ],
                  };
              });
            });
            // }); 
          // });
        
        
    }
    else if(!SelectedModels || SelectedModels.length === 0){
      console.log("abcbcbcbcbcbcbcbcbcbcbcb 111")

        // selectedColors.map(color => {
          dataCopies = SelectedCManufacture.map((manufacturer) => {
            return SelectedVarient.map((variant) => {
              return SelectedBrandForShow.map((cat) => {
                return {
                  ...data,
                  subsubcatagory:
                    subCatagoryName +
                    " " +
                    SelectedTypeName +
                    " " +
                    variant.name +
                    " " +
                    manufacturer.name,
                  manufacturer: manufacturer.id,
                  manufacturerName: manufacturer.name,
                  variant: variant.id,
                  catagory: cat.name,
                  catagoryid: cat.id,
                  // subcatagoryid: modell.id,
                  // subcatagory: modell.name,
                  variantName: variant.name,
                  filter: [
                    {
                      Variant: [variant.name],
                      Manufacturer: [manufacturer.name],
                      ProductPrice: [0],
                      Color: selectedColors.map((color) => color.name),
                    },
                  ],
                };
              });
            });
          }); 
        // });

    }
    else if(!SelectedVarient || SelectedVarient.length === 0){
      console.log("abcbcbcbcbcbcbcbcbcbcbcb  222")

      // selectedColors.map(color => {
        dataCopies = SelectedCManufacture.map((manufacturer) => {
          return SelectedBrandForShow.map((cat) => {
            return SelectedModels.map((modell) => {
              return {
                ...data,
                subsubcatagory:
                  subCatagoryName +
                  " " +
                  SelectedTypeName +
                  " " +
                  // variant.name +
                  // " " +
                  manufacturer.name,
                  manufacturer: manufacturer.id,
                  manufacturerName: manufacturer.name,
                  // variant: variant.id,
                  catagory: cat.name,
                  catagoryid: cat.id,
                  subcatagoryid: modell.id,
                  subcatagory: modell.name,
                  // variantName: variant.name,
                  filter: [
                    {
                      // Variant: [variant.name],
                      Manufacturer: [manufacturer.name],
                      ProductPrice: [0],
                      Color: selectedColors.map((color) => color.name),
                    },
                  ],
              };
            });
          });
        }); 
      // });
    }
    else if(!SelectedBrandForShow || SelectedBrandForShow.length === 0){
      console.log("abcbcbcbcbcbcbcbcbcbcbcb   3333")

      // selectedColors.map(color => {
        dataCopies = SelectedCManufacture.map((manufacturer) => {
          return SelectedVarient.map((variant) => {
            return SelectedModels.map((modell) => {
              return {
                ...data,
                subsubcatagory:
                  subCatagoryName +
                  " " +
                  SelectedTypeName +
                  " " +
                  variant.name +
                  " " +
                  manufacturer.name,
                  manufacturer: manufacturer.id,
                  manufacturerName: manufacturer.name,
                  variant: variant.id,
                  catagory: modell.catagory,
                  catagoryid: modell.catagoryid,
                  subcatagoryid: modell.id,
                  subcatagory: modell.name,
                  variantName: variant.name,
                  filter: [
                    {
                      Variant: [variant.name],
                      Manufacturer: [manufacturer.name],
                      ProductPrice: [0],
                      Color: selectedColors.map((color) => color.name),
                    },
                  ],
              };
            });
          });
        }); 
      // });
    } 
    else{
      console.log("abcbcbcbcbcbcbcbcbcbcbcb   7777")

      dataCopies = SelectedCManufacture.map((manufacturer) => {
        return SelectedBrandForShow.map(cat => {
          return SelectedVarient.map((variant) => {
            return SelectedModels.map((modell) => {
              return {
                ...data,
                subsubcatagory:
                  subCatagoryName +
                  " " +
                  SelectedTypeName +
                  " " +
                  variant.name +
                  " " +
                  manufacturer.name,
                manufacturer: manufacturer.id,
                manufacturerName: manufacturer.name,
                variant: variant.id,
                catagory: cat.name,
                catagoryid: cat.id,
                subcatagoryid: modell.id,
                subcatagory: modell.name,
                variantName: variant.name,
                filter: [
                  {
                    Variant: [variant.name],
                    Manufacturer: [manufacturer.name],
                    ProductPrice: [0],
                    Color: selectedColors.map((color) => color.name),
                  },
                ],
              };
            });
          });
        }); 
      });
    }

    console.log("Final Data Copies: ", dataCopies.flat(4));

    if(ViewEdit === true){
      axios
      .patch(`${Env.server}/api/subsubcatagory/UpdateSSC/${ViewEditAllData._id}`, dataCopies.flat(4))
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abc===>>", res.data.subsubcatagories);
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
        clearAllHook();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
    }else{
      axios
      .post(`${Env.server}/api/subsubcatagory/addnew`, dataCopies.flat(4))
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abc===>>", res.data.subsubcatagories);
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
        clearAllHook();
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
    }
  };

  const publishImage = () => {
    const formData = new FormData();
    formData.append("imagefront", imagesFront);
    console.log("muzz1-====", imagesFront);
    console.log("muzz2-====", image);
    image.forEach((img, index) => {
      formData.append(`images`, img);
    });
    let objectNames = [];
    axios
      .post(`${Env.server}/api/uploadik/multiple`, formData)
      .then((res) => {
        // console.log('aaaaaaaaaaaaaaaaa',res.data);
        // let resp = res.data.file;
        // for (let i = 0; i < resp.length; i++) {
        //   objectNames.push("/images/" + resp[i].filename);
        // }
        objectNames = res.data.urls;
        setResponseImages(objectNames);
        // if (res.data.message === "imagesSaved") {
          submit(objectNames);
        // } else {
        //   alert("error not in catch");
        // }
      })
      .catch((err) => {
        console.log("err------ooo", err);
        alert("catch error");
      });
  };

  useEffect(()=>{
    GetSubSubCatagoryP();
  },[currentPage,productItems]);

  const GetSubSubCatagoryP = async() => {
    setImages([]);
    setImagesFront([]);
    setLoading(true);
    await axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagoryDD/${currentPage}/${productItems}`)
      .then((res) => {
        console.log("aaaaaaaaaaaaaaa", res.data);
        setTotalPages(res.data.totalPages);
        setGetSubSubCatagory(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
    setLoading(false);
  };

  const GetSubSubCatagory = async() => {
    setImages([]);
    setImagesFront([]);
    setLoading(true);
    await axios
      .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagoryDD`)
      .then((res) => {
        console.log("abc===>>", res.data.message);
        console.log("abclll===>>", res.data.subsubcatagories);
        setGetSubSubCatagory(res.data.subsubcatagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
      setLoading(false);
  };

  const AllSubCatagory = () => {
    setImages([]);
    setImagesFront([]);

    axios
      .get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
      .then((res) => {
        console.log("===>>", res.data.subcatagories);
        setgetAllSubCatagory(res.data.subcatagories);
        setGetAllModel(res.data.subcatagories);
      })
      .catch((err) => {
        console.log("err====>>>", err);
      });
  };

  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    if (UpdateImageActive === true) {
      setImagesFront1(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImages1(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    } else {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImagesFront(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    }
  };

  const removeImage = (index) => {
    if (UpdateImageActive === true) {
      const updatedImagesfront1 = [...imagesFront1];
      updatedImagesfront1.splice(index, 1);
      setImagesFront1(updatedImagesfront1);

      const updatedImages1 = [...image1];
      updatedImages1.splice(index, 1);
      setImages1(updatedImages1);
    } else {
      const updatedImagesfront = [...imagesFront];
      updatedImagesfront.splice(index, 1);
      setImagesFront(updatedImagesfront);

      const updatedImages = [...image];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const handleUpdateImage = () => {
    const formData = new FormData();
    formData.append("imagefront", imagesFront1);
    console.log("muzz1-====", imagesFront1);
    image1.forEach((img, index) => {
      formData.append(`images`, img);
    });
    let objectNames = [];
    axios
      .post(`${Env.server}/api/upload/multiple`, formData)
      .then((res) => {
        // let resp = res.data.file;
        // for (let i = 0; i < resp.length; i++) {
        //   objectNames.push("/images/" + resp[i].filename);
        // }
        objectNames = res.data.urls;
        setResponseImages1(objectNames);
        // if (res.data.message === "imagesSaved") {
          console.log("avc-=-=", objectNames);
          setUpdateImageActive(false);
          if (EdtImageGaleryIndex >= 0) {
            EditDataImage(
              { imageSubsubCatagory: objectNames },
              UpdateImageData._id,
              EdtImageGaleryIndex
            );
          } else {
            UpdateEditDataImage(
              { imageSubsubCatagory: objectNames },
              UpdateImageData._id
            );
          }
          // UpdateEditData({ imageSubsubCatagory: objectNames }, UpdateImageData._id)
        // } else {
        //   alert("error not in catch");
        // }
      })
      .catch((err) => {
        console.log("err------ooo", err);
        alert("catch error");
      });
  };

  const EditDataImage = (data, id, index) => {
    axios
      .patch(
        `${Env.server}/api/subsubcatagory/EditedDataimage/${id}/${index}`,
        data
      )
      .then((res) => {
        console.log("abc====", res.data);
        AllSubCatagory();
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const UpdateEditDataImage = (data, id) => {
    axios
      .patch(
        `${Env.server}/api/subsubcatagory/updateEditedDataimage/${id}`,
        data
      )
      .then((res) => {
        console.log("abc====", res.data);
        AllSubCatagory();
        // GetSubSubCatagory();
        GetSubSubCatagoryP();
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  const handleToggle = (e, id) => {
    e.stopPropagation();
    UpdateEditData({ Show: e.target.checked }, id);
  };

  const [activeTab, setActiveTab] = useState("description");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleUpdateDescription = () => {
    console.log("abc====", description);
    console.log("id====", EditIDProduct._id);
    UpdateEditData({ details: description }, EditIDProduct._id);
  };

  const handleUpdateReview = () => {
    console.log("abc====", ReviewPersonName, PersonReview);
    console.log("id====", EditIDProduct._id);
    UpdateEditDataNewDb(
      { reviews: [{ name: ReviewPersonName, review: PersonReview }] },
      EditIDProduct._id
    );
  };

  const handleUpdateSpeification = () => {
    console.log("abc====", specification);
    UpdateEditData({ specifications: specification }, EditIDProduct._id);
  };

  return (
    <>
      <div
        className={
          currentPath.includes("signup") || currentPath.includes("signin")
            ? ""
            : "wrapper"
        }
      >
        {currentPath.includes("signup") ||
        currentPath.includes("signin") ? null : (
          <Sidebar />
        )}

        <div
          className={
            currentPath.includes("signup") || currentPath.includes("signin")
              ? ""
              : "page-wrapper"
          }
        >
          {currentPath.includes("signup") ||
          currentPath.includes("signin") ? null : (
            <Header />
          )}

          {/* Page Content -> Loads Here */}
          <div className="content">

            <div className="row">
            {/* FUll Width Selected */}
              <div className="col-12">
                <div className="card card-default">
                  
                  {/* Upper 3 filter options */}
                  <div className="row mb-4 mt-3">
                    <div className="col-md-4">
                      <select
                        className="form-select"
                        aria-label="Select brand"
                        style={{ height: "40px", width: "270px" }}
                        onChange={handleBrandSelection}
                      >
                        <option value="all  ">Select Brand</option>
                        <option value="all">All</option>
                        {GetAllBrands &&
                          GetAllBrands.map((brand, index) => (
                            <option key={index} value={brand._id}>
                              {" "}
                              {brand.catagory}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-md-4">
                      <select
                        className="form-select"
                        aria-label="Select model"
                        style={{ height: "40px", width: "270px" }}
                        onChange={handleModelSelection}
                      >
                        <option value="all">Select Model</option>
                        <option value="all">All</option>
                        {GetAllModel &&
                          GetAllModel.map((model, index) => (
                            <option key={index} value={model._id}>
                              {" "}
                              {model.subcatagory}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-md-4">
                      <select
                        className="form-select"
                        aria-label="Select type"
                        style={{ height: "40px", width: "270px" }}
                        onChange={handleTypeSelection}
                      >
                        <option value="all">Select Type</option>
                        <option value="all">All</option>
                        {TypeArray &&
                          TypeArray.map((type, index) => (
                            <option key={index} value={type.type}>
                              {" "}
                              {type.type}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  
                  {/* Lower 5 Filter Options */}
                  <div style={{ display: "flex" }}>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <TextField
                        id="standard-basic"
                        style={{ height: "40px", width: "160px" }}
                        label="Search Accessories"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <select
                        className="form-select"
                        aria-label="Select sorting order"
                        style={{ height: "40px", width: "160px" }}
                        value={sortingOrder}
                        onChange={handleSortingChange}
                      >
                        <option value="ascending">Ascending</option>
                        <option value="descending">Descending</option>
                      </select>
                    </div>

                    <div className="col-md-2">
                      <select
                        className="form-select"
                        aria-label="Select sorting order"
                        style={{ height: "40px", width: "160px" }}
                        // value={sortingOrder}
                        // onChange={handleSortingChange}
                      >
                        <option value="all">All</option>
                        <option value="blue">blue</option>
                      </select>
                    </div>

                    <div className="col-md-2">
                      <select
                        className="form-select"
                        aria-label="Select sorting order"
                        style={{ height: "40px", width: "160px" }}
                        // value={sortingOrder}
                        // onChange={handleSortingChange}
                      >
                        <option value="price">Price</option>
                        <option value="ascending">High-Low</option>
                        <option value="ascending">Low-High</option>
                      </select>
                    </div>

                    <div className="col-md-2">
                      <select
                        className="form-select"
                        aria-label="Select sorting order"
                        style={{ height: "40px", width: "160px" }}
                        value={sortingOrder}
                        onChange={handleSortingChange}
                      >
                        <option value="qty">QTY</option>
                        <option value="ascending">Low-High</option>
                        <option value="descending">Low-High</option>
                      </select>
                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  {/* Pagination Section */}
                  <Container fluid style={{ display: 'flex', justifyContent: 'space-around', padding: '10px 20px' }}>
                    <Row>
                      <Col>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography variant="body2" sx={{ paddingRight: '4px' }}>Page:</Typography>
                          <IconButton disabled={currentPage <= 1} onClick={() => {
                            setCurrentPage(prev => prev - 1);
                            }}>
                            <ArrowBackIosIcon />
                          </IconButton>
                          <TextField
                            type="number"
                            value={currentPage}
                            onChange={(e) => {
                              if(e.target.value > totalPages){
                                setCurrentPage(totalPages);
                              }else if(e.target.value < 1){
                                setCurrentPage(1);
                              }else{
                                setCurrentPage(e.target.value)
                              }
                            }}
                            sx={{ width: '60px', textAlign: 'center' }}
                          />
                          <IconButton disabled={currentPage >= totalPages} onClick={() => setCurrentPage(prev => prev + 1)}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                          <Typography variant="body2" sx={{ paddingRight: '4px' }}>of{' ' + totalPages + ' '}pages</Typography>
                        </Box>
                      </Col>
                      <Col>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography variant="body2">Number of Products:</Typography>
                          <IconButton disabled={productItems <= 20} onClick={() => setProductItems(prev => prev - 10)}>
                            <RemoveIcon />
                          </IconButton>
                          <TextField
                            type="number"
                            value={productItems}
                            onChange={(e) => {
                              if(e.target.value > 200){
                                setProductItems(200);
                              }else if(e.target.value < 20){
                                setProductItems(20);
                              }else{
                                setProductItems(e.target.value)
                              }
                            }}
                            sx={{ width: '120px', textAlign: 'center' }}
                          />
                          <IconButton disabled={productItems >= 200} onClick={() => setProductItems(prev => prev + 10)}>
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Col>
                    </Row>
                  </Container>


                  {/* header */}
                  <div className="d-flex justify-content-between">
                    {/* Heading Part */}
                    <div className="card-header">
                      <h2 className="text-center f-bold">
                        <b>Different Models Accessories Form</b>
                      </h2>
                    </div>
                    {/* Add Button */}
                    <button
                      className="btn btn-primary m-4"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                      style={{ height: "40px" }}
                    >
                      Add Accessories
                    </button>

                  </div>

                  <div
                    className="card-body"
                    style={{height: "auto",}}
                  >
                    {/* Select All Filter and Delete Button */}
                    <div className="row">
                      <div className="col-md-2">
                        <label>
                          <input
                            type="checkbox"
                            onChange={handleToggleAll}
                            checked={
                              selectedItems.length ===
                              filteredSubSubCatagory.length
                            }
                          />
                          &nbsp;Select All
                        </label>
                      </div>
                      <div className="col-md-8"></div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-danger"
                          style={{ marginBottom: "30px", cursor: "pointer" }}
                          onClick={handleDeleteSelected}
                        >
                          Delete
                        </button>
                      </div>
                    </div>

                    {/* Section where the accessories are being rendered */}
                    {
                      loading ? 
                      <div className="container w-full min-h-screen flex" 
                      style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'white',minHeight:'70px'}} >
                        <div style={{width:'80px',height:'80px'}}>
                          <img src={Loader} width={80} height={80} alt={'Loading...'} />
                        </div>
                      </div>
                      :
                      <div className="container">

                        {/* Header Section Row */}
                        <div className="row">
                          
                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px", cursor: "pointer" }}
                              className="card-title"
                            >
                              Select
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              SKU
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Image
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Prod Name
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Color
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Modal
                            </h5>
                          </div>
                          {/* Trade Price Heading */}
                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Trade Price
                            </h5>
                          </div>
                          {/* Price Heading */}
                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Price
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Qty
                            </h5>
                          </div>

                          <div className="col-md-1 p-0">
                            <h5
                              style={{ fontSize: "13px" }}
                              className="card-title"
                            >
                              Other
                            </h5>
                          </div>
                          
                        </div>

                        {/* All Accessories Are Rendered Here */}
                        {sortedData.map((data, index) => {
                          const isCombinedSKU =
                            data.combine &&
                            sortedData.filter(
                              (product) => product.SKU === data.SKU
                            ).length > 1;

                          // Render only the first product with the same SKU if data.combine is true
                          if (
                            isCombinedSKU &&
                            index !==
                              sortedData.findIndex(
                                (product) => product.SKU === data.SKU
                              )
                          ) {
                            return null; // Skip rendering this product
                          }
                          return (
                            <div className="row" key={index}>
                              <div className="col-md-12">
                                <div className="card mb-3">
                                  <div className="row no-gutters pt-3">

                                    <div className="col-md-1">
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleToggleItem(data._id)
                                        }
                                        checked={selectedItems.includes(
                                          data._id
                                        )}
                                      />
                                    </div>

                                    <div className="col-md-1">
                                      {editableIndexSKU === index ? (
                                        <input
                                          type="text"
                                          value={editedContentSKU}
                                          onChange={handleInputChangeSKU}
                                          onBlur={() =>
                                            handleSaveSKU(index, data._id)
                                          }
                                          autoFocus
                                        />
                                      ) : (
                                        <h5
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                          onClick={() =>
                                            handleEditSKU(index, data.SKU)
                                          }
                                        >
                                          {data.SKU}
                                        </h5>
                                      )}
                                    </div>

                                    <div
                                      className="col-md-1"
                                      data-toggle="modal"
                                      data-target="#exampleModalLongUpdateImage"
                                      onClick={() =>
                                        handleImageClick3(index, data)
                                      }
                                    >
                                      <img
                                        src={getAsset(
                                          data.imageSubsubCatagory[0]
                                        )}
                                        className="card-img"
                                        alt="..."
                                        style={{
                                          height: "58px",
                                          width: "90px",
                                          maxWidth: "100px",
                                        }}
                                      />
                                    </div>

                                    <div className="col-md-1">
                                      {editableIndex === index ? (
                                        <input
                                          type="text"
                                          value={editedContent}
                                          onChange={handleInputChange}
                                          onBlur={() =>
                                            handleSave(index, data._id)
                                          }
                                          autoFocus
                                        />
                                      ) : (
                                        <h5
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                          onClick={() =>
                                            handleEdit(
                                              index,
                                              data.subsubcatagory
                                            )
                                          }
                                        >
                                          {data.subsubcatagory}
                                        </h5>
                                      )}
                                    </div>

                                    <div className="col-md-1">
                                      {data &&
                                      data.filter &&
                                      data.filter[0] &&
                                      data.filter[0].Color &&
                                      data.filter[0].Color.length === 1 ? (
                                        <h5
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                        >
                                          {data.filter[0].Color[0]}
                                        </h5>
                                      ) : (
                                        <select className="form-select">
                                          {data.filter[0].Color.map(
                                            (color, index) => (
                                              <option key={index}>
                                                {color}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      )}
                                    </div>

                                    <div className="col-md-1">
                                      {data.combine ? (
                                        <>
                                          <select
                                            style={{
                                              fontSize: "13px",
                                              width: "60px",
                                            }}
                                            className="form-select"
                                          >
                                            {data &&
                                              data.extra &&
                                              data.extra[0] &&
                                              data.extra[0].allmodels &&
                                              data.extra[0].allmodels[0] &&
                                              data.extra[0].allmodels.map(
                                                (model, index) => (
                                                  <option
                                                    key={index}
                                                    value={model.name}
                                                  >
                                                    {model.name}
                                                  </option>
                                                )
                                              )}
                                          </select>
                                        </>
                                      ) : (
                                        <h6
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                        >
                                          {data.subcatagory}
                                        </h6>
                                      )}
                                    </div>

                                    <div className="col-md-1">
                                      {editableIndex11 === index ? (
                                        <input
                                          type="number"
                                          value={editedContent11}
                                          onChange={handleInputChange11}
                                          onBlur={() =>
                                            handleSave11(index, data._id)
                                          }
                                          autoFocus
                                        />
                                      ) : (
                                        <h5
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                          onClick={() =>
                                            handleEdit11(
                                              index,
                                              data.wholesellerPrice
                                            )
                                          }
                                        >
                                          £ {data.wholesellerPrice}
                                        </h5>
                                      )}
                                    </div>
                                    
                                    <div className="col-md-1">
                                      {editableIndex1 === index ? (
                                        <input
                                          type="number"
                                          value={editedContent1}
                                          onChange={handleInputChange1}
                                          onBlur={() =>
                                            handleSave1(index, data._id)
                                          }
                                          autoFocus
                                        />
                                      ) : (
                                        <h5
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                          onClick={() =>
                                            handleEdit1(
                                              index,
                                              data.productPrice
                                            )
                                          }
                                        >
                                          £ {data.productPrice}
                                        </h5>
                                      )}
                                    </div>

                                    

                                    <div className="col-md-1">
                                      {editableIndex2 === index ? (
                                        <input
                                          type="number"
                                          value={editedContent2}
                                          onChange={handleInputChange2}
                                          onBlur={() =>
                                            handleSave2(index, data._id)
                                          }
                                          autoFocus
                                        />
                                      ) : (
                                        <h5
                                          style={{ fontSize: "13px" }}
                                          className="card-title"
                                          onClick={() =>
                                            handleEdit2(index, data.qty)
                                          }
                                        >
                                          {data.qty}
                                        </h5>
                                      )}
                                    </div>

                                    <div className="dropdown col-md-1">
                                      <i
                                        className="fas fa-ellipsis-v dropdown-toggle"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }} 
                                          data-toggle="modal"
                                          data-target="#exampleModalLong" 
                                          onClick={()=> {
                                            HandleViewProductDetail(data)
                                            setViewEditAllData(data)
                                            }} 
                                        >
                                          View
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          data-toggle="modal"
                                          data-target="#exampleModalLongDetailEdit"
                                          onClick={() => {
                                            setEditIDProduct(data);
                                          }}
                                          href="#"
                                        >
                                          Detail
                                        </a>

                                        <label className="dropdown-item toggle-label">
                                          <input
                                            type="checkbox"
                                            className="toggle-checkbox"
                                            onChange={(e) =>
                                              handleToggle(
                                                e,
                                                data._id,
                                                data.Show
                                              )
                                            }
                                            checked={data.Show}
                                          />
                                          <span className="slider"></span>
                                        </label>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      </div>
                    }
                    

                    {/* View Modal Or Add Accessories Modal */}
                    <div
                      class="modal fade"
                      id="exampleModalLong"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                      style={{ width: "100%", marginLeft: "40px" }}
                    >
                      <div
                        class="modal-dialog"
                        role="document"
                        style={{ maxWidth: "90%", width: "100%" }}
                      >
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              {ViewEdit === true ? ("Edit") : ("Brand Model Accessories")}
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={()=> {
                                setViewEdit(false)
                                clearAllHook()
                              }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                              {/* Type Selection Section */}
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Dropdown className="mr-5">
                                  
                                  <Dropdown.Toggle variant="primary" id="dropdown-basic" className="mr-3">
                                    Product Type
                                  </Dropdown.Toggle>


                                  <Dropdown.Menu style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}
                                    value={typeShow}
                                    onChange={handleVariantChange1}
                                    label="Select Item 1"
                                  >
                                    {/* Type Search */}
                                    <div>
                                      <Form.Group controlId="formSearch" className="m-auto">
                                        {/* <Form.Label>Search:</Form.Label> */}
                                        <div className="input-group">
                                          <input type="text" className="form-control" onChange={TypeSearchModalData} placeholder="Search..." />
                                          <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button">
                                              Search
                                            </button>
                                          </div>
                                        </div>
                                      </Form.Group>
                                    </div>

                                    {TypeArray.map((item) => (
                                      <Dropdown.Item key={item._id} value={item._id} onClick={() => handleVariantChange1(item)}>
                                        {item.type}
                                        <IconButton edge="end" aria-label="delete" data-toggle="modal"
                                          data-target="#ModalDeleteFinal" onClick={(event) => handleDeleteType(event, item._id, 'Type')}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>

                                  <Button
                                    onClick={() => setTypeHookShow(true)}
                                    variant="outlined"
                                  >
                                    Add new Type
                                  </Button>

                                  {TypeHookShow === true ? (
                                    <>
                                      <br />
                                      <Box
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <TextField
                                          label="Enter new Type..."
                                          size="small"
                                          value={NewType}
                                          onChange={(e) =>
                                            setNewType(e.target.value)
                                          }
                                        />
                                        <Button
                                          variant="contained"
                                          sx={{
                                            backgroundColor: "black",
                                            "&:hover": {
                                              backgroundColor: "black",
                                            },
                                          }}
                                          onClick={() => { AddType() }}
                                        >
                                          Add
                                        </Button>
                                      </Box>
                                    </>
                                  ) : null}


                                </Dropdown>
                                {selectedType.name ?(
                                <div> 
                                  <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <ul> 
                                        <li style={{ border:"1px solid black" , borderRadius:"10px", padding:"5px" }}>
                                          {selectedType && selectedType.name} 
                                        </li>
                                    </ul>
                                  </div>
                                </div>
                                ):null}
                            </div>

                            {/* Image Input Section */}
                            <div
                              onClick={handleInsideClick}
                              style={{
                                margin: "20px auto",
                                width: "100px",
                                height: "100px",
                                border: "1px dashed #aaa",
                                borderRadius: "20px",
                                cursor: "pointer",
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "black",
                              }}
                            >
                              <h5 style={{ color: "white", marginTop: "30px" }}>
                                Choose Image 1
                              </h5>
                              <input
                                {...getInputProps()}
                                id="fileInput" // Ensure this ID is present
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  opacity: 0,
                                  cursor: "pointer",
                                }}
                              />
                            </div>

                            <br />
                            {/* Images Show Container */}
                            <center>
                              {" "}
                              <div className="container"  spacing={1}>
                              {ViewEdit === true ? (
                                <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}} >
                                  {ViewEditImages.map((file, index) => (
                                    
                                    <div key={index} style={{position:'relative',display:'inline'}} >
                                        <span 
                                        onClick={() => removeImage(index)}
                                        style={{fontSize:'22px',color:'white',backgroundColor:'red',radius:'5px',position:'absolute',margin:'3px',cursor:'pointer',top:'10px',right:'10px',height:'25px',width:'25px'}} >&times;</span>
                                        <img
                                          key={index}
                                          src={getAsset(file)}
                                          alt=""
                                          width="300"
                                        />
                                    </div>
                                  ))}
                                </div>
                              ):(
                                <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
                                {imagesFront.map((file, index) => (
                                  <div item key={file.name}>
                                    <div style={{position:'relative'}} >
                                      <img
                                        src={file.preview}
                                        alt={file.name}
                                        width="300"
                                      />
                                      <span
                                        style={{fontSize:'22px',color:'white',backgroundColor:'red',radius:'5px',position:'absolute',margin:'3px',cursor:'pointer',top:'10px',right:'10px',height:'25px',width:'25px'}}
                                        onClick={() => removeImage(index)}
                                        size="small"
                                      >
                                        &times;
                                      </span>
                                    </div>
                                  </div>
                                ))}
                                </div>
                              )}
                              
                              </div>
                            </center>

                            <div className="container">
                              

                              <br />
                              <br />
                              {/* Site For rest of the selections */}
                              <div className="mr-5 py-3" >
                                <h5 style={{ textAlign: 'center'}} className="py-2" >
                                  Choose Filter:
                                </h5>

                              <div style={{display: 'flex', justifyContent: 'center'}}>
                                
                                <Dropdown>
                                  <Dropdown.Toggle variant="primary" id="brandDropdown" className="mr-3 w-80">
                                    Search Brand-Type
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}>
                                    <div>
                                      <Form.Group controlId="formSearchBrand" className="m-auto">
                                        <div className="input-group">
                                          <input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} />
                                          <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button">
                                              Search
                                            </button>
                                          </div>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  {/* Dropdown items */}
                                    {ModalBrand.map((item) => (
                                      <>
                                        <Dropdown.Item key={item._id} value={item._id} onClick={()=>handleBrandSelection1(item)}
                                        active={GetAllCatagoryBrand.includes(item._id)}
                                        >
                                          {item.catagory}
                                        </Dropdown.Item>
                                      </>
                                    ))}
                                    </Dropdown.Menu>
                                </Dropdown>
  
                              </div>

                                {SelectedBrandForShow[0] ? (
                                  <div> 
                                    <div style={{ maxHeight: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
                                      <ul style={{ border:"1px solid black" , borderRadius:"10px", padding:"5px" }}> 
                                        <li>
                                          {SelectedBrandForShow.map((data , index) => (
                                            <span   key={index}>{data.name} {"  "} <span style={{color:"red", fontSize:"22px", cursor:"pointer"}} onClick={() => removeFromBrandHook(data.id, index)}>&times;</span> {"   "} <span style={{fontSize:"25px"}}>{'  '} | {' '}</span></span>
                                          ))}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}

                                <br />


                                <div style={{display: 'flex', justifyContent: 'center'}}>

                        {/* <InputLabel>Modal</InputLabel> */}
                      <Dropdown>
                        <Dropdown.Toggle  variant="primary" id="dropdown-basic" className="mr-3 w-80">
                        Search Model
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}
                          value={SelectedModels.map(
                            (varient) => varient.id
                          )} 
                          label="Select Item 1" 
                          multiple 
                        >

                        <div>
                        <Form.Group controlId="formSearch" className="m-auto">
                        {/* <Form.Label>Search:</Form.Label> */}
                        <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search..." onChange={TypeSearchModelData} />
                        <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button">
                        Search
                        </button>
                        </div>
                        </div>
                        </Form.Group>
                        </div>
                          <Form.Control as="select" multiple value={modelSelected.map(item => item._id)} onChange={handleModelChange1}>
                            <option disabled>Select Model(s)</option>
                            
                            {GetAllModel.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.subcatagory}
                              </option>
                            ))}
                          </Form.Control>
                        </Dropdown.Menu>
                      </Dropdown>


                                </div>

                                {SelectedModels[0] ? (
                                  <div> 
                                    <div style={{ maxHeight: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
                                      <ul style={{ border:"1px solid black" , borderRadius:"10px", padding:"5px" }}>  
                                        <li>
                                          {SelectedModels.map((data , index) => (
                                            <span   key={index}>{data.name} {"  "} <span style={{color:"red", fontSize:"22px", cursor:"pointer"}} onClick={() => removeFromModalHook(data.id, index)}>&times;</span> {"   "} <span style={{fontSize:"25px"}}>{'  '} | {' '}</span></span>
                                          ))}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                                <br/>

                                <div style={{display: 'flex', justifyContent: 'center'}}>

                                                                <Dropdown>
                                                                  
                                                                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="mr-3" style={{width: '180px'}}>
                                        Add Variant
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu
                                  style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}
                                  value={SelectedVarient.map((variant) => variant.id)}
                                  onChange={handleVariantChange}
                                  label="Select Item 1"
                                  multiple // Add multiple attribute here
                                >
                                  {/* Your search input */}
                                  <div>
                                    <Form.Group controlId="formSearch" className="m-auto">
                                      <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." onChange={TypeSearchVarientData} />
                                        <div className="input-group-append">
                                          <button className="btn btn-outline-secondary" type="button">
                                            Search
                                          </button>
                                        </div>
                                      </div>
                                    </Form.Group>
                                  </div>
                                  
                                  {/* Dropdown items */}
                                  <Dropdown multiple>
                                      {variantArray.map((item) => (
                                        <Dropdown.Item
                                          key={item._id}
                                          value={item._id}
                                          onClick={() => handleVariantChange11(item)}
                                          active={selectedVariants.includes(item._id)}
                                        >
                                          {item.variant}
                                          <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            data-toggle="modal"
                                            data-target="#ModalDeleteFinal"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleDeleteVarient(e, item._id, "Variant");
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown>
                                </Dropdown.Menu>


                                                                <Button
                                                                  onClick={() => setVarHookShow(true)}
                                                                  variant="outlined"
                                                                  className=""
                                                                  style={{width: '180px'}}
                                                                >
                                                                  Add new Variant
                                                                </Button>

                                                                {VarHookShow === true ? (
                                                                  <>
                                                                    <Box
                                                                      sx={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                      }}
                                                                    >
                                                                      <TextField
                                                                        label="Enter new Variant..."
                                                                        size="small"
                                                                        value={newVariant}
                                                                        onChange={(e) =>
                                                                          setNewVariant(e.target.value)
                                                                        }
                                                                      />
                                                                      <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                          backgroundColor: "black",
                                                                          "&:hover": {
                                                                            backgroundColor: "black",
                                                                          },
                                                                        }}
                                                                        onClick={AddVariant}
                                                                      >
                                                                        Add
                                                                      </Button>
                                                                    </Box>
                                                                  </>
                                                                ) : null}



                                </Dropdown>
                                
                                </div>

                                {SelectedVarient[0] && SelectedVarient[0].name && SelectedVarient[0].name.trim()!== "" ? (
                                  <div> 
                                    <div style={{ maxHeight: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
                                      <ul style={{ border:"1px solid black" , borderRadius:"10px", padding:"5px" }}>  
                                        <li >
                                          {SelectedVarient.map((data , index) => (
                                            data && data.name && data.name.trim() !== "" ?
                                            <span   key={index}>{data.name} {"  "} <span style={{color:"red", fontSize:"22px", cursor:"pointer"}} onClick={() => removeFromVarientHook(data.id, index)}>&times;</span> {"   "} <span style={{fontSize:"25px"}}>{'  '} | {' '}</span></span>
                                            : null
                                          ))}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
<br /><br />

                                <div style={{display: 'flex', justifyContent: 'center'}}>

                                <Dropdown>

                                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="mr-3" style={{width: '180px'}}>
                                        Add Manufacturer
                                      </Dropdown.Toggle>

                                                                <Dropdown.Menu style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}
                                                                  value={SelectedCManufacture.map(
                                                                    (manufacture) => manufacture.id
                                                                  )}
                                                                  onChange={handleManufacturerChange}
                                                                  label="Select Colors"
                                                                  multiple
                                                                >
                                                                  <div>
                                      <Form.Group controlId="formSearch" className="m-auto">
                                        {/* <Form.Label>Search:</Form.Label> */}
                                        <div className="input-group">
                                          <input type="text" className="form-control" placeholder="Search..."  onChange={TypeSearchManufactureData} />
                                          <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"  >
                                              Search
                                            </button>
                                          </div>
                                        </div>
                                      </Form.Group>
                                    </div>
                                                              <Dropdown multiple>
                                                                  {manufacturerArray.map((item) => (
                                                                    <Dropdown.Item key={item._id} value={item._id}
                                                                        active={selectedManufactures.includes(item._id)}
                                                                        onClick={() => handleManufacturerChange(item)}
                                                                    >
                                                                      {item.manufacturer}
                                                                      <IconButton
                                                                        edge="end"
                                                                        aria-label="delete"
                                                                        data-toggle="modal"
                                                                        data-target="#ModalDeleteFinal"
                                                                        onClick={(e) => {
                                                                          e.stopPropagation();
                                                                          handleDeleteManufacturer(
                                                                            e,
                                                                            item._id,
                                                                            "Manufacturer"
                                                                          );
                                                                        }}
                                                                        
                                                                      >
                                                                        <DeleteIcon />
                                                                      </IconButton>
                                                                    </Dropdown.Item>
                                                                  ))}
                                                              </Dropdown>
                                                                </Dropdown.Menu>

                                                                <Button variant="outlined" onClick={() => setManfacHookShow(true)} className="" style={{width: '180px'}}>
                                                                  Add new Manufacturer
                                                                </Button>
                                                                {ManfacHookShow === true ? (
                                                                  <>
                                                                    <Box
                                                                      sx={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                      }}
                                                                    >
                                                                      <TextField
                                                                        label="Enter new Manufacturer..."
                                                                        value={newManufacturer}
                                                                        onChange={(e) =>
                                                                          setNewManufacturer(e.target.value)
                                                                        }
                                                                      />
                                                                      <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                          backgroundColor: "black",
                                                                          "&:hover": {
                                                                            backgroundColor: "black",
                                                                          },
                                                                        }}
                                                                        onClick={addManufacturer}
                                                                      >
                                                                        Add
                                                                      </Button>
                                                                    </Box>
                                                                  </>
                                                                ) : null}



                                </Dropdown>
                                

                                </div>



                                {SelectedCManufacture[0] && SelectedCManufacture[0].name && SelectedCManufacture[0].name.trim() !== "" ? (
                                  <div> 
                                    <div style={{ maxHeight: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
                                      <ul style={{ border:"1px solid black" , borderRadius:"10px", padding:"5px" }}>  
                                        <li >
                                          {SelectedCManufacture.map((data , index) => (
                                            data && data.name && data.name.trim() !== "" ?
                                            <span   key={index}>{data.name} {"  "} <span style={{color:"red", fontSize:"22px", cursor:"pointer"}} onClick={() => removeFromManufactureHook(data.id, index)}>&times;</span> {"   "} <span style={{fontSize:"25px"}}>{'  '} | {' '}</span></span>
                                            :null
                                          ))}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}

<br /><br />

                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                  <Dropdown>

                                        <Dropdown.Toggle variant="primary" id="dropdown-basic" className="mr-3" style={{width: '180px'}}>
                                          Add Colors
                                        </Dropdown.Toggle>

                                        
                                        <Dropdown.Menu style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}
                                          value={selectedColors.map(
                                            (color) => color.id
                                          )}
                                          onChange={handleColorChange}
                                          label="Select Colors"
                                          multiple
                                        >
                                          <div>
                                            <Form.Group controlId="formSearch" className="m-auto">
                                              {/* <Form.Label>Search:</Form.Label> */}
                                              <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search..."  onChange={TypeSearchColorData} />
                                                <div className="input-group-append">
                                                  <button className="btn btn-outline-secondary" type="button">
                                                    Search
                                                  </button>
                                                </div>
                                              </div>
                                            </Form.Group>
                                          </div>       
                                          
                                          <Dropdown multiple>
                                            {colorArray.map((item) => (
                                              <DropdownItem key={item._id} value={item._id} 
                                              onClick={() => handleColorChange(item)}
                                              active={selectedColorss.includes(item._id)}
                                              >
                                                {item.color}
                                                <IconButton
                                                  edge="end"
                                                  aria-label="delete"
                                                  data-toggle="modal"
                                                  data-target="#ModalDeleteFinal"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteColor(
                                                      e,
                                                      item._id,
                                                      "Color"
                                                    );
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </DropdownItem>
                                            ))}
                                            </Dropdown>

                                        </Dropdown.Menu>

                                                                  <Button
                                                                    onClick={() => setColorHookShow(true)}
                                                                    variant="outlined"
                                                                    style={{width: '180px'}}
                                                                  >
                                                                    Add new Color
                                                                  </Button>
                                                                  {colorHookShow === true ? (
                                                                    <>
                                                                      <Box
                                                                        sx={{
                                                                          width: "100%",
                                                                          display: "flex",
                                                                          alignItems: "center",
                                                                          justifyContent: "center",
                                                                        }}
                                                                      >
                                                                        <TextField
                                                                          label="Enter new Color..."
                                                                          size="small"
                                                                          value={newColor}
                                                                          onChange={(e) =>
                                                                            setNewColor(e.target.value)
                                                                          }
                                                                        />
                                                                        <Button
                                                                          variant="contained"
                                                                          sx={{
                                                                            backgroundColor: "black",
                                                                            "&:hover": {
                                                                              backgroundColor: "black",
                                                                            },
                                                                          }}
                                                                          onClick={addColor}
                                                                        >
                                                                          Add
                                                                        </Button>
                                                                      </Box>
                                                                    </>
                                                                  ) : null}


                                  </Dropdown>

                                </div>       
  
                                {selectedColors[0] ? (
                                <div> 
                                  <div style={{ maxHeight: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <ul style={{ border:"1px solid black" , borderRadius:"10px", padding:"5px" }}>  
                                      <li >
                                        {selectedColors.map((data , index) => (
                                          <span   key={index}>{data.name} {"  "} <span style={{color:"red", fontSize:"22px", cursor:"pointer"}} onClick={() => removeFromColorHook(data.id, index)}>&times;</span> {"   "} <span style={{fontSize:"25px"}}>{'  '} | {' '}</span></span>
                                        ))}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                ) : null}

                                {selectedColors && selectedColors.length > 0 && 
                                  <div className="my-3">
                                    {
                                      selectedColors.map(color =>(
                                        <div key={color.id} style={{display:'flex',width:'100%',minHeight:'50px',alignItems:'center',justifyContent:'center',gap:'15px'}} >
                                            <div>{color.name}</div>
                                            <input type="number" value={color.quantity?color.quantity : null} placeholder="Enter Quantity" onChange={(e)=>{
                                              const newSelectedColors = selectedColors.map(prev=>prev.id === color.id ? {...color,quantity:parseInt(e.target.value,10)}:prev)
                                              setSelectedColors(newSelectedColors);
                                              let totalQuantity = 0;
                                              newSelectedColors.map(color=>{
                                                console.log(color);
                                                totalQuantity += parseInt(color.quantity,10);
                                              })
                                              setOptionalQty(totalQuantity);
                                            }} />
                                        </div>
                                      ))
                                    }
                                  </div>
                                }
                                <br /><br /><br />
                              <div>
                                  <label style={{ fontSize: "24px" }}>
                                    Optional
                                  </label>
                                  <br />
                                  <TextField
                                    label="Price"
                                    type="number"
                                    value={optionalPrice}
                                    onChange={handleOptionalPriceChange}
                                  />
                                  <br />
                                  <TextField
                                    label="Trade Price"
                                    type="number"
                                    value={optionalwholesalePrice}
                                    onChange={handleOptionalWholeSalePriceChange}
                                  />
                                  <br />
                                  <TextField
                                    label="SKU"
                                    type="text"
                                    value={optionalSKU}
                                    onChange={handleOptionalSKUChange}
                                  />
                                  <br />
                                  <TextField
                                    label="QTY"
                                    disabled={selectedColors[0] && selectedColors[0].quantity}
                                    type="number"
                                    value={optionalQty}
                                    onChange={handleOptionalQtyChange}
                                  />
                              </div>
                              
                                <br />
                              </div>


                              <button
                                className="update-button btn btn-primary my-3"
                                data-dismiss="modal"
                                onClick={publishImage}
                              > 
                                {ViewEdit? ("Update") : ("Publish")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Delete Modal */}
                    <div
                      class="modal fade"
                      id="exampleModalLong1"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Delete Product
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <h4>Are you sure to delete?</h4>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                              onClick={handleDeleteFun}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Update Images Modal */}
                    <div
                      class="modal fade"
                      id="exampleModalLongUpdateImage"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Update Images
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span
                                aria-hidden="true"
                                onClick={() => {
                                  setUpdateImageActive(false);
                                  setEdtImageGaleryIndex(-1);
                                }}
                              >
                                &times;
                              </span>
                            </button>
                          </div>
                          <div>
                            <div>
                              <button
                                className="btn btn-primary"
                                onClick={() => setEdtImageGaleryIndex(-1)}
                              >
                                Reset
                              </button>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                marginLeft: "10px",
                              }}
                            >
                              {UpdateImageData &&
                                UpdateImageData.imageSubsubCatagory &&
                                UpdateImageData.imageSubsubCatagory.map(
                                  (image, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        marginRight: "20px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <img
                                        src={getAsset(image && image)}
                                        className="card-img"
                                        alt={`Image ${index + 1}`}
                                        style={{
                                          height: "70px",
                                          width: "auto",
                                          maxHeight: "70px",
                                          marginRight: "10px",
                                          border:
                                            EdtImageGaleryIndex === index
                                              ? "2px solid blue"
                                              : "none",
                                        }}
                                      />
                                      <div>
                                        <button
                                          onClick={() =>
                                            setEdtImageGaleryIndex(index)
                                          }
                                        >
                                          Edit
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                          onClick={() =>
                                            handleDeleteImage(index)
                                          }
                                          data-dismiss="modal"
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>

                          <div
                            onClick={handleInsideClick}
                            style={{
                              marginLeft: "20px",
                              width: "100px",
                              height: "100px",
                              border: "1px dashed #aaa",
                              borderRadius: "20px",
                              cursor: "pointer",
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "black",
                            }}
                          >
                            {/* <img src={dragAndDropImage} alt="Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                            <h5 style={{ color: "white", marginTop: "30px" }}>
                              Choose Image 2
                            </h5>
                            <input
                              {...getInputProps()}
                              id="fileInput" // Ensure this ID is present
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                            />
                          </div>

                          

                          <br />
                          <center>
                            {" "}
                            <div className="container" spacing={1}>
                              {imagesFront1.map((file, index) => (
                                <div item key={file.name}>
                                  <div>
                                    <img
                                      src={file.preview}
                                      alt={file.name}
                                      width="300"
                                    />
                                    <span
                                      onClick={() => removeImage(index)}
                                      size="small"
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "30px",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {" "}
                                        &times;
                                      </i>
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </center>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={() => {
                                setUpdateImageActive(false);
                                setEdtImageGaleryIndex(-1);
                              }}
                            >
                              Close
                            </button>
                            <>
                              <button
                                type="button"
                                class="btn btn-primary"
                                data-dismiss="modal"
                                onClick={handleUpdateImage}
                              >
                                Update
                              </button>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Accessory Details Modal */}
                    <div
                      className="modal fade"
                      id="exampleModalLongDetailEdit"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Edit Accessory Details:
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span
                                aria-hidden="true"
                                onClick={() => {
                                  setDescription("");
                                  setSpecification("");
                                }}
                              >
                                &times;
                              </span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col">
                                <button
                                  className={`btn btn-link ${
                                    activeTab === "description" ? "active" : ""
                                  }`}
                                  onClick={() => handleTabClick("description")}
                                >
                                  Description
                                </button>
                              </div>
                              <div className="col">
                                <button
                                  className={`btn btn-link ${
                                    activeTab === "specification"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTabClick("specification")
                                  }
                                >
                                  Specification
                                </button>
                              </div>
                              <div className="col">
                                <button
                                  className={`btn btn-link ${
                                    activeTab === "review" ? "active" : ""
                                  }`}
                                  onClick={() => handleTabClick("review")}
                                >
                                  Review
                                </button>
                              </div>
                              {/* <div className="col">
                                <button className={`btn btn-link ${activeTab === 'reviews' ? 'active' : ''}`} onClick={() => handleTabClick('reviews')}>Reviews</button>
                              </div> */}
                            </div>
                            {activeTab === "description" && (
                              <div>
                                {/* Description Content Goes Here */}
                                <h3>Description:</h3>

                                <JoditEditor
                                  ref={editor}
                                  value={EditIDProduct.details}
                                  // config={config}
                                  onChange={(newContent) =>
                                    setDescription(newContent)
                                  }
                                />
                                <div
                                  className="btn btn-primary "
                                  style={{ margin: "20px" }}
                                  onClick={handleUpdateDescription}
                                >
                                  Update
                                </div>
                              </div>
                            )}
                            {activeTab === "specification" && (
                              <div>
                                <h3>Specification:</h3>
                                <JoditEditor
                                  ref={editor}
                                  value={EditIDProduct.specifications}
                                  // config={config}
                                  onChange={(newContent) =>
                                    setSpecification(newContent)
                                  }
                                />
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "20px" }}
                                  onClick={handleUpdateSpeification}
                                >
                                  Update
                                </div>
                              </div>
                            )}
                            {activeTab === "review" && (
                              <div>
                                <h3>Reviews by Admin:</h3>
                                <h6 style={{ textAlign: "left" }}>
                                  Person Name:
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type Name"
                                  onChange={(e) =>
                                    setReviewPersonName(e.target.value)
                                  }
                                />
                                <br />
                                <h6 style={{ textAlign: "left" }}>Review:</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type your Review"
                                  onChange={(e) =>
                                    setPersonReview(e.target.value)
                                  }
                                />
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "20px" }}
                                  onClick={handleUpdateReview}
                                >
                                  Update
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      border: "1px solid black",
                                    }}
                                  >
                                    <p
                                      style={{ padding: "5px", color: "blue" }}
                                    >
                                      Person Name
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      border: "1px solid black",
                                    }}
                                  >
                                    <p
                                      style={{ padding: "5px", color: "blue" }}
                                    >
                                      Review
                                    </p>
                                  </div>
                                </div>

                                {EditIDProduct &&
                                  EditIDProduct.reviews &&
                                  EditIDProduct.reviews &&
                                  EditIDProduct.reviews.map((review, index) => (
                                    <div key={index}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <div
                                          style={{
                                            flex: 1,
                                            border: "1px solid black",
                                          }}
                                        >
                                          <p style={{ padding: "5px" }}>
                                            {review.name}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            flex: 1,
                                            border: "1px solid black",
                                          }}
                                        >
                                          <p style={{ padding: "5px" }}>
                                            {review.review}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Delete Alert Modal */}
                    <div
                      className="modal fade"
                      id="ModalDeleteFinal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                      style={{ zIndex: "19990" }}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-right"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalLongTitle"
                            >
                              Alert!
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {matchedItemCount === 0 ? (
                            <>
                              <div className="modal-body">
                                <p>
                                  You have {matchedItemCount} products related
                                  to this {matchedItem}.
                                </p>
                                <p>
                                  Please choose another option or delete only{" "}
                                  {matchedItem}
                                </p>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleDelete("noall")}
                                >
                                  Delete {matchedItem}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => window.location.reload()}
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="modal-body">
                                <p>
                                  You are about to delete {matchedItemCount}{" "}
                                  products related to this {matchedItem}.
                                </p>
                                <p>
                                  Please choose one of the following options:
                                </p>
                                <ul>
                                  <li>Delete all related products</li>
                                  <li>Delete only this {matchedItem}</li>
                                  <li>Cancel</li>
                                </ul>
                              </div>
                              <div className="">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleDelete("all")}
                                >
                                  Delete All Related Products
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => handleDelete("noall")}
                                >
                                  Delete Only This {matchedItem}{" "}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary update-button"
                                  onClick={() => window.location.reload()}
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSSCatagory;
